import { useEffect, useState } from "react";
import "../Gacha.css"
import BossItem from "./BossItem";
import Refund from "./Refund";
import Quest from "./Quest";
import NFTContract from "../contracts/nfts.json";
import GachaContract from "../contracts/gacha.json";
import QuestsContract from "../contracts/quests.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";

function Quests(props) {


    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const gachaContractAddress = props.gachaContract;
    const questsContractAddress = props.questsContract;

  const nft = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  )

  const gacha = new web3.eth.Contract(
    GachaContract,
    gachaContractAddress
  )

  const questsContract = new web3.eth.Contract(
    QuestsContract,
    questsContractAddress
  )

  const questNames = [
    "Epic Quest of Knowledge", "Epic Quest of Might", "Epic Quest of Skill"
  ]

  const [loading,setLoading] = useState(true);

  const [quests, setQuests] = useState([]);
  const [relics, setRelics] = useState([]);
  const [balances, setBalances] = useState([]);
  const [currentStep, setCurrentStep] = useState([]);

  const [questsOpen, setQuestsOpen] = useState([]);



  async function getQuests() {
    setLoading(true);
    const _quests = await questsContract.methods.getQuests().call();
    setQuests(_quests);
    setRelics(await nft.methods.getRelics().call());
    setBalances(await nft.methods.getRelicsBalances(account).call());
    setCurrentStep(await questsContract.methods.getCurrentStepForEachQuest(account).call());
    let _questsOpen = new Array(_quests.length).fill(false);
    setQuestsOpen(_questsOpen);
    setLoading(false);
  }

  async function fulfillStep(questId) {
    try {
      await questsContract.methods.completeQuestStep(questId).send({
        from: account,
        gas: 300000
      });
      setCurrentStep(await questsContract.methods.getCurrentStepForEachQuest(account).call());
    } catch (error) {
      console.error(error);
    }
  }

  function openQuest(quest, bool) {
    var _questsOpen = questsOpen;
    _questsOpen[quest] = bool;
    setQuestsOpen(_questsOpen);
    setQuestsOpen(prevItems => {
      return [...prevItems];
    });
  }

  function isQuestVisible(quest) {
    if(quest.itemRequired != 0 && balances[quest.itemRequired] > 0) {
      return true      
    }
    if(quest.itemRequired == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  useEffect(() => {getQuests()}, [])

  return(
        <div className="gacha-box">
            {!loading && <div className="center-element">
              <h2>Quests</h2>

              {quests.map((quest, questIndex) => {
                return(
                  isQuestVisible(quest) && <div>
                    <button onClick={() => {openQuest(questIndex, true)}} style={{margin:"15px"}} className="animated-button3">
                      <span></span>
                      <span style={{display:"none"}} ></span>
                      <span></span>
                      {questNames[questIndex]}
                    </button>
                    {questsOpen[questIndex] && <Quest fulfill={() => fulfillStep(questIndex)} questIndex={questIndex} currentStep={currentStep[questIndex]} quest={quest} balances={balances} relics={relics} close={() => openQuest(questIndex, false)} />}
                  </div>
                )
              })}
            </div>}
        </div>
  )
}

  export default Quests