import "../Stats.css";
import { useEffect, useState } from "react";
import MinerContract from "../contracts/minerabi.json";
import NFTContract from "../contracts/nfts.json";
import BossContract from "../contracts/bosses.json";
import GachaContract from "../contracts/gacha.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";


function Stats(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const gachaContractAddress = props.gachaContract;
    const minerContractAddress = props.minerContract;
    const bossContractAddress = props.bossContract;
    const multiplier = 1000000000000000000;
    const finneyMultiplier = 1000000000000000
    const statsMultiplier = 100000000000000000;
    const beansMultiplier = 1000000000000;
    const expMultiplier = 100000000000000;


    const [TVL, setTVL] = useState(0);
    const [totalWithdrawn, setTotalWithdrawn] = useState(0);
    const [totalReinvest, setTotalReinvest] = useState(0);
    const [user, setUser] = useState([]);
    const [depositedAmount, setDepositedAmount] = useState(0);
    const [userBonusStats, setUserBonusStats] = useState([]);
    const [upline, setUpline] = useState("");
    const [canSendBeans, setCanSendBeans] = useState(false);
    const [amount, setAmount] = useState(0);
    const [address, setAddress] = useState("");
    const [sent, setSent] = useState(false);

    const [daysPassed, setDaysPassed] = useState(1);


    const [amountWithdraw, setWithdraw] = useState(0);
    const [amountInGacha, setGachaAmount] = useState(0);

    const miner = new web3.eth.Contract(    
        MinerContract,
        minerContractAddress
    )
    const boss = new web3.eth.Contract(
        BossContract,
        bossContractAddress
    )

    const nft = new web3.eth.Contract(
        NFTContract,
        nftContractAddress
    )

    const gacha = new web3.eth.Contract(
        GachaContract,
        gachaContractAddress
    )

    function calculateTotalReturn() {
        return (((depositedAmount) * 1.03 ** daysPassed) / statsMultiplier).toFixed();
    }

    function calculateDailyReturn() {
        if(daysPassed > 0) {
            var base = parseFloat(depositedAmount);
            var amount;
            var i = 0;
            for(i; i < daysPassed; i++) {
                amount = base * 0.03;
                base += amount;
            }
            return (amount / expMultiplier).toFixed()
        }
    }


    async function getStats() {
        const _user = await miner.methods.users(account).call();
        setUser(_user);
        setDepositedAmount(_user.deposit_amount);
        const _userBonusStats = await miner.methods.userBonusStats(account).call();
        setUserBonusStats(_userBonusStats);
        const _tvl = await web3.eth.getBalance(minerContractAddress);
        setTVL(_tvl);
        const _tW = await miner.methods.total_withdraw().call();
        setTotalWithdrawn(_tW);
        const _tR = await miner.methods.total_reinvested().call();
        setTotalReinvest(_tR);
        const _canSendBeans = await gacha.methods.canSendBeans(account).call();
        setCanSendBeans(_canSendBeans);
        const _amountInGacha = await web3.eth.getBalance(gachaContractAddress);
        setGachaAmount(_amountInGacha);
    }

    async function sendMagicBeans() {
        try {
            setSent(false);
            await gacha.methods.sendGachaTokens(amount, address).send(
                {
                    from: account,
                    gas: 50000
                }
            );
            setSent(true);
        } catch (error) {
            console.error(error)
        }
    }

    function handleChange(event) {
        if(event.target.value <= 3) {
            setAmount(event.target.value);
        }
    }

    function handleChangeAddress(event) {
        setAddress(event.target.value)
    }

    function handleChangeWithdraw(event) {
        setWithdraw(event.target.value);
    }

    async function withdrawGacha() {
        try {
            await gacha.methods.withdraw(amountWithdraw).send({
                from:account,
                gas: 200000,
            })
            
            setTimeout(getStats, 2000);
        } catch (error) {
            console.log(error)
        }
    }

    function changeDaysPassed(event) {
        setDaysPassed(event.target.value);
    }

    
    useEffect(() => {
        getStats();
    }, []);

    return(
        <div className="stats-box">
            <div className="stats-container">
                <div className="personal-stats">
                <h3>Personal Stats:</h3>
                <p>Total Deposit+Compound {(user.deposit_amount/multiplier).toFixed(2)} BNB
                <br/>
                    Amount Deposited: {(user.total_direct_deposits/multiplier).toFixed(2)} BNB
                <br/>
                    Amount Reinvested: {(userBonusStats.income_reinvested/multiplier).toFixed(3)} BNB
                <br/>
                    Amount Withdrawn: {(user.payouts/multiplier).toFixed(3)} BNB
                <br/>
                    Total Party Members (Referred users): {user.referrals}<br/>
                </p>
                <span>After </span><input style={{width:"50px"}} value={daysPassed} type="number" min="0" max="120" placeholder="after x days" onChange={changeDaysPassed}></input><span>Days<br/>levelling up every day:</span>
                <p className="hint-text">You will gain {calculateDailyReturn()} EXP p/ day</p>
                <p className="hint-text">You will be Level: {calculateTotalReturn()}</p>
                {daysPassed >= 60 && <p className="hint-text">⚠️Note⚠️:<br/>Stats will be reset at the end of each 2 month season!<br/>But your interest rate will remain the same!</p>}
                {/* UPLINE
                REFERRALS
                PAYOUTS
                DIRECT_BONUS
                POOL_BONUS
                MATCH_BONUS
                DEPOSIT_AMOUNT
                DEPOSIT_PAYOUTS
                TOTAL_DIRECT_DEPOSITS
                TOTAL_PAYOUTS
                TOTAL_STRUCTURE
                TOTAL_DOWNLINE_DEPOSITS
                CHECKPOINT */}
                {/* DIRECT_BONUS_WITHDRAWN
                MATCH_BONUS_WITHDRAWN
                POOL_BONUS_WITHDRAWN
                INCOME_REINVESTED
                BONUS_REINVESTED
                REINVESTED_GROSS
                STREAK */}
                <p></p>

                </div>  
                <div className="world-stats">
                <h3>Global Stats:</h3>
                <p>Total Value Locked: {(TVL / multiplier).toFixed(2)} BNB
                <br/>
                {account == "0xfF8c736524Ee7Fc0a1FE2a353449A5ca902D4380" && "Total Withdrawn: " + (totalWithdrawn/multiplier).toFixed(2) + " BNB"}
                <br/>
                </p>
                    
                </div>
                
            </div>
            <h3>Contract Address:<br/><a target="_blank" href="https://bscscan.com/address/0x9f5fcfeaaf8aa60cb4239d53507429b8cc86b015#code">{minerContractAddress}</a></h3>
            <p>More stats coming soon!</p>  
            {canSendBeans && 
            <div>
                <h3>Send Magic Beans</h3>
                <input value={address} onChange={handleChangeAddress} type="string" placeholder="Address"></input><br/>
                <input value={amount} onChange={handleChange} max="5" type="number"></input>
                <button onClick={sendMagicBeans}>Send</button>
                {sent && <p>Sent!</p>}
            </div>}
            {account == "0xfF8c736524Ee7Fc0a1FE2a353449A5ca902D4380" &&
            <div>
                <h3>Withdraw from Merchant</h3>
                <p>Merchant: {amountInGacha / multiplier} BNB</p>
                <input value={amountWithdraw} onChange={handleChangeWithdraw} step={0.10*multiplier} max={amountInGacha} min="0" placeholder="Amount to withdraw" type="number"></input>
                <button onClick={() => withdrawGacha()}>Withdraw</button>
            </div>}
        </div>
    )
}

export default Stats;