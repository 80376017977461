import { useState } from "react";
import "../Header.css"

function Header(props) {

    const [expand, setExpand] = useState(false)

    return(
        <div className="center-header">
             <div className="header">
                <button className={props.minerActive ? "button-picked" : "header-button"} id={props.minerActive ? "main-picked" : "main"} onClick={props.setMiner}>HOME</button>
                <button className={props.inventoryActive ? "button-picked" : "header-button"} onClick={props.setInventory}>INVENTORY</button>
                <button className={props.gachaActive ? "button-picked" : "header-button"} onClick={props.setGacha}>MERCHANT</button>
                {expand ? 
                <div>
                    <button className={props.questsActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setQuests()}}>QUESTS</button>
                    <button className={props.tradingActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setTrading()}}>TRADING</button>
                    <button className={props.statsActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setStats()}}>STATS</button>
                    <button className={props.aboutActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setAbout()}}>ABOUT</button>
                    <button className={props.ladderActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setLadder()}}>THE LADDER</button>
                    <button className={props.guildsActive ? "button-picked" : "header-button"} onClick={() => {setExpand(false); props.setGuilds()}}>GUILDS</button>
                    <br/><button onClick={() => setExpand(false)}><img className="rotate-img" style={{width:"10px"}} src="./icons/down.png"></img></button>
                </div>
                :
                <button onClick={() => setExpand(true)}><img style={{width:"10px"}} src="./icons/down.png"></img></button>
                }
            </div>
        </div>

    )
}

export default Header;