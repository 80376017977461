import { useEffect, useState } from "react";
import TradeItem from "./TradeItem";
import "../Warning.css"

function TradeRequest(props) {

    const [disabled, setDisabled] = useState(false);

    function checkTime() {
        console.log(parseFloat(props.timestamp) - parseFloat(props.trade.timeInitiated));
        return parseFloat(props.trade.timeLimit) - (parseFloat(props.timestamp) - parseFloat(props.trade.timeInitiated));
        
    }

    function checkEquipped() {
        if(!props.offer) {
            var i = 0;
            var a = 0;
            for(i; i < props.trade.itemsOffered.length ; i++) {
                if (props.otherEquipped[props.trade.itemsOffered[i]] == true) {
                    setDisabled(true);
                    console.log("your partner has an item equipped");
                }
                console.log(i + " items")
            }
            for(var a; a < props.trade.itemsRequested.length ; a++) {
                if (props.equipped[props.trade.itemsRequested[a]] == true) {
                    setDisabled(true);
                    console.log("you have one of the items equipped")
                }
                
            }
            console.log("checked");
            console.log(props.equipped);
            console.log(props.otherEquipped);
            console.log(props.trade.itemsOffered.length);
        }
    }

    useEffect(() => {}, [])

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <p>{props.offer ? props.otherPlayerNick : "You"} will receive:</p>
                    {props.trade.itemsOffered.map((item, index) => {
                        return(
                        <span>{props.relics[item].relicName} x {props.trade.amountsOffered[index]}<br/></span>
                        )
                    })}
                    <p>{!props.offer ? props.otherPlayerNick : "You"} will receive:</p>
                    {props.trade.itemsRequested.map((item, index) => {
                        return(
                        <span>{props.relics[item].relicName} x {props.trade.amountsRequested[index]}<br/></span>
                        )
                    })}
                    <br/>
                    {!props.offer && <button disabled={props.checkEquipped} onClick={props.acceptTrade}>Accept trade</button>}<br/>
                    {!props.offer && <button onClick={props.cancel}>Reject trade</button>}
                    {props.offer && <button onClick={props.cancel}>Cancel trade</button>}
                    <br/>
                    {props.success && <p>Trade successful!</p>}
                    {parseFloat(props.trade.timeLimit) > parseFloat(props.timestamp) - parseFloat(props.trade.timeInitiated) ? <p>{Math.floor(checkTime()/3600)} Hours and {Math.floor((checkTime()/60)%60)} minutes left</p> :
                    <p>This trade has expired.<br/>Press the button above to cancel your trade<br/>
                    and regain access to offered items.
                    </p>}
                    {!props.offer && props.checkEquipped && <p>⚠️CANNOT TRADE WHILE ITEMS ARE EQUIPPED!⚠️</p>}
                    <button onClick={props.close}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default TradeRequest