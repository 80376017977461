import "../Warning.css"

function BossItem(props) {

    const statsMultiplier = 100000000000000000

    const bonusType = [
        "",
        "Compound bonus when leveling Strength",
        "Compound bonus when leveling Dexterity",
        "Compound bonus when leveling Intelligence",
        "Bonus to Strength gained when compounding",
        "Bonus to Dexterity gained when compounding",
        "Bonus to Intelligence gained when compounding",
        "Higher chance to beat boss",
        "Loss when defeated by a boss",
        "Seconds less to the withdrawal penalty",
        "Immediate gain to your Strength stat",
        "Immediate gain to your Dexterity stat",
        "Immediate gain to your Intelligence stat",
        "to STR when equipped",
        "to DEX when equipped",
        "to INT when equipped",
        "Allow the user to upgrade the 3 attributes simultaneously",
        "Compound bonus when leveling all 3 attributes simultaneously",
        "Seconds less to your recovery time before fighting a boss",
        "% higher chance to find a Magic Bean",
        "Shorten the recovery time to stumble into random events"
      ]

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <div className="boss-item-text">
                        <h2>{props.relic.relicName}</h2>
                    </div>
                    <img src={"./items/img-only-border/" + props.id + ".jpg"} className="display-image"></img>
                    <div className="item-effect">
                        <h3>Effect:</h3>
                        {props.relic.bonusType == 0 &&
                        <span>Quest Item</span>}
                        <p>{props.relic.bonusType < 7 && props.relic.bonusType != 0 &&
                        <span>x{props.relic.multiplier / 100} {bonusType[props.relic.bonusType]}</span>
                        }
                        {props.relic.bonusType == 7 &&
                        <span>+{props.relic.multiplier}% {bonusType[props.relic.bonusType]}</span>
                        }     
                        {props.relic.bonusType == 8 &&
                        <span>Keep {props.relic.multiplier}% of your compound when defeated by a boss</span>
                        }
                        {props.relic.bonusType == 9 &&
                        <span>You can withdraw with no penalty {(props.relic.multiplier / 3600).toFixed()} Hours earlier than usual</span>
                        }                            
                        {props.relic.bonusType > 9 && props.relic.bonusType < 16 &&
                        <span>+{props.relic.multiplier / statsMultiplier} {bonusType[props.relic.bonusType]}</span>
                        }
                        {props.relic.bonusType == 16 &&
                        <span>{bonusType[props.relic.bonusType]}</span>}
                        {props.relic.bonusType == 17 &&
                        <span>x{props.relic.multiplier / 100} {bonusType[props.relic.bonusType]}</span>}
                        {props.relic.bonusType == 18 &&
                        <span>You can fight a boss {(props.relic.multiplier/3600).toFixed()} Hours earlier than usual</span>
                        }
                        {props.relic.bonusType == 19 &&
                        <span>+{props.relic.multiplier}{bonusType[props.relic.bonusType]}</span>
                        }
                        {props.relic.bonusType == 20 &&
                        <span>{bonusType[props.relic.bonusType]} by {(props.relic.multiplier/3600).toFixed()} Hours</span>
                        }
                        </p>
                        <p>{props.relic.consumable ? "One time use only" : "Permanent Item"}</p>
                    </div>
                    
                    <button onClick={props.disableBox}>Close</button>
                </div>

            </div>
        </div>
    )
}

export default BossItem