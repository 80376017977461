import { useEffect, useState } from "react";
import "../Warning.css"

function Deposit(props) {

    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    const beansMultiplier = 100000000000000;

    const attribute = props.attribute;
    const cBonus = attribute < 3 ? attribute + 1 : 17;
    const sBonus = attribute + 4;

    const deposit = props.toDeposit * multiplier;
    const depositedAmount = parseInt(props.depositedAmount);
    const [daysPassed, setDaysPassed] = useState(1);
    const [calculator, setCalculator] = useState(false);

    const buttonClass=["animated-button1", "animated-button5", "animated-button8", "animated-button3", ""];    



    function calculateDeposit() {
        var _deposit = ((((depositedAmount + deposit) * 0.03 * 86400) / 86400));
        return _deposit
    }

    function calculateTotalReturn() {
        return (((depositedAmount + deposit) * 1.03 ** daysPassed) / statsMultiplier).toFixed();
    }

    function calculateDailyReturn() {
        var base = deposit + depositedAmount;
        var amount;
        var i = 0;
        for(i; i < daysPassed; i++) {
            amount = base * 0.03;
            base += amount;
        }
        return (amount / expMultiplier).toFixed()
    }

    function calculateFullPayoutStat(bonus) {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)));
        if(props.equipped.bonuses[bonus]) {
            _payout = _payout * props.equipped.multipliers[bonus] / 100;
        }
        return _payout
    }

    function calculateFullPayoutStatPlusDeposit(bonus) {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)));
        _payout += deposit;
        if(props.equipped.bonuses[bonus]) {
            _payout = _payout * props.equipped.multipliers[bonus] / 100;
        }
        return _payout;
    }

    function bonusCalculator(num, bonus) {
        var bonus = num == 0 ? props.equipped.multipliers[cBonus] % 10000 : props.equipped.multipliers[bonus] % 10000;
        return bonus;
    }

    function changeDaysPassed(event) {
        setDaysPassed(event.target.value);
    }



    useEffect(() => {
    },[])
    var _deposit = (depositedAmount + deposit) * 0.03 * (props.timestamp - props.checkpoint) / 86400;


    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <h2>DEPOSIT:</h2>
                    <p>You are about to deposit {(deposit/multiplier)} BNB.
                    {depositedAmount > 0 && <p>And compound {(calculateFullPayoutStat() / multiplier).toFixed(5)} BNB</p>}
                    {/*<br/>This will grant you:<br/>
                    <span style={{textDecoration:"underline"}}>{deposit / beansMultiplier} POWER</span> */}
                    </p>
                    {/* {depositedAmount > 0 && <p>With this deposit, you will raise your POWER to {(deposit + depositedAmount) / beansMultiplier}</p>}
                    {depositedAmount > 0 ?
                    <span>
                    This will improve your daily return by<br/>
                    {calculateDeposit()/multiplier} BNB or {calculateDeposit() / beansMultiplier} BEANS<br/><br/>
                    </span>
                    :
                    <span>
                    This will give you an initial daily return of<br/>
                    {calculateDeposit()/multiplier} BNB or {calculateDeposit() / beansMultiplier} BEANS<br/><br/></span>}
                    This daily return will gain interest on every subsequent Compound. */}
                    {props.attribute == 3 && 
                    <div>
                        <p className="attribute-str">Strength experience earned: {((calculateFullPayoutStatPlusDeposit(4) / expMultiplier) / 3).toFixed()} exp</p>
                        <p className="attribute-dex">Dexterity experience earned: {((calculateFullPayoutStatPlusDeposit(5) / expMultiplier) / 3).toFixed()} exp</p>
                        <p className="attribute-int">Intelligence experience earned: {((calculateFullPayoutStatPlusDeposit(6) / expMultiplier) / 3).toFixed()} exp</p>
                    </div>
                    }
                    {props.attribute == 0 && <p className="attribute-str">Strength experience earned: {(calculateFullPayoutStatPlusDeposit(sBonus) / expMultiplier).toFixed()} exp</p>}
                    {props.attribute == 1 && <p className="attribute-dex">Dexterity experience earned: {(calculateFullPayoutStatPlusDeposit(sBonus) / expMultiplier).toFixed()} exp</p>}
                    {props.attribute == 2 && <p className="attribute-int">Intelligence experience earned: {(calculateFullPayoutStatPlusDeposit(sBonus) / expMultiplier).toFixed()} exp</p>}
                    <p className="hint-text">Hint: 1 Stat Point = 1000 exp</p>                    
                    {props.equipped.bonuses[sBonus] &&
                    <p className="hint-text">+{bonusCalculator(1, sBonus) - 100}% Bonus provided by {props.equipped.equippedRelics[sBonus].relicName} {props.equipped.equippedRelics[sBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / expMultiplier).toFixed()} exp Bonus from Party Members.</p>}
                    {calculator ?
                    <div>
                        <span>After </span><input style={{width:"50px"}} value={daysPassed} type="number" min="1" max="120" placeholder="after x days" onChange={changeDaysPassed}></input><span>Days<br/>levelling up every day:</span>
                        <p className="hint-text">You will gain {calculateDailyReturn()} EXP p/ day</p>
                        <p className="hint-text">You will be Level: {calculateTotalReturn()}</p>
                        {daysPassed >= 60 && <p className="hint-text">⚠️Note⚠️:<br/>Stats will be reset at the end of each 2 month season!<br/>But your interest rate will remain the same!</p>}
                        <button onClick={() => setCalculator(false)}>Close Calculator</button>
                    </div> :
                    <button onClick={() => setCalculator(true)}>Interest Calculator</button>
                    }
                    <p>{depositedAmount > 0 ? "Total Compound: " + (calculateFullPayoutStat() / multiplier).toFixed(5) + " BNB" : "-"}</p>
                    {props.equipped.bonuses[cBonus] &&
                    <p className="hint-text">+{bonusCalculator(0) - 100}% Bonus provided by {props.equipped.equippedRelics[cBonus].relicName} {props.equipped.equippedRelics[cBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / multiplier).toFixed(5)} BNB from Party Members.</p>}

                    <button onClick={props.deposit} className={buttonClass[attribute]}>
                    {<div><span></span>
                    <span></span>
                    <span></span>
                    <span></span></div>}
                    DEPOSIT
                    </button>

                <br/><br/>
                    <button onClick={props.disableDeposit}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default Deposit