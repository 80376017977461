import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import Web3 from "web3";
import "../Start.css";
// import MinerContract from "../contracts/minerabi.json";
// import NFTContract from "../contracts/nfts.json";

function Start(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const [line, setLine] = useState(0);

    const storyOne =
    [
        "Finally, you're here! The Three Kingdoms need your assistance.",
        "That is not important, the Ancient Dragon Lord Nefagan\nhas awoken and begun it's reign of terror.\nHalf the city of Dolron,\nthe capital of the Kingdom of Man,\nis completely destroyed.",
        "We need all the assistance we can possibly get.\nPlease join one of the guilds and begin your growth,\nwe need as many men and women on the front lines as possible.\nTell your friends,\nfor if we fail in our quest it just might cost us the entire realm.",
        "There will be treasure in it for you too, don't worry.\nOur most dedicated warriors will see riches beyond imagination.",
        "One more thing, brave one... What name do you go by?"
    ]

    const replyOne =
    [
        "Who are you?",
        "Ok...",
        "How do I profit from this?",
        "Alright!",
        "My name is not important."
    ]

   
    // const nftContractAddress = props.nftContractAddress;
    // const minerContractAddress = props.minerContractAddress;
    // const bossContractAddress = props.bossContractAddress;

    // const nftContract = new web3.eth.Contract(
    //     NFTContract,
    //     nftContractAddress
    // );
    // const minerContract = new web3.eth.Contract(    
    //     MinerContract,
    //     minerContractAddress
    // )

    const [nick, setNick] = useState("");

    function handleNextLine() {
        var _line = line;
        setLine(_line + 1);
    }

    async function handleChange(event) {
        setNick(event.target.value);
    }

    async function handleClick() {
        props.setName(nick);
    }

    useEffect(() => {props.connect(); props.getName()}, [])
    
    return(
        <div>
        <div className="black-screen">
            <div className="start-text">
                {!props.active &&
                <p>Connect your wallet to proceed</p>
                }
                {props.loadingName && <p>Loading information...<br/>(Try refreshing if this is taking too long)</p>}
                {props.active && props.nickname == "" && !props.loadingName && props.deposited == 0 && 
                <div className="story-div">
                    <img style={{width:"200px"}} id="splash" src="./Characters/Fairy/fairy1.jpg" ></img>
                    <pre className="story-text">{storyOne[line]}</pre>
                    {line != 4 && <button onClick={handleNextLine}>{replyOne[line]}</button>}
                    {line == 4 && <div>
                        <input placeholder="name" type="text" value={nick} onChange={handleChange}></input>
                        <button onClick={handleClick}>Send</button>
                        {props.nickFail && <p style={{fontSize:"0.8em"}}>This name is invalid: (It is either already in use, or it exceeds the maximum of 10 characters) </p>}
                        <br/>
                        <button onClick={props.setMinerActive} className="story-text">My name is not important</button>
                    </div>}
                </div>
                }
                {props.active && props.nickname != "" && 
                <div>
                    <img style={{width:"200px"}} id="splash" src="./Characters/Fairy/fairy1.jpg" ></img>
                    <p>Welcome {props.nickname}</p>
                    {parseFloat(props.magicBeans) > 0 && <p>You have <span style={{textShadow:"0 0 5px white"}}>{props.magicBeans}</span> Magic Bean{parseFloat(props.magicBeans) > 1 && "s"} to spend.</p>}
                    {props.tradesTo.length > 0 && <p>You have <span style={{textShadow:"0 0 5px white"}}>{props.tradesTo.length}</span> pending trade request{props.tradesTo.length > 1 && "s"}.</p>}
                    <button onClick={props.setMiner}>Start</button>
                </div>
                }
                {!props.loadingName && props.deposited > 0 && props.nickname == "" &&
                <div>
                    <img style={{width:"200px"}} id="splash" src="./Characters/Fairy/fairy1.jpg" ></img>
                    <pre className="story-text">Welcome back...<br/>We remember you, but you can remain anonymous if you want...</pre>
                    <input placeholder="name" type="text" value={nick} onChange={handleChange}></input>
                        <button onClick={handleClick}>Send</button>
                        {props.nickFail && <p style={{fontSize:"0.8em"}}>This name is invalid: (It is either already in use, or it exceeds the maximum of 10 characters) </p>}
                        <br/>
                        <button onClick={props.setMiner}>I will remain anonymous for now.</button>
                </div>
                }
            </div>
        </div>
        </div>
    )
}

export default Start;