import { useEffect } from "react";
import "../Warning.css"

function Compound(props) {

    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    // const beansMultiplier = 1000000000000;
    const beansMultiplier = 100000000000000;

    const stats = props.stats;


    const attribute = props.attribute;
    const cBonus = attribute < 3 ? attribute + 1 : 17;
    const sBonus = attribute + 4;

    const cBonusActivated = attribute == 3 ? props.equipped.bonuses[17] : props.equipped.bonuses[attribute + 1];
    // const sBonusActivated = attribute == 3 ? false : props.equipped.bonuses[attribute + 4];

    function calculateFullPayoutReinvest() {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)) * 1.05);
        if(cBonusActivated) {
            _payout = _payout * props.equipped.multipliers[cBonus] / 100;
        }
        return _payout
    }
    
    function checkIfCapped(stat) {
        if((stats[attribute] / statsMultiplier) + calculateFullPayoutStat(sBonus) / statsMultiplier >= 100 + props.equipped.multipliers[stat+13] / statsMultiplier)
            {
                return true;
            } 
        else
            {
                return false;
            }
    }

    function calculateFullPayoutStat(bonus) {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)) * 1.05);
        if(props.equipped.bonuses[bonus]) {
            _payout = _payout * props.equipped.multipliers[bonus] / 100;
        }
        return _payout
    }

    function bonusCalculator(num, bonus) {
        var bonus = num == 0 ? props.equipped.multipliers[cBonus] % 10000 : props.equipped.multipliers[bonus] % 10000;
        return bonus;
    }



    useEffect(() => {
        console.log((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus) )* 1.05);
        console.log(sBonus);
    },[])

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <p>LEVEL UP:</p>
                    <p>Consuming these {(props.payout / beansMultiplier).toFixed(0)} Beans will make you permanently stronger:</p>
                    {props.attribute == 3 && 
                    <div>
                        <p>Strength experience earned: {((calculateFullPayoutStat(4) / expMultiplier) / 3).toFixed()} exp</p>
                        <p>Dexterity experience earned: {((calculateFullPayoutStat(5) / expMultiplier) / 3).toFixed()} exp</p>
                        <p>Intelligence experience earned: {((calculateFullPayoutStat(6) / expMultiplier) / 3).toFixed()} exp</p>
                    </div>
                    }
                    {props.attribute == 0 && <p>Strength experience earned: {(calculateFullPayoutStat(sBonus) / expMultiplier).toFixed()} exp</p>}
                    {props.attribute == 1 && <p>Dexterity experience earned: {(calculateFullPayoutStat(sBonus) / expMultiplier).toFixed()} exp</p>}
                    {props.attribute == 2 && <p>Intelligence experience earned: {(calculateFullPayoutStat(sBonus) / expMultiplier).toFixed()} exp</p>}
                    {checkIfCapped(attribute) && <p>⚠️STAT IS CAPPED AND CANNOT GO HIGHER⚠️</p>}
                    <p className="hint-text">Hint: 1 Stat Point = 1000 exp</p>                    
                    {props.attribute == 3 ?
                    <div>
                    {props.equipped.bonuses[4] &&
                    <p className="hint-text">+{bonusCalculator(1, 4) - 100}% Bonus provided by {props.equipped.equippedRelics[4].relicName} {props.equipped.equippedRelics[4].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.equipped.bonuses[5] &&
                    <p className="hint-text">+{bonusCalculator(1, 5) - 100}% Bonus provided by {props.equipped.equippedRelics[5].relicName} {props.equipped.equippedRelics[5].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.equipped.bonuses[6] &&
                    <p className="hint-text">+{bonusCalculator(1, 6) - 100}% Bonus provided by {props.equipped.equippedRelics[6].relicName} {props.equipped.equippedRelics[6].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / expMultiplier).toFixed()} exp Bonus from Party Members.</p>}
                    </div> :
                    <div>{props.equipped.bonuses[sBonus] &&
                    <p className="hint-text">+{bonusCalculator(1, sBonus) - 100}% Bonus provided by {props.equipped.equippedRelics[sBonus].relicName} {props.equipped.equippedRelics[sBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / expMultiplier).toFixed()} exp Bonus from Party Members.</p>}
                    </div>
                    }
                    <p>Total Compound: {(calculateFullPayoutReinvest() / multiplier).toFixed(5)} BNB </p>
                    {props.equipped.bonuses[cBonus] &&
                    <p className="hint-text">+{bonusCalculator(0, sBonus) - 100}% Bonus provided by {props.equipped.equippedRelics[cBonus].relicName} {props.equipped.equippedRelics[cBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / multiplier).toFixed(5)} BNB from Party Members.</p>}

                    <button onClick={props.compound}>Level Up</button><br/><br/>
                    <button onClick={props.disableCompound}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default Compound