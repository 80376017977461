import { useEffect, useState } from "react";
import "../Gacha.css";
import "../Trading.css";
import TradeItem from "./TradeItem";
import TradeRequest from "./TradeRequest";
import NFTContract from "../contracts/nfts.json";
import GachaContract from "../contracts/gacha.json";
import TradingContract from "../contracts/trading.json";
import MinerContract from "../contracts/minerabi.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";
import { render } from "@testing-library/react";
import truncateEthAddress from "truncate-eth-address";

function Trading(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const gachaContractAddress = props.gachaContract;
    const tradingAddress = props.tradingAddress;
    const minerAddress = props.minerAddress;

  const nftContract = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  )

  const gacha = new web3.eth.Contract(
    GachaContract,
    gachaContractAddress
  )

  const trading = new web3.eth.Contract(
    TradingContract,
    tradingAddress
  )

  const miner = new web3.eth.Contract(
    MinerContract,
    minerAddress
  )

  const [inventoryLoaded, setInventoryLoaded] = useState(false);
  const [balances, setBalances] = useState([]);
  const [relics, setRelics] = useState([]);
  const [equipped, setEquipped] = useState([]);
  
  const [itemSelected, setItemSelected] = useState(null);
  const [selected, setSelected] = useState(false);
  const [bonusFilter, setBonusFilter] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);
  const [otherBonusFilter, setOtherBonusFilter] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);

  const bonusTypes = [
    "All", "Compound", "Level up", "Stat Bonus", "Boss Fight", "Time", "Special", "Strength", "Dexterity", "Intelligence", "Quest"
  ]

  const [loadingTrading, setLoadingTrading] = useState(true);

  const [creatingTrade, setCreatingTrade] = useState(false);
  const [creatingTradeWith, setCreatingTradeWith] = useState(false);
  const [approved, setApproved] = useState();
  const [tradingWith, setTradingWith] = useState("");
  const [tradingWithName, setTradingWithName] = useState("");
  const [unavailableItems, setUnavailable] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [amounts, setAmounts] = useState([]);
  const [timeLimit, setTimeLimit] = useState(1);

  const [otherPlayerBalances, setOtherPlayerBalances] = useState([]);
  const [otherPlayerEquipped, setOtherPlayerEquipped] = useState([]);
  const [otherUnavailable, setOtherUnavailable] = useState([]);

  const [itemsRequested, setItemsRequested] = useState([]);
  const [amountsRequested, setAmountsRequested] = useState([]);
  const [otherPlayerNick, setOtherPlayerNick] = useState("");

  const [tradesTo, setTradesTo] = useState([])
  const [tradesBy, setTradesBy] = useState([]);
  const [allTrades, setAllTrades] = useState([]);
  const [expiredTrades, setExpiredTrades] = useState([]);

  const [timestamp, setTimestamp] = useState(0);
  
  const [tradeRequestOpen, setTradeRequestOpen] = useState(false);
  const [tradeOfferOpen, setTradeOfferOpen] = useState(false);

  const [tradeRequests, setTradeRequests] = useState([]);
  const [tradeOffers, setTradeOffers] = useState([]);

  const [tradeSuccessful, setTradeSuccessful] = useState(false);
  const [tradeCancelled, setCancelled] = useState(false);


  function changeBonusFilter(bonus, other) {
    var _filter = [];
    if(bonus == 0) {
      _filter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    }
    if(bonus == 1) {
      _filter = [1, 2, 3, 17];
    }
    if(bonus == 2) {
      _filter = [4, 5, 6];
    }
    if(bonus == 3) {
      _filter = [10, 11, 12, 13, 14, 15];
    }
    if(bonus == 4) {
      _filter = [7, 8, 18];
    }
    if(bonus == 5) {
      _filter = [9, 18, 20];
    }
    if(bonus == 6) {
      _filter = [16, 19, 20];
    }
    if(bonus == 7) {
      _filter = [1, 4, 10, 13];
    }
    if(bonus == 8) {
      _filter = [2, 5, 11, 14];
    }
    if(bonus == 9) {
      _filter = [3, 6, 12, 15]
    }
    if(bonus == 10) {
      _filter = [0];
    }
    console.log(bonus);
    console.log(_filter)
    other ? setOtherBonusFilter(_filter) : setBonusFilter(_filter);
  }

  async function setApproval(bool) {
    try {
      await nftContract.methods.setApprovalForAll(tradingAddress, bool).send({
        from: account,
        gas: 50000
      })
      getTrades();
    } catch (error) {
      console.error(error)
    }
  }

  async function checkEquipped(tradeId, address) {
    const _otherPlayerEquipped = await nftContract.methods.getRelicsEquipped(address).call();
    var _disabled = false;
    var i = 0;
    var a = 0;
    for(i; i < allTrades[tradeId].itemsOffered.length ; i++) {
      if (_otherPlayerEquipped[allTrades[tradeId].itemsOffered[i]] == true) {
        _disabled = true;
        console.log("your partner has an item equipped");
      }
      console.log(i + " items")
    }
    for(var a; a < allTrades[tradeId].itemsRequested.length ; a++) {
      if (equipped[allTrades[tradeId].itemsRequested[a]] == true) {
        _disabled = true;
        console.log("you have one of the items equipped")
      }
    }
      console.log("checked");
    }


  async function getItems() {
    setInventoryLoaded(false);
    const _balances = await nftContract.methods.getRelicsBalances(account).call();
    const _equipped = await nftContract.methods.getRelicsEquipped(account).call();
    const _relics = await nftContract.methods.getRelics().call();
    await setRelics(_relics);
    await setBalances(_balances);
    await setEquipped(_equipped)
    setInventoryLoaded(true);
  }

  async function getItemsTradingWith() {
    setInventoryLoaded(false);
    const _balances = await nftContract.methods.getRelicsBalances(account).call();
    const _relics = await nftContract.methods.getRelics().call();
    const _equipped = await nftContract.methods.getRelicsEquipped(account).call();
    const _otherBalances = await nftContract.methods.getRelicsBalances(tradingWith).call();
    const _otherEquipped = await nftContract.methods.getRelicsEquipped(tradingWith).call();
    const _otherUnavailable = await trading.methods.getItemsInOffer(tradingWith).call();
    const _nick = await miner.methods.getNicknameToAddress(tradingWith).call()
    await setRelics(_relics);
    await setBalances(_balances);
    await setEquipped(_equipped)
    setOtherPlayerBalances(_otherBalances);
    setOtherPlayerEquipped(_otherEquipped);
    setOtherUnavailable(_otherUnavailable);
    setOtherPlayerNick(_nick);
    console.log(_nick)
    console.log(_otherBalances);
    setInventoryLoaded(true);
  }

  async function getTrades() {
    const _timestamp = await miner.methods.getBlockTimeStamp().call();
    setTimestamp(_timestamp);
    const _allTrades = await trading.methods.getAllTrades().call();
    const _trades = await trading.methods.getTradesToAddress(account).call();
    const _tradesBy = await trading.methods.getTradesByAddress(account).call();
    const _expiredTrades = _tradesBy.filter(trade => !trade.fulfilled && parseFloat(trade.timeLimit) <= parseFloat(_timestamp) - parseFloat(trade.timeInitiated));
    console.log(_trades);
    console.log(_tradesBy);
    setAllTrades(_allTrades);
    setTradesTo(_trades);
    setTradesBy(_tradesBy);
    setExpiredTrades(_expiredTrades);
    const _relics = await nftContract.methods.getRelics().call();
    await setRelics(_relics);
    const _approval = await nftContract.methods.isApprovedForAll(account, tradingAddress).call();
    console.log(_approval);
    setApproved(_approval);
    const _unavailableItems = await trading.methods.getItemsInOffer(account).call();
    console.log(_unavailableItems);
    setUnavailable(_unavailableItems);
    let _requests = new Array(_trades.length).fill(false);
    setTradeRequests(_requests);
    console.log(_requests);
    let _offers = new Array(_tradesBy.length).fill(false);
    setTradeOffers(_offers)
    setLoadingTrading(false);
  }

  async function createTradeWith() {
    try {
      await trading.methods.setTrade(tradingWith, itemsSelected, amounts, itemsRequested, amountsRequested, (timeLimit*86400)).send(
        {
          from: account,
          gas: 700000
        }
      );
      reset();
      getTrades();
      setTimeLimit("");
    } catch (error) {
      console.error(error);
    }
  }

  async function acceptTrade(id) {
    try {
      await trading.methods.acceptTrade(id).send(
        {
          from:account,
          gas: 700000
        }
      )
      setTradeSuccessful(true)
      getTrades();
    } catch (error) {
      console.error(error)
    }
  }

  async function cancelTrade(id) {
    try {
      await trading.methods.closeTrade(id).send({
        from: account,
        gas: 100000
      })
      setCancelled(true);
      getTrades();
    } catch (error) {
      console.error(error);
    }
  }

  function handleSelect(item) {
    const _i = itemsSelected;
    const _a = amounts;
    if(!itemsSelected.includes(item)) {
      setItemsSelected(prevItems => {
        return [...prevItems, item];
      });
      setAmounts(prevAmounts => {
        return [...prevAmounts, 1]
      })
    }
    else {
      _a[_i.indexOf(item)]++;
      setAmounts(_a);
      setItemsSelected(prevItems => {
        return [...prevItems];
      });
    }
    console.log(_a);
    console.log(itemsSelected);
  }

  function handleSelectRequested(item) {
    const _i = itemsRequested;
    const _a = amountsRequested;
    if(!itemsRequested.includes(item)) {
      setItemsRequested(prevItems => {
        return [...prevItems, item];
      });
      setAmountsRequested(prevAmounts => {
        return [...prevAmounts, 1]
      })
    }
    else {
      _a[_i.indexOf(item)]++;
      setAmountsRequested(_a);
      setItemsRequested(prevItems => {
        return [...prevItems];
      });
    }
    console.log(_a);
    console.log(itemsRequested);
  }


  function changeTradingWith(event) {
    setTradingWith(event.target.value);
  }

  function changeNameTradingWith(event) {
    setTradingWithName(event.target.value);
  }

  async function openTradeWithName() {
    const _addr = await miner.methods.getAddressToNickname(tradingWithName).call();
    setTradingWith(_addr);
    const _isUser = await nftContract.methods.isUser(_addr).call();
    console.log(_isUser);
    if(!_isUser) {
      alert("This Address does not seem to be a BeanQuest player");
    }
    else {
      getItemsTradingWith();
    }
  }

  function reset() {
    setAmounts([]);
    setItemsSelected([]);
    setItemsRequested([]);
    setAmountsRequested([]);
  }

  async function openTradeWith() {
    const _isUser = await nftContract.methods.isUser(tradingWith).call();
    console.log(_isUser);
    if(!_isUser) {
      alert("This Address does not seem to be a BeanQuest player");
    }
    else {
      setCreatingTradeWith(true);
      getItemsTradingWith();
    }
  }

  function handleTimeLimit(event) {
    setTimeLimit(event.target.value);
  }

  async function findPlayerNick(address) {
    setOtherPlayerNick(await miner.methods.getNicknameToAddress(address).call());
    setEquipped(await nftContract.methods.getRelicsEquipped(account).call());
    setOtherPlayerEquipped(await nftContract.methods.getRelicsEquipped(address).call());
  }

  function openTradeRequestBox(index, bool) {
    var _requests = tradeRequests;
    _requests[index] = bool;
    setTradeRequests(_requests);
    setTradeRequests(prevItems => {
      return [...prevItems];
    });
    setCancelled(false);
    setTradeSuccessful(false);
  }

  function openTradeOfferBox(index, bool) {
    var _offers = tradeOffers;
    _offers[index] = bool;
    setTradeOffers(_offers);
    setTradeOffers(prevItems => {
      return [...prevItems];
    });
    setCancelled(false);
    setTradeSuccessful(false);
  }

  useEffect(() => {getTrades()}, []);

  return(
    <div className="trade-box">
        <h2>Trading Menu</h2>
        {loadingTrading && <div>
          <p>Please wait while this page loads...</p>
        </div>}
        {!approved && !loadingTrading && <div>
          <p>You must allow the Trading contract to transact your NFTs in order to trade with other users.<br/>The contract is verified and there is no way for this contract to transfer any of your NFTs without your permission.</p>
          <button onClick={() => setApproval(true)}>Give Approval</button>
        </div>}
        {approved && 
        <div>
        {/* <button onClick={() => {setCreatingTrade(true); getItems()}}>Create Trade to noone in specific</button>
        <br/>
        <button>See trading offers available</button> */}
        <p>Trade requests you made:</p>
        <table className="items-table">
          <tbody>
            <tr>
              <th>User</th>
              <th>Other player receives</th>
              <th>You receive</th>
              <th>Details</th>
            </tr>

          {tradesBy.map((trade, index) => {
                        return(
                          !trade.fulfilled && parseFloat(trade.timeLimit) > parseFloat(timestamp) - parseFloat(trade.timeInitiated) && <tr key={index}>
                            <td>{truncateEthAddress(trade.requestTo)}</td>
                            <td>{trade.itemsOffered.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsOffered[index]}<br/></p>
                              )
                            } )}</td>
                            <td>{trade.itemsRequested.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsRequested[index]}<br/></p>
                              )
                            } )}</td>
                            <td><button onClick={() => {findPlayerNick(trade.requestTo); openTradeOfferBox(index, true)}} style={{fontSize:"0.8em"}}>See trade</button></td>
                            {tradeOffers[index] &&
                              <TradeRequest equipped={equipped} timestamp={timestamp} cancel={() => cancelTrade(trade.tradeId)} offer={true} index={index} relics={relics} trade={trade} otherPlayerNick={otherPlayerNick} close={() => openTradeOfferBox(index, false)}/>
                            }
                          </tr>
                        )
                      })}
          </tbody>
        </table>
        {expiredTrades.length > 0 && <p>Expired Trades:</p>}
        {expiredTrades.length > 0 &&
          <table className="items-table">
          <tbody>
            <tr>
              <th>User</th>
              <th>Other player receives</th>
              <th>You receive</th>
              <th>Details</th>
            </tr>

          {expiredTrades.map((trade, index) => {
                        return(
                          <tr key={index}>
                            <td>{truncateEthAddress(trade.requestTo)}</td>
                            <td>{trade.itemsOffered.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsOffered[index]}<br/></p>
                              )
                            } )}</td>
                            <td>{trade.itemsRequested.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsRequested[index]}<br/></p>
                              )
                            } )}</td>
                            <td><button onClick={() => {findPlayerNick(trade.requestTo); openTradeOfferBox(index, true)}} style={{fontSize:"0.8em"}}>See trade</button></td>
                            {tradeOffers[index] &&
                              <TradeRequest equipped={equipped} timestamp={timestamp} cancel={() => cancelTrade(trade.tradeId)} offer={true} index={index} relics={relics} trade={trade} otherPlayerNick={otherPlayerNick} close={() => openTradeOfferBox(index, false)}/>
                            }
                          </tr>
                        )
                      })}
          </tbody>
        </table>}
        <p>Pending trade requests:</p>
        <table className="items-table">
          <tbody>
            <tr>
              <th>User</th>
              <th>Other player receives</th>
              <th>You Receive</th>
              <th>Details</th>
            </tr>

          {tradesTo.map((trade, index) => {
                        return(
                          !trade.fulfilled && parseFloat(trade.timeLimit) > parseFloat(timestamp) - parseFloat(trade.timeInitiated) && <tr key={index}>
                            <td>{truncateEthAddress(trade.requestMadeBy)}</td>
                            <td>{trade.itemsRequested.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsRequested[index]}<br/></p>
                              )
                            } )}</td>
                            <td>{trade.itemsOffered.map((item, index) => {
                              return (
                                <p key={index}>{relics[item].relicName} x {trade.amountsOffered[index]}<br/></p>
                              )
                            } )}</td>
                            <td><button onClick={() => {findPlayerNick(trade.requestMadeBy); openTradeRequestBox(index, true)}} style={{fontSize:"0.8em"}}>See trade</button></td>
                            {tradeRequests[index] &&
                              <TradeRequest equipped={equipped}
                              // checkEquipped={checkEquipped(trade.tradeId, trade.requestMadeBy)}
                              otherEquipped={otherPlayerEquipped} timestamp={timestamp} success={tradeSuccessful} cancel={() => cancelTrade(trade.tradeId)} acceptTrade={() => acceptTrade(trade.tradeId)} offer={false} index={index} relics={relics} trade={trade} otherPlayerNick={otherPlayerNick} close={() => openTradeRequestBox(index, false)}/>
                            }
                          </tr>
                        )
                      })}
          </tbody>
        </table>
        <p>Request to Trade with a specific player:</p>
        <input style={{width:"200px"}} placeholder="name of player to trade with" value={tradingWithName} onChange={changeNameTradingWith}></input>
        <button disabled={tradingWithName.length == 0} onClick={openTradeWithName}>Search for this users address</button>
        <br/>
        <input style={{width:"200px"}} placeholder="address of player to trade with" value={tradingWith} onChange={changeTradingWith}></input>
        <button disabled={tradingWith.length != 42 || tradingWith == account} onClick={openTradeWith}>Trade with this player</button>

        <div>
          <p className="hint-text">Transactions from trading contract are {approved ? "approved" : "not approved"}</p>
          <button onClick={() => setApproval(false)}>Remove Approval</button>
        </div>
        
        {creatingTradeWith && 
          <div className="black-screen">
            <div className="create-trade-with-box">
            <p>Your Inventory:</p>
            <div className="trade-with-items-container">
                  <select className="select-filter" name="Filter" onChange={e=> {changeBonusFilter(e.target.value, false)}}>
                    {bonusTypes.map((filter, index) => {
                      return(
                        <option key={index} value={index}>{filter}</option>
                      )
                    })}
                  </select>
                  {inventoryLoaded ?
                    relics.map((itemContent, index) => {
                      return (
                        <TradeItem amount={itemsSelected.includes(index) ? amounts[itemsSelected.indexOf(index)] : 0} inOffer={unavailableItems[index]} key={index} bonusFilter={bonusFilter} relic={itemContent} balance={balances[index]} equipped={equipped[index]} itemSelected={itemSelected} index={index} select={() => handleSelect(index)}/>
                    )
                  }) : <p>Loading</p>}
                </div>
                <div>
                <p>Offering:</p>
                  {
                    itemsSelected.map((item, index) => {
                      return(
                        <p className="item-name" key={index}>{relics[item].relicName} x {amounts[index]}</p>
                      )
                    })
                    }
                </div>
                <p>{otherPlayerNick.length > 0 ? otherPlayerNick : "anonymous"}'s inventory</p>
                <div className="trade-with-items-container">
                  <select className="select-filter" name="Filter" onChange={e=> {changeBonusFilter(e.target.value, true)}}>
                    {bonusTypes.map((filter, index) => {
                      return(
                        <option key={index} value={index}>{filter}</option>
                      )
                    })}
                  </select>
                  {inventoryLoaded ?
                    relics.map((itemContent, index) => {
                      return (
                        <TradeItem amount={itemsRequested.includes(index) ? amountsRequested[itemsRequested.indexOf(index)] : 0} inOffer={otherUnavailable[index]} key={index} bonusFilter={otherBonusFilter} relic={itemContent} balance={otherPlayerBalances[index]} equipped={otherPlayerEquipped[index]} itemSelected={itemSelected} index={index} select={() => handleSelectRequested(index)}/>
                    )
                  }) : <p>Loading</p>}
                </div>
                <div><p>Requested:</p>
                  {
                    itemsRequested.map((item, index) => {
                      return(
                        <p className="item-name" key={index}>{relics[item].relicName} x {amountsRequested[index]}</p>
                      )
                    })
                    }
                </div>
              <div className="text">
                <button onClick={reset}>Reset selection</button>
                <p className="hint-text">Note: Items equipped cannot be traded.</p>
                <span>Days until trade offer expires:</span>
                <input style={{width:"40px"}} type="number" placeholder="time" min="1" onChange={handleTimeLimit} value={timeLimit}></input>
                <br/>
                <button disabled={itemsSelected.length == 0 || itemsRequested.length == 0 || timeLimit == 0 || timeLimit == ""} onClick={createTradeWith}>Create Trade</button>
                <button onClick={() => {setCreatingTradeWith(false); setItemsSelected([]); setAmounts([])}}>Close</button>
              </div>
            </div>
          </div>
        }
        {creatingTrade &&
          <div className="black-screen">
            <div className="create-trade-box">
            <div className="trade-items-container">
                  <select className="select-filter" name="Filter" onChange={e=> {changeBonusFilter(e.target.value, false)}}>
                    {bonusTypes.map((filter, index) => {
                      return(
                        <option key={index} value={index}>{filter}</option>
                      )
                    })}
                  </select>
                  {inventoryLoaded ?
                    relics.map((itemContent, index) => {
                      return (
                        <TradeItem amount={itemsSelected.includes(index) ? amounts[itemsSelected.indexOf(index)] : 0} inOffer={unavailableItems[index]} key={index} bonusFilter={bonusFilter} relic={itemContent} balance={balances[index]} equipped={equipped[index]} itemSelected={itemSelected} index={index} select={() => handleSelect(index)}/>
                    )
                  }) : <p>Loading</p>}
                </div>
                <div><p>Offering:</p>
                  {
                    itemsSelected.map((item, index) => {
                      return(
                        <p className="item-name" key={index}>{relics[item].relicName} x {amounts[index]}</p>
                      )
                    })
                    }
                </div>
              <div className="text">
                <button onClick={reset}>Reset selection</button>
                <p className="hint-text">Note: Items equipped will not be displayed here.</p>
                <br/><br/>
                <button>Create Trade</button>
                <button onClick={() => {setCreatingTrade(false); setItemsSelected([]); setAmounts([])}}>Close</button>
              </div>
            </div>
          </div>}
        </div>}
        
    </div>
  )
}

export default Trading;