import "../About.css";

function About() {
    return (
        <div className="about-body">
            <div className="about-box">
                <div className="about-text">
                    <h2>About BeanQuest</h2>
                    <p>BeanQuest is the ultimate integration of the classic DeFi Compound Miner with Blockchain gaming!<br/>
                    <br/>Inspired by our love of classic RPGs and all things Crypto, we set about creating a fun and engaging system that aims to provide a unique experience where players can enjoy all the perks a browser RPG has to offer, while also having the possibility to gain a passive income on their deposit!<br/>
                    <br/>Build up your arsenal of BeanQuest NFTs to rise to the top of the Ladder, improving your performance and your financial return with every item collected!</p>
                    <div className="dev">
                        {/* {<img src="./devs/hash.gif"></img>} */}
                    </div>
                    <a style={{textDecoration:"none"}} href="https://medium.com/@beanquest/how-to-play-276a4963b6a7" target="_blank"><button style={{width:"150px", height:"35px", fontSize:"18px"}}className="special-button">How To Play?</button></a>
                    <h3>Follow us on different platforms to stay up to date!</h3>
                    <div className="socials">
                        <a href="https://twitter.com/beanquestrpg" target="_blank"><img src="./icons/twitter.png"></img></a>
                        <a href="https://t.me/beanquest" target="_blank"><img src="./icons/telegram.png"></img></a>
                        <a href="https://discord.gg/z4vPwUQBmd" target="_blank"><img src="./icons/discord.png"></img></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;