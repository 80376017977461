import { useEffect, useState } from "react";
import "../Warning.css";
import "../Gacha.css";

function BossWindow(props) {

    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    const beansMultiplier = 1000000000000;

    const [intro, setIntro] = useState([]);
    const [success, setSuccess] = useState([]);
    const [failure, setFailure] = useState([])

    const [readLog, setReadLog] = useState(false);

    const fightPrompts=
    [
        "A savage and brutal war chief of the fire goblins.\nKnown for torturing his enemies and lighting them on fire. He is resting near a tent with a gang of goblins around him. You’ll have the element of surprise on your side, but they have more numbers than your party. Grunts are far less effective without a war chief, so if you can kill him swiftly the others will be no problem.",
        
        "A mythical beast only heard about in legends. It is about the size of an elephant. Flames flicker from it’s mane. It’s roar is deep and loud. It hits you in your stomach and makes you feel like you want to vomit. It seems quite upset you are in it’s den. Unfortunately there’s no way to turn back, you’ll have to fight or die.",
        
        "It’s been a long time now since you’ve seen the sunlight.\nThere can’t be much left to explore in this lair.\nThe dragon must be near.\n\nThe heat is almost unbearable now.\nYou work your way through a small passageway,\ncompletely lost without any sense of direction.\n\nAll of a sudden you hear what sounds like thunder coming from up ahead.\n\nThen again, more loud booming.\n\nBOOM BOOM BOOM.\n\n"+
        "“ Where are you human?\nThis is no place for you.\nTurn back now or meet certain death.\nHA HA HA.”\n\nAs you exit the small passage way you came from,\nyou enter into a massive chamber\nwith ornate constructions of stone,\nhuge chairs, pillars and tables,\ncertainly not the work of man or elf.\nYou then see a giant humanoid\nwith a fiery crown upon his head.\nHe stands what seems like twenty feet tall.\n\n“There you are!\nYou don’t heed my warning??\nSoon you will understand the might of the fire giants!”\n\nHe throws a massive boulder in your direction\njust narrowly missing you.\n\n“ HA HA HA.\nTIME TO DIE!”",

        "The ground rumbles.\nthe sounds of heavy crashing vibrates your body\nas you walk through a smooth circular tube like tunnel,\n"+
        "no doubt bored from dragon fire.\n\nThe time is now, unfortunately there’s no turning back either.\n"+
        "To make it through the way you came,\npast an angry fire giant army and through the goblin encampments,\nwould be certain death\n\n"+
        "You’ve resolved yourself to kill Lord Nefagan or die trying.\nIt was never going to be easy, but the three kingdoms are at stake\n"+
        "They could be torched at anytime.\n\nAs you peak your head out of the tunnel opening\nyou see the dragon laying there with his eyes closed.\n\n"+
        "Suddenly you hear in your head\n\n“What are you doing here, human?”\n\n…\n\nThe voice is unmistakeable,\nit’s raspy and deep.\n"+
        "The dragon lays motionless with eyes closed.\nYou decide to think towards the dragon.\n\n“I’m here to kill you.”\n\n"+
        "…\n\n“Just you?”\n\n“Yes just me.”\n\n"+
        "“Well then, what are you waiting for?”\n\nThe dragon’s eyes snap open\nand he’s looking straight at you."
        
    ]

    const strIntro = [
        "You find a good blind spot to begin your assault on Mogoobo's gang, you are just 2 meters away from the closest grunt and you will begin by taking this one out...",
        
        "",
        
        "You begin to run towards the giant.\nYour heart is beating like crazy,\nbut if you are to have any chance to survive\nyou must act fast.\nAs you are running towards the giant,\nhe throws another boulder towards you...",
        
        "As you step out into Lord Nefagan’s chamber\nyou immediately survey the area around you.\n\nIt’s a massive chamber,\nbig enough to fit a dragon comfortably.\n"+
        "The chamber is shaped like an inside of a large egg.\nThere are boulders strewn about\nand a giant tunnel opening\non the opposite side of where you entered,\nsurely where the dragon exits and enters.\n\n"+
        "Lord Nefagan is laying on what looks like a bed of black sand,\nfinely crushed lava rock.\nYou notice all kinds of armor\nand weapons laying around the chamber,\nparticularly near where you just entered.\n\n"+
        "Lord Nefagan begins to stand up\non his four legs\nand he spreads his massive wings,\nit’s an awesome and terrifying sight.\n\nYou’ve never seen any creature this big.\n"+
        "Even the Sea Monsters of the Northern Ocean\nare not as large.\n\nJust then Nefagan looks up slightly towards\nthe ceiling and you see his throat\nbegin to glow a bright orange.\n\n"+
        "He’s attacking!\nYou rush to your right\nand hide behind a massive boulder.\nThen you hear the explosion coming towards you.\nAn immense crash hits the boulder\nand you feel heat pouring around you.\n\n"+
        "Is this rock going to melt????\nThe heat stops and you realize\nthere’s no way the rock holds up to another blast.\nYou immediately dash to the next biggest one you can find..."
    ]

    const strSuccess = [
        "The moment you see that he is distracted, you rush towards him readying your blade! Your sword pierces through him with ease as he succumbs to the floor!\nThe other three don't waste their time to rush towards you! You manage to evade their savage, uneducated strikes! By the time you are finished with them you are left face to face with the Pyromaniac War Chief!\nYou feel the heat of his flamethrower pulsating as he begins to unleash his flames in your direction! You manage to hide behind the tent as he sets it ablaze! When he finally stops to realize you were not among the scorched remains, it is too late!\n\n~ 'YEAAAARGHHHHH!'\n\nJumping from a crude metal structure you firmly bring your blade down in a deathly clean cut. The last thing he feels is the sharp edge of your sword running through his neck. The hideous creature falls to his knees desperately gasping for air as his head rolls off his lifeless body... When the rest of the camp sees what happened, they scatter away in fear and screaming.\n\n~ 'Heh, typical goblins.'",
        "",
        "You dodge it gracefully and continue your charge.\nBefore the giant can react,\nyou are right under him\nand dig your sword into his lower leg.\nHe lets out a deafening howl.\nHe tries to grab you,\nbut you roll under his legs and slash\nright above his boot on the same injured leg.\nYour sword cuts deep and the giant crashes to one knee.\n\nYou grab a hold of the giant’s clothing\nand pull yourself onto his back,\nall in one motion.\nHe tries to grab you but cannot reach you.\nWith one arm, you plunge your long sword into his back,\nyou hear the giant gasp for air\nas you puncture his lung.\nHe falls onto his chest\nas you stand on his back plunging your sword repeatedly into his back.\nThe blood is glowing red and hot.\nAfter you are sure he is dead,\nyou sit down and wipe your gear off,\nalmost in disbelief of the fight that just took place.",

        "You make sure to look at Lord Nefagan while running.\n\nImmediately you notice spots\nwhere his dragon scales are much lighter,\nor even missing altogether.\n"+
        "His spine is lined with spikes.\nHe is just massive,\nwhat were you thinking?\n\n"+
        "Maybe if you can get close\nto one of the weak spots\nyou can sink your sword into it.\n"+
        "Who knows what that will do?\n\nYou decide to start moving up the cavern\nrunning as fast as you can.\nYou shed some armor\nas it’s sure to get roasted\nfrom one blast\nand you need more speed to outrun\n"+
        "the blasts of fire in your direction.\n\nNefagan begins moving towards you,\nyou’re just about high enough to try\nand jump on top of him.\n\n"+
        "A massive claw swipes in your direction\nbut you’re just able to scamper away.\n\n"+
        "Nefagan is right on you now,\nthis is your only chance\nbefore becoming a crispy snack.\n\nYou take a massive leap on top of a boulder,\nturn around and start running towards the dragon.\n\n"+
        "Your target is a soft spot\nnear the upper chest,\nyou prepare a hand onto your blade\nas you run near the edge of the boulder.\n\n"+
        "There’s only one thing you can do now,\nyou leap off the large rock towards the dragon,\n\n"+
        "if you missed the dragon you’d fall\nand sustain certain injury,\nleaving you a sitting duck.\n\n"+
        "In mid air you raise your blade\nwith two hands above your head\nand with perfect timing plunge\nit into a small open spot\nin the dragon’s scales.\n\n"+
        "It sinks in and the dragon roars with pain and anger.\n\n"+
        "“Now you’ve made me angry!!”\n\n"+
        "You hear Nefagan’s voice\nonce again inside your head.\nHe shakes and you hold on to your blade for life,\n"+
        "swinging wildly from Nefagan’s chest.\n\nUsing the momentum of the last shake,\nyou land on the back of the dragon\nand pull your sword out\nall in the same motion.\n\n"+
        "Without taking a moment,\nyou run up his long thick neck.\nIt’s clear the scales overlap\non the back of the neck\nbut near the head they are feathered out and there are openings\ndepending on "+
        "how the dragon is posturing.\n\nYou ready your blade\nand with all your strength\nthrust it into the back of the dragon’s skull.\n\n"+
        "It lodges only a foot in,\nyou’ve struck bone.\n\nThe dragon roars again and swings his head\nbut once again you’ve gotten yourself stuck.\n"+
        "This time, as soon\nas he stops shaking for a moment,\nyou grab some spikes nearby,\njump, and then dropkick the end of your blade\nwith all your strength.\n\n"+
        "The sword sinks into the Dragon’s skull up to the hilt.\nImmediately the beast drops\nand you fall with him landing\nonto the black sand of the chamber.\n\nEverything is quiet.\n\n"+
        "You lay there and take a few deep breaths\nbefore gathering yourself up\nand pulling your sword out of Lord Nefagan’s skull.\n\n"+
        "You decide to cut a few teeth\nfrom the dragon as proof of the kill.\n\nYou see a few pieces of armor and weaponry\nlaying around that look special\nand toss them in your pack.\n\n"+
        "You begin making your way\nout of the chamber\nin the giant tunnel ahead…"
    ]

    const strFailure = [
        "The moment you see that he is distracted, you rush towards him readying your blade! Your sword pierces through him with ease as he succumbs to the floor!\nThe other three don't waste their time to rush towards you! You manage to evade their savage, uneducated strikes! By the time you are finished with them you are left face to face with the Pyromaniac War Chief!\nYou feel the heat of his flamethrower pulsating as he begins to unleash his flames in your direction! You manage to hide behind the tent as he sets it ablaze! When he finally stops to realize you were not among the scorched remains, he looks up to see you in the air!\n\n~ 'YEAAAARGHHHHH!'\n\nAs you jump from a crude metal structure with your blade ready to strike, Mogoobo rolls out of the way, narrowly avoiding the death blow!\nTrying to regain your footing the Goblin tosses a firebomb directly at you! Overwhelmed by the flames you hear him laugh as he prepares his flamethrower for the final blow...\n\n...\n\n It's out of gas! Lucky you! You use the energy you have left to run as far away as possible and tend to your burns.",
        "",
        
        "It crashes just next to you knocking you off balance just slightly.\nAs you stumble,\nthe giant takes two steps forward\nand before you have a chance to react,\nkicks you with his massive boot.\nYou’re sent flying backwards and slam onto the ground.\nYou are dazed and as you pick yourself up off the ground\nhe grabs you and holds you in the air."+
        "\nThe giant squeezes very tightly around you.\nYou feel your whole body is about to break\nas you begin to cough blood.\n\n“STUPID WORTHLESS HUMAN,\nHA HA HA.”\n\n Just when you think this will be your end,\nA Dragon's roar echoes through the cave.\nLord Nefagan...\n\nMustering every last bit of energy you have left,\nyou plunge your sword into the giant's hand\nwhile he is distracted by the mighty Dragon Lord!\n\nYou managed to hit a weak spot!\nGormax instinctively lets go as he writhes in pain\nand you are dropped to the floor\n\n'CURSE YOU, HUMAN!!!'\n\nJust as he is lifting his boot to squish you,\nyou manage to break through a weak spot on the wall,\nescaping certain death.\n\nYou will live to fight another day.",

        "The Dragon takes two large steps towards you\nand you notice you’re trapped.\n\n"+
        "If you run to your right\nthere’s a rock wall\nyou’ll not be able to get over in time,\nand if you go back to your left\nyou won’t be able to get to a big enough boulder\nfor protection,\n"+
        "if you go back into the tunnel\nwhich you came from you will be roasted for sure.\nJust then the dragon swipes at the rock\nyou’re behind and knocks it away\nand knocks you down as well.\n\n"+
        "As you roll over to pick yourself up\nyou see a blast of fire headed your way,\nbefore you have any time to react,\nthe molten dragon fire consumes your body.\nBurning you alive.\n\n"+
        "You scream in agony as your flesh\nmelts off your body and you pass out from the pain.\n\n"+
        "The dragon bends down\nand mangles your body with a single bite.\n\nHe flicks your lifeless corpse to the side\nand returns to his spot in the middle of the cavern\nand lays back down…"
    ]
    
    const dexIntro = [
        "You find a good blind spot to begin your assault on Mogoobo's gang, you are just 2 meters away from the closest grunt and you will begin by trying to take this one out...",
        "",
        
        "You gracefully hop down into the giant’s chamber\nand dash behind one of the pillars.\n\n“HA HA,\nHIDING WILL DO YOU NO GOOD, HUMAN.”\n\nYou have a few arrows already prepared\nwith poisoned tips: venom of the Golong Sands Viper,\nthe deadliest known substance in the natural world.\nNo clue whether or not it will effect him,\nyou know it won’t hurt to try.\nYou hear him stand and begin to stomp towards you...",
        
        "As you step out into Lord Nefagan’s chamber\nyou immediately survey the area around you.\n\nIt’s a massive chamber,\nbig enough to fit a dragon comfortably.\n"+
        "The chamber is shaped like an inside of a large egg.\nThere are boulders strewn about\nand a giant tunnel opening\non the opposite side of where you entered,\nsurely where the dragon exits and enters.\n\n"+
        "Lord Nefagan is laying on what looks like a bed of black sand,\nfinely crushed lava rock.\nYou notice all kinds of armor\nand weapons laying around the chamber,\nparticularly near where you just entered.\n\n"+
        "Lord Nefagan begins to stand up\non his four legs\nand he spreads his massive wings,\nit’s an awesome and terrifying sight.\n\nYou’ve never seen any creature this big.\n"+
        "Even the Sea Monsters of the Northern Ocean\nare not as large.\n\nJust then Nefagan looks up slightly towards\nthe ceiling and you see his throat\nbegin to glow a bright orange.\n\n"+
        "He’s attacking!\nYou rush to your right\nand hide behind a massive boulder.\nThen you hear the explosion coming towards you.\nAn immense crash hits the boulder\nand you feel heat pouring around you.\n\n"+
        "Is this rock going to melt????\nThe heat stops and you realize\nthere’s no way the rock holds up to another blast.\nYou immediately dash to the next biggest one you can find..."
    ]

    const dexSuccess = [
        "You rush towards the first goblin ready to strike, but he was not caught off-guard! You struggle to overpower him but eventually you find an opening and manage to pierce his side with your blade! By the time he succumbs to the floor, the other three have already surrounded you! As you clumsily dodge out of the way of the first one's blow, you hear a loud explosion! The grunts are engulfed in flames after accidentally striking the firebomb stash!\nThe recently awoken Mogoobo does NOT look happy. You dodge his first firebomb and run towards him as he throws another right in your direction! You manage to evade it, but he does not let up, as he readies his flamethrower to fire directly at you!",
        "",
        
        "You throw a flash bomb out\nand take a deep breath.\nThe bomb explodes with a pop and a flash,\nand swiftly you turn the corner and shoot an arrow.\nIt hits him directly in the neck.\n\nHe snaps the arrow from his body and laughs.\n\n“YOU THINK THAT TOOTHPICK WILL DO ANYTHING TO ME?”\n\nYou dart to the next pillar,\nas you wait for the venom to take effect.\nHe takes two booming steps towards you\nand then you hear him begin to cough.\n\n“It’s working!”\nyou think to yourself.\n\nYou hear him then fall to one knee.\nNow’s your chance.\n\nYou prepare some Manticore’s Bane poison\nonto your dagger and rush out after him.\nHe looks up to see you but it’s too late.\n\nYou drive your dagger multiple times\ninto his leg and he howls in pain.\nAs he falls over,\nyou dodge his flailing arms\nand stab him multiple times in the neck.\n\nAs the glowing red blood pours from his neck,\nyou watch his eyes go lifeless.",

        "You look into your pack\nand pull out a dark glowing bottle.\n\nThe liquid inside is black and purple and steaming,\nit’s radiating with a strange energy.\n\n"+
        "Liquid death.\nA special poison which needs a master poison maker\nand a high necromancer to work together.\n"+
        "Only a few people can even\nmake it in the world.\nAnd many have killed themselves\naccidentally getting some on their skin\nor in their eye.\n\n"+
        "You’re sure this will take down Nefagan\nif you can get it inside him somehow.\nHowever, you’re a little afraid of what he might become.\n"+
        "Sometimes the poison turns\nthe living into an undead abomination,\nyou never know how the necromancer\nfelt on the day it was made.\n\n"+
        "You slip the bottle onto your belt\nand gather a few flash bangs,\nsmoke bombs, and other devices\nto slip on there for fast access.\n\n"+
        "You throw two flash bangs\nand a smoke bomb to distract the dragon.\nLord Nefagan looks back and forth,\nyou can tell he’s lost you in the confusion.\n\n"+
        "Before he sees you, you sneak towards him\nand end up right under his belly.\nNow the climb.\nYou’ve climbed the castle wall in Elor\nfor an assassination,\nwhat’s so hard about climbing a dragon?\n\n"+
        "You grab on to a few scales\nand it’s clear he still doesn’t\nknow where you are.\nSlowly you make your way up the dragon,\nonto his hind region.\n\n“Where are you, you little bug?”\n\n"+
        "You hear Nefagan’s voice\nin your head once again.\nYou slowly make your way up the dragon’s back,\nnot sure when he’ll notice you.\nOnce you grab on to his neck\nyou hear him ROAR.\n"+
        "Welp, there goes the element of surprise.\nYou’ll have to move fast now.\n\n"+
        "The dragon swings his head around wildly,\nas you hold on while making your way up his neck.\nYou’re close enough to make your move now\nand you vault yourself towards\nthe head of the dragon.\nYou grab onto a spike above his eye\nand look deep into it.\n\n"+
        "While holding on with one hand,\nyou use the other hand\nto grab the jar of liquid death.\n\n"+
        "“You must be thirsty, HAVE A DRINK!”\n\n"+
        "You throw the bottle\ninto the open mouth of the dragon\nas it’s trying to bite you.\nYou let go of him and drop to the floor,\nlanding with a swift roll.\n\n"+
        "You see the dragon’s throat\nbegin to glow orange as he pulls\nhis head back ready to turn you into ash.\n\n"+
        "Just then you see the color leave his neck.\nHe makes a strange noise,\nlike a roar but clearly this time\nit was filled with anguish rather than anger.\n\n"+
        "A thick black liquid begins\npouring out of the dragon’s mouth.\nIt takes one more step\nand collapses in front of you,\nnearly crushing you.\n"+
        "You jump and thrust your fist into the air!\n\nBefore leaving the chamber\nyou spend a while rummaging around\nthe armor and weaponry of the previous challengers.\n\n"+
        "You find quite a few pieces\nthat will fetch wild prizes at the bazaar,\n"+
        "on top of your bounty\nyou still get to claim.\nNo more sneaking into castles and barracks for you!\n\n"+
        "The dragon’s color is changing\nfrom red to black as it lays there\nlifeless in the chamber.\nAs you climb out and into the exit tunnel\nyou see the sunlight shining from above.\n"+
        "The crisp air hits your nostrils\nand it feels like forever\nsince you’ve been able to take a deep breath.\n\n"+
        "You’ll have to climb down the volcano\nbut there doesn’t seem to be\nany goblin encampments anywhere.\nJust then,\nyou hear an incredible roar\n"+
        "from behind you coming from the cavern…\n\nUh oh!\nWell...\n\n"+
        "the bounty was for killing Lord Nefagan only,\nIt never said anything about turning him into a zombie!"
    
    ]

    const dexFailure = [
        "",
        "",
        "As you are going through your equipment,\nyou fail to notice the big boulder the giant threw in your direction!\nYou avoid it the best you can, but it crashes just next to you,\nknocking you off balance just slightly.\n\nAs you stumble,\nthe giant takes two steps forward\nand before you have a chance to react,\nkicks you with his massive boot.\nYou’re sent flying backwards and slam onto the ground.\nYou are dazed and as you pick yourself up off the ground\nhe grabs you and holds you in the air."+
        "\nThe giant squeezes very tightly around you.\nYou feel your whole body is about to break\nas you begin to cough blood.\n\n“STUPID WORTHLESS HUMAN,\nHA HA HA.”\n\n Just when you think this will be your end,\nA Dragon's roar echoes through the cave.\nLord Nefagan...\n\nMustering every last bit of energy you have left,\nyou throw an instant flash bomb right into the giant's eyes\nwhile he is distracted by the mighty Dragon Lord!\n\nGormax instinctively lets go as he writhes in pain\nand you are dropped to the floor\n\n'MY EYES! MY EYES!!'\n\nDazed by the blinding light,\nhe is stomping madly through the volcano!\nJust as his big boot is about to squish you,\nyou manage to slip by a small opening on the rocks,\nescaping certain death.\n\nYou will live to fight another day.",

        "The Dragon takes two large steps towards you\nand you notice you’re trapped.\n\n"+
        "If you run to your right\nthere’s a rock wall\nyou’ll not be able to get over in time,\nand if you go back to your left\nyou won’t be able to get to a big enough boulder\nfor protection,\n"+
        "if you go back into the tunnel\nwhich you came from you will be roasted for sure.\nJust then the dragon swipes at the rock\nyou’re behind and knocks it away\nand knocks you down as well.\n\n"+
        "As you roll over to pick yourself up\nyou see a blast of fire headed your way,\nbefore you have any time to react,\nthe molten dragon fire consumes your body.\nBurning you alive.\n\n"+
        "You scream in agony as your flesh\nmelts off your body and you pass out from the pain.\n\n"+
        "The dragon bends down\nand mangles your body with a single bite.\n\nHe flicks your lifeless corpse to the side\nand returns to his spot in the middle of the cavern\nand lays back down…"
    ]

    
    const intIntro = [
        "",
        "",
        "You hop down into the giant's chamber\nand begin casting a spell\nby reciting an incantation and moving your arms about.\nA small snow squall begins to form around you.\nYour eyes turn icy blue\nand the air gets remarkably cold.\nNoticing this the giant stands up and begins taking steps toward you...",

        "As you step out into Lord Nefagan’s chamber\nyou immediately survey the area around you.\n\nIt’s a massive chamber,\nbig enough to fit a dragon comfortably.\n"+
        "The chamber is shaped like an inside of a large egg.\nThere are boulders strewn about\nand a giant tunnel opening\non the opposite side of where you entered,\nsurely where the dragon exits and enters.\n\n"+
        "Lord Nefagan is laying on what looks like a bed of black sand,\nfinely crushed lava rock.\nYou notice all kinds of armor\nand weapons laying around the chamber,\nparticularly near where you just entered.\n\n"+
        "Lord Nefagan begins to stand up\non his four legs\nand he spreads his massive wings,\nit’s an awesome and terrifying sight.\n\nYou’ve never seen any creature this big.\n"+
        "Even the Sea Monsters of the Northern Ocean\nare not as large.\n\nJust then Nefagan looks up slightly towards\nthe ceiling and you see his throat\nbegin to glow a bright orange.\n\n"+
        "He’s attacking!\nYou rush to your right\nand hide behind a massive boulder.\nThen you hear the explosion coming towards you.\nAn immense crash hits the boulder\nand you feel heat pouring around you.\n\n"+
        "Is this rock going to melt????\nThe heat stops and you realize\nthere’s no way the rock holds up to another blast.\nYou immediately dash to the next biggest one you can find...",
    ]

    const intSuccess = [
        "",
        "",
        "You throw your hands in front of you\nand an icy blast shoots out\nand coats the ground of the chamber\nin a thick sheet of ice.\n\nThe giant slips as he is running towards you and falls hard on his back.\nHe tries to get up and once again slips and falls down.\n\nYou raise your attention to the ceiling\nand begin speaking to yourself another spell.\nThe entire chamber is almost like being in the middle of a blizzard now.\nLarge, thick, and sharp icicles begin to form from the ceiling.\nYou raise your arms and dozens of icicles\nsuddenly crash down towards the giant\nas he is struggling to stand.\nOne pierces him through the neck\nand you hear him scream with a gurgling noise\nas he spits up glowing red blood.\nAs the blood pours from his neck and mouth\nit begins to steam when touching the icy floor.\nYour eyes and the atmosphere of the chamber\nreturn to normal.\n\nGormax's corpse lays motionless in front of you.",

        "You quickly summon a waterfall in front of you,\npouring from the air,\nthe fire hits the large gush of water\nand immediately fills the chamber with steam.\n\n"+
        "You’ll have to use what you have\navailable to you\nwith your magic and elements.\nThe stones could be morphed,\nice could be conjured\nbut the chamber is so hot,\n"+
        "you’re not sure any ice magic\nwill be effective here.\nPerhaps the heat can be used to your advantage as well.\n\n"+
        "You blink away to another side\nand conjure a ball of pure magic energy\nand blast it towards the dragon.\nIt hits him near the front shoulder area\nbut splashes off his scales.\n\n"+
        "He turns and breathes more fire towards you.\nOnce again you use the moisture in the air\nto conjure another stream of water.\nWhen the fire and water hit,\neven more steam is created,\n"+
        "the entire chamber is filled with it now.\nIt’s tough for you to even see the dragon,\nlet alone him see you.\nPerhaps this is your opportunity.\n\n"+
        "You blink towards the floor and teleport again.\nLord Nefagan doesn’t notice\nand you have a little time to work some magic.\n"+
        "You begin conjuring an earth spell\nand slam your staff into the ground.\n\n"+
        "Lord Nefagan is still shooting fire\nand swiping the air at your previous location.\nThe ground begins to shift and move.\nThe boulders roll, shift, move and change shape.\n\n"+
        "You’re changing the ground and earth\ninside the chamber without him noticing.\nIf this doesn’t work,\nyou have no idea what you’ll do.\n\n"+
        "The trap has been set,\nnow let’s see if he’ll take the bait.\n\n"+
        "It’s time for ice again.\nYour eyes turn blue\nand you lay a thick sheet of ice in front of you.\n"+
        "If he shoots fire in your direction,\nthe whole plan will be ruined.\n\n"+
        "“Over here you stupid Lizard!”\n\n"+
        "The visibility is extremely low\nwith the steam and smoke\nthat has filled the chamber.\n\n"+
        "The dragon begins to run towards you,\nafter taking three giant steps in your direction,\nit’s claw hits the ice sheet.\nIt slips and is unable to recover,\n"+
        "it’s huge body and momentum\nsend him uncontrollably in your direction.\n\n"+
        "The dragon’s next claw hits the ice\nand he slips even more,\nhe is going to fall.\n\n"+
        "Lord Nefagan tumbles down\nand crashes hard\ninto the floor of the chamber.\n"+
        "It roars in anguish and pain,\nbut the roars begin to get softer and softer\nand he seems to be stuck.\n\n"+
        "He lays his head down\nand the entire chamber goes quiet.\nYou mumble a few words\nand conjure a wind storm\nto blow the steam and smoke\nfrom the top exit tunnel of the chamber.\n\n"+
        "As the scene clears\nyou see the dragon laying dead\non the ground with a massive obsidian spike piercing\nthrough his chest.\n\n"+
        "When he slipped he fell right onto your trap.\nThe obsidian rock made the perfect weapon,\nsharp and extremely strong.\nThe dragon never saw it coming,\n"+
        "otherwise he would have never gotten that close\nto the obelisk like spike sticking from the ground.\n\n"+ 
        "You collect a few teeth\nfrom the dragon and some scales.\nYou find a few artifacts in the chamber\nyou wouldn’t dare leave behind,\n"+
        "you gather them and pull a bottle\nfilled with a glowing blue liquid from your pack.\nYou begin mumbling an evocation\nand in a moment you disappear\nin a cloud of smoke,\n"+
        "leaving the chamber empty\nwith the dragon’s corpse left behind..."
    ]

    const intFailure = [
        "",
        "",
        "His thunderous steps begin to shake you off your footing.\nHe gets faster as you try to regain your balance, but it's no use!\nAs you stumble,\nthe giant takes two steps forward\nand before you have a chance to react,\nhe kicks you with his massive boot.\nYou’re sent flying backwards and slam onto the ground.\nYou are dazed and as you pick yourself up off the ground\nhe grabs you and holds you in the air."+
        "\nThe giant squeezes very tightly around you.\nYou feel your whole body is about to break\nas you begin to cough blood.\n\n“STUPID WORTHLESS HUMAN,\nHA HA HA.”\n\n Just when you think this will be your end,\nA Dragon's roar echoes through the cave.\nLord Nefagan...\n\nMustering every last bit of energy you have left,\nyou flash your staff right in the giant's eyes,\ncasting a blinding spell\nwhile he is distracted by the mighty Dragon Lord!\n\nGormax instinctively lets go as he writhes in pain\nand you are dropped to the floor\n\n'MY EYES! MY EYES!!'\n\nDazed by the blinding light,\nhe is stomping madly through the volcano!\nAs his giant boot is about to squish you,\nyou are just finishing casting your Escape spell.\nHis boot cracks through the rocks\nas you narrowly avoid certain death.\n\nYou will live to fight another day.",

        "The Dragon takes two large steps towards you\nand you notice you’re trapped.\n\n"+
        "If you run to your right\nthere’s a rock wall\nyou’ll not be able to get over in time,\nand if you go back to your left\nyou won’t be able to get to a big enough boulder\nfor protection,\n"+
        "if you go back into the tunnel\nwhich you came from you will be roasted for sure.\nJust then the dragon swipes at the rock\nyou’re behind and knocks it away\nand knocks you down as well.\n\n"+
        "As you roll over to pick yourself up\nyou see a blast of fire headed your way,\nbefore you have any time to react,\nthe molten dragon fire consumes your body.\nBurning you alive.\n\n"+
        "You scream in agony as your flesh\nmelts off your body and you pass out from the pain.\n\n"+
        "The dragon bends down\nand mangles your body with a single bite.\n\nHe flicks your lifeless corpse to the side\nand returns to his spot in the middle of the cavern\nand lays back down…"
    ]

    function returnHours() {
        return Math.floor((props.recoveryTime - (props.timestamp - props.checkpoint) - props.equipped.multipliers[18])/3600)
    }

    function returnMinutes() {
        return Math.floor(((props.recoveryTime - (props.timestamp - props.checkpoint) - props.equipped.multipliers[18])/60)%60)
    }

    function determineFight() {
        var a = 9;
        if(props.stats[0] > props.stats[1] && props.stats[0] > props.stats[2]) {
            setIntro(strIntro);
            setSuccess(strSuccess);
            setFailure(strFailure);
            a = 0
        }
        if(props.stats[1] > props.stats[0] && props.stats[1] > props.stats[2]) {
            setIntro(dexIntro);
            setSuccess(dexSuccess);
            setFailure(dexFailure);
            a = 1;
        }
        if(props.stats[2] > props.stats[1] && props.stats[2] > props.stats[0]) {
            setIntro(intIntro);
            setSuccess(intSuccess);
            setFailure(intFailure);
            a = 2;
        }
        console.log(failure);
    }
    
    const attribute = props.attribute;
    const cBonus = attribute + 1;
    const sBonus = attribute + 4;


    const cBonusActivated = props.equipped.bonuses[attribute + 1];
    const sBonusActivated = props.equipped.bonuses[attribute + 4];

    function calculateFullPayoutReinvest() {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)) * 1.05);
        _payout = _payout * props.bossInfo.rewardMultiplier / 100;
        if(cBonusActivated) {
            _payout = _payout * props.equipped.multipliers[cBonus] / 100;
        }
        return _payout
    }

    function calculateFullPayoutStat() {
        var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)) * 1.05);
        _payout = _payout * props.bossInfo.rewardMultiplier / 100;
        if(sBonusActivated) {
            _payout = _payout * props.equipped.multipliers[sBonus] / 100;
        }
        return _payout
    }

    function calculateWinChancesWithoutWeakPoint() {
        var chance = 0;
        if(parseInt(props.stats[3]) >= parseInt(props.bossStats[3])) {
            chance = 70 + (props.stats[3] / statsMultiplier - props.bossStats[3] / statsMultiplier) 
        }
        else {
            chance = (props.stats[3] / statsMultiplier) * 100 / (props.bossStats[3] / statsMultiplier) / 3;
        }
        if(parseInt(props.stats[3]) > 250 * statsMultiplier && chance > 80) {
            chance = 80;
        }
        if(chance > 95) {
            chance = 95
        }
        if(parseInt(props.bossStats[3]) / 4 > parseInt(props.stats[3])) {
            chance = 1;
        }
        return chance;
    }

    function calculateWinChancesNoCap() {
        var chance = 0;
        if(parseInt(props.stats[3]) >= parseInt(props.bossStats[3])) {
            chance = 70 + (props.stats[3] / statsMultiplier - props.bossStats[3] / statsMultiplier) 
        }
        else {
            chance = (props.stats[3] / statsMultiplier) * 100 / (props.bossStats[3] / statsMultiplier) / 3;
        }
        chance = chance + props.equipped.multipliers[7];
        if(parseInt(props.bossStats[3]) / 4 > parseInt(props.stats[3])) {
            chance = 1;
        }
        if(parseInt(props.stats[props.bossStats[5]]) > parseInt(props.bossStats[props.bossStats[5]]) ) {
            chance += 10;
        }
        return chance;
    }

    function calculateWinChances() {
        var chance = 0;
        if(parseInt(props.stats[3]) >= parseInt(props.bossStats[3])) {
            chance = 70 + (props.stats[3] / statsMultiplier - props.bossStats[3] / statsMultiplier) 
        }
        else {
            chance = (props.stats[3] / statsMultiplier) * 100 / (props.bossStats[3] / statsMultiplier) / 3;
        }
        chance = chance + parseFloat(props.equipped.multipliers[7]);
        if(parseInt(props.bossStats[3]) / 4 > parseInt(props.stats[3])) {
            chance = 1;
        }
        if(parseInt(props.stats[props.bossStats[5]]) > parseInt(props.bossStats[props.bossStats[5]]) ) {
            chance += 10;
        }
        if(parseInt(props.stats[3]) > 250 * statsMultiplier && chance > 80) {
            chance = 80;
        }
        if(chance > 95) {
            chance = 95
        }
        return chance;

    }

    function checkWeakpoint() {
        if(parseInt(props.stats[props.bossStats[5]]) > parseInt(props.bossStats[props.bossStats[5]]) ) {
            return true;
        }
        return false
    }

    function bonusCalculator(num) {
        var bonus = num == 0 ? props.equipped.multipliers[cBonus] % 10000 : props.equipped.multipliers[sBonus] % 10000;
        return bonus;
    }


    useEffect(() => {
        determineFight();
        console.log((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus) )* 1.05);
        console.log(props.equipped.bonuses);
        console.log(cBonus);
        console.log(success[props.currentBoss]);
    },[])

    return(
        <div className="black-screen">
            <div className="boss-warn-box">
            {props.bossDefeated && !props.bossFightFinished ? 
            <div className="boss-text">
            
                <h2>GREAT ENEMY FELLED</h2>
                <img className="boss-img" src={"./bosses/"+props.bossInfo.name+" Dead.jpg"}></img>
                {readLog ?
                <div className="boss-text">
                <pre>{intro[props.currentBoss]}</pre>
                <pre>{success[props.currentBoss]}</pre>
                <button onClick={() => setReadLog(false)}>Close Fight Log</button>
                </div> :
                <button onClick={() => setReadLog(true)}>Read Fight Log</button>}
                <p>~ {props.bossInfo.name} lies before you lifeless.</p>
                <h3>You were victorious.</h3>
                <p>~ You received: {props.relics[props.bossInfo.nftDrop].relicName}.</p>
                <button onClick={props.disableWindow}>Close</button>
            </div>
            :
            <div>
            {props.timestamp - props.checkpoint > props.recoveryTime - parseFloat(props.equipped.multipliers[18]) && !props.fightUnderway && !props.bossFightFinished &&
                <div className="text">
                    <h2>BOSS FIGHT!</h2>
                    <div className="boss-text">
                        <pre>~ {fightPrompts[props.currentBoss]}</pre>
                        <p>Now you must face...</p>
                        <img className="boss-img" src={"./bosses/" + props.bossInfo.name + " Fight.jpg"}></img>
                        <h2>{props.bossInfo.name}!</h2>
                    </div>
                    {/* <p>{props.nickname} vs. Boss</p> */}
                    <div className="chanceCalc">
                        <p>Your chances of winning are:
                        <br/>
                        <span>{Math.floor(calculateWinChances())}%</span><br/>
                        <span className="hint-text">{calculateWinChances() > calculateWinChancesWithoutWeakPoint() && <span>{Math.floor(calculateWinChancesWithoutWeakPoint())}%</span>}{props.equipped.bonuses[7] && <span>{" +"} {props.equipped.multipliers[7]}% ({props.equipped.equippedRelics[7].relicName})<br/></span>}{checkWeakpoint() && " + 10% (Weak Point Bonus)"}<br/>
                        {calculateWinChancesNoCap() > 80 && props.bossStats[3] >= 250 * statsMultiplier && "Capped at 80%"}
                        {calculateWinChancesNoCap() > 95 && props.bossStats[3] < 250 * statsMultiplier && "Capped at 95%"}</span>
                        </p>
                        {props.stats[3] < props.bossStats[3] &&
                        <p className="hint-text">Hint:<br/>Improve your stats or use specific equipment for a better chance at defeating bosses.</p>}
                        {props.stats[3] < props.bossStats[3] / 4 &&
                        <p className="">Increase your level to {props.bossStats[3]/statsMultiplier} for a better chance at winning</p>} 
                    </div>
                    <p>Total Compound if you win: {(calculateFullPayoutReinvest() / multiplier).toFixed(5)} BNB</p>
                    {props.equipped.bonuses[cBonus] &&
                    <p className="hint-text">+{bonusCalculator(0) - 100}% Bonus provided by {props.equipped.equippedRelics[cBonus].relicName} {props.equipped.equippedRelics[cBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    {props.attribute == 0 && <p>STR exp earned: {(calculateFullPayoutStat() / expMultiplier).toFixed()}</p>}
                    {props.attribute == 1 && <p>DEX exp earned: {(calculateFullPayoutStat() / expMultiplier).toFixed()} exp</p>}
                    {props.attribute == 2 && <p>INT exp earned: {(calculateFullPayoutStat() / expMultiplier).toFixed()} exp</p>}
                    {props.equipped.bonuses[sBonus] &&
                    <p className="hint-text">+{bonusCalculator(1) - 100}% Bonus provided by {props.equipped.equippedRelics[sBonus].relicName} {props.equipped.equippedRelics[sBonus].consumable && <span>{"("}consumable{")"}</span>}</p>}
                    <button onClick={props.fightBoss}>Fight!</button><br/>
                        <p className="hint-text">Warning:<br/>Losing the fight will result in the loss of {(((props.payout * props.equipped.multipliers[8] / 100 - props.payout) * -1)/ expMultiplier).toFixed()} beans.<br/>
                        {props.equipped.bonuses[8] && <span className="hint-text">{((props.payout * props.equipped.multipliers[8] / 100) / expMultiplier).toFixed()} Beans {"(" + props.equipped.multipliers[8] + "%)"} saved due to {props.equipped.equippedRelics[8].relicName}{props.equipped.equippedRelics[8].consumable && " (consumable)"}</span>}
                        </p>
                    <button onClick={props.disableWindow}>Run away!</button>
                </div>}
                {props.timestamp - props.checkpoint <= props.recoveryTime - parseFloat(props.equipped.multipliers[18]) && !props.bossFightFinished && !props.bossDefeated && 
                <div className="text">
                    <h3>Recovery!</h3>
                    <p>You need to rest before attempting to fight a boss!</p>
                    <p>Time until you can attempt to fight:
                    <br/>
                    <p>{returnHours()} hours and {returnMinutes()} minutes</p>
                    {props.equipped.bonuses[18] && <p className="hint-text">-{(props.equipped.equippedRelics[18].multiplier / 3600).toFixed()} Hours: Bonus provided by {props.equipped.equippedRelics[18].relicName} {props.equipped.equippedRelics[18].consumable && "(consumable)"}</p>}
                    <p className="hint-text">Hint:<br/>Equip an item that speeds up your recovery to be able to challenge bosses quicker.</p>
                    </p>
                    <button onClick={props.disableWindow}>Close</button>
                </div>}
                {props.bossFightFinished && props.bossDefeated &&
                <div className="text">
                    <div className="boss-text">
                        <pre>{intro[props.currentBoss]}</pre>
                        <pre>{success[props.currentBoss]}</pre>
                        <img className="boss-img" src={"./bosses/"+props.bossInfo.name+" Dead.jpg"}></img>
                        <h2>GREAT ENEMY FELLED</h2>
                        <h3>You are victorious.</h3>
                        <p>~ You receive: {props.relics[props.bossInfo.nftDrop].relicName} x {props.bossInfo.amountDropped}.</p>
                    </div>
                    <button onClick={props.disableWindow}>Close</button>
                </div>}
                {props.bossFightFinished && !props.bossDefeated && !props.fightUnderway &&
                <div className="boss-text">
                    <pre>{intro[props.currentBoss]}</pre>
                    <pre>{failure[props.currentBoss]}</pre>
                    <img className="quest-img" src={"./bosses/"+props.bossInfo.name+" Winner.jpg"}></img>

                    <h2>YOU LOST</h2>
                    <p>You get nothing.</p>
                    <p>You lost all your beans.</p>
                    <p>Your whole body hurts.</p>
                    <button onClick={props.disableWindow}>Wake up</button>
                </div>}
                {props.fightUnderway && 
                <div className="boss-text">
                <p>You challenge {props.bossInfo.name}!</p>
                <pre>{intro[props.currentBoss]}</pre>
                <p>Loading your results...<br/>
                <span className="hint-text">Note: this should take roughly 25 seconds.</span></p>
                </div>
                }
            </div>
            }
            {props.bossError &&
            <div className="boss-text">
                <p>Something went wrong.</p>
                <p>Click below to try to get the results again.</p>
                <button onClick={() => {props.getBossResults()}}>Reload</button>
                <p className="hint-text">If you have been trying for over 60 seconds and your beans did not disappear, try reloading and fighting the boss again.</p>
                <button onClick={props.disableWindow}>Close</button>
            </div>}
            
                <div className="gacha-footer">
                    <p>Powered by</p> 
                    <a target="_blank" href="https://kenshi.io">
                    <img src="kenshi-logo.svg"></img>
                    </a>
                </div>  
            </div>
        </div>
    )
}

export default BossWindow