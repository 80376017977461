import { useEffect, useState } from "react";
import "../Splash.css";
import "../SplashButton.css";

function Splash(props) {

    const [toDeposit, setToDeposit] = useState(1);
    const [daysPassed, setDaysPassed] = useState(1);
    const multiplier = 1000000000000000000
    
    function calculateTotalReturn() {
        if(daysPassed > 0) {
            return ((toDeposit * 1.03 ** daysPassed)*10000).toFixed(0);
        }  
    }

    function calculateDailyReturn() {
        if(daysPassed > 0) {
            var base = parseFloat(toDeposit);
            var amount;
            var i = 0;
            for(i; i < daysPassed; i++) {
                amount = base * 0.03;
                base += amount;
            }
            return (amount*10000).toFixed(0)   
        }
    }

    function changeDaysPassed(event) {
        setDaysPassed(event.target.value);
    }

    function changeDeposit(event) {
        setToDeposit(event.target.value); 
    }

    useEffect(() => {props.getMinerInfo()}, [])
    
    return(
            <div className="center">
                <img id="splash" src="./splash/splash.jpg"></img>
                <div className="socials">
                        <a href="https://twitter.com/beanquestrpg" target="_blank"><img src="./icons/twitter.png"></img></a>
                        <a href="https://t.me/beanquestrpg" target="_blank"><img src="./icons/telegram.png"></img></a>
                        <a href="https://discord.gg/z4vPwUQBmd" target="_blank"><img src="./icons/discord.png"></img></a>
                </div>
                <div className="center-text">
                    <h1>- Welcome to BeanQuest -</h1>
                    <h3>The First Compound Miner RPG on the BlockChain!</h3>
                    <div className="wrap">
                        <button className="button" id="splashButton" onClick={props.setStart}>Start Playing</button>
                    </div>
                    <p>Unfortunately it is no longer possible to earn a financial return<br/>on any further investments into BeanQuest,<br/>but all the game systems are fully operational and being developed on.</p>
                    <p>Stay tuned for further updates!</p>
                    <div className="miner-stats">
                        {/* <div>
                        <h3>TVL</h3>
                        <h1>{props.TVL > 0 ? (props.TVL/10**18).toFixed(2) : "~10"} BNB</h1>
                        </div> */}
                        <div>
                        <h3>PLAYERS</h3>
                        <h1>{props.totalUsers > 0 ? props.totalUsers : 73}</h1>
                        </div>
                        {/* <div>
                        <h3>FEES</h3>
                        <h1>6%</h1>
                        </div>
                        <div>
                        <h3>DAILY %</h3>
                        <h1>3%</h1>
                        </div> */}
                    </div>
                    <h1>- What is BeanQuest? -</h1>
                    <hr/>
                    <div className="info-header">
                        <h3>Combining the best of Compound Miners with an innovative Blockchain RPG system, we made BeanQuest into the most engaging, yet simple, Miner experience to date!</h3>
                    </div>
                    <div className="info-divide">
                        <div>
                        <img src="./howto/deposit-lq.gif"></img>
                        </div>
                        <hr/>
                        <div>
                        <h3>START YOUR ADVENTURE!</h3>
                        <p>To start your adventure, you will need to make an initial deposit, this is the origin of your 3% Daily Return and most importantly, what will define your initial stats.</p>
                        </div>
                    </div>
                    <div>
                        <h2>Try our Experience Calculator!</h2>
                        <span>With a deposit of </span><input style={{width:"70px"}} value={toDeposit} type="number" step="0.1" min="0.1" placeholder="Amount" onChange={changeDeposit}></input><span>BNB</span><br/>
                        <span>After </span><input style={{width:"50px"}} value={daysPassed} type="number" min="0" max="365" placeholder="after x days" onChange={changeDaysPassed}></input><span>Days<br/>levelling up every day:</span>
                        <p>Your daily experience earned will be: {calculateDailyReturn()} EXP p/ day</p>
                        <p>You will have: {calculateTotalReturn()} EXP compounded</p>
                    </div>
                    <div className="info-divide">
                        <div>
                            <h3>LEVEL UP AND TAKE RISKS!</h3>
                            <p>If you want to get stronger, you will need to keep playing! Use your gains to Reinvest by Levelling Up or Fight the weekly Bosses for a chance at BIG rewards!</p>
                        </div>
                        <hr/>
                        <div>
                            <img src="./howto/fight-boss.gif"></img>
                        </div>
                    </div>
                    <div className="info-divide">
                        <div>
                            <img src="./howto/nft.gif"></img>
                        </div>
                        <hr/>
                        <div>
                            <h3>COLLECT UNIQUE NFT's!</h3>
                            <p>Visit the Merchant to collect Equipment that will improve your stats! The Kenshi VRF guarantees fair and secure randomized results on each Treasure Chest!</p>
                        </div>
                    </div>
                    <h2>All this and much more that we have planned!</h2>
                    <div className="wrap">
                        <button className="button" id="splashButton" onClick={props.setStart}>Start Playing</button>
                    </div>
                    <h3>Follow us on different platforms to stay up to date!</h3>
                    <div className="socials">
                        <a href="https://twitter.com/beanquestrpg" target="_blank"><img src="./icons/twitter.png"></img></a>
                        <a href="https://t.me/beanquestrpg" target="_blank"><img src="./icons/telegram.png"></img></a>
                        <a href="https://discord.gg/z4vPwUQBmd" target="_blank"><img src="./icons/discord.png"></img></a>
                    </div>
                    <div className="info-footer"><h3>Contract Address:<br/><a target="_blank" href="https://bscscan.com/address/0x9f5fcfeaaf8aa60cb4239d53507429b8cc86b015#code">0x9f5fcfeaaf8aa60cb4239d53507429b8cc86b015</a></h3>
                    <p style={{padding:"75px 20px"}} className="hint-text">DISCLAIMER: <br/> BeanQuest does not guarantee any profit or return on investment from funds deposited by any player.
                    <br/>All funds deposited enter a verified smart contract on the Binance Smart Chain.
                    <br/>The BeanQuest developers do not have access to any of the funds deposited.
                    <br/>We implore you to do your own research before depositing any cryptocurrency into the BeanQuest game.
                    <br/>All funds deposited into BeanQuest are considered the price of access to experience and play BeanQuest.
                    <br/>Because developers do not have direct access to the deposited funds, it is not possible to refund for any deposits.
                    <br/>BeanQuest is not a financial institution and no information coming from BeanQuest should ever be considered as financial advice.
                    <br/>Play at your own risk and most of all have fun!</p>

</div>
                </div>
            </div>
    )
}

export default Splash