import { useEffect } from "react";
import "../Warning.css"

function Warning(props) {
    
    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    const beansMultiplier = 100000000000000;

    function checkTime() {
        if(props.streak + (props.timeElapsed) > 432000 - props.equipped.multipliers[9]) {
            return true;
        }
        else {
            return false;
        }
    }

    function calculateFullPayoutReinvest() {
            var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)));
            return _payout
        }
    

    useEffect(() => {console.log(props.equipped.equippedRelics);}, [])

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    {!checkTime() &&
                    <div>
                        <p>⚠️WARNING⚠️<br/>Withdrawing without compounding for 5 days will incur in a 90% penalty to amount withdrawn.</p>
                        <p>Time until penalty is removed: {(props.timestamp - props.checkpoint) < 172800 ? ((432000 - (props.streak + (props.timestamp - props.checkpoint)) - props.equipped.multipliers[9]) /3600).toFixed() : ((432000 - (props.streak + 172800) - props.equipped.multipliers[9])/3600).toFixed()} Hours {props.timeElapsed >= 172800 && "(frozen)"} </p>
                        {props.timestamp - props.checkpoint >= 172800 && <p className="hint-text">⚠️48 HOURS have passed!⚠️<br/>Keep compounding, otherwise the penalty will not be removed!</p>}
                        {props.equipped.bonuses[9] && <p className="hint-text">-{(props.equipped.equippedRelics[9].multiplier / 3600).toFixed()} Hours: Bonus provided by {props.equipped.equippedRelics[9].relicName} {props.equipped.equippedRelics[9].consumable && <span>{"("}consumable{")"}</span>}</p>}
                        <p className="hint-text">Hint:<br/>Equip an item that grants you a haste bonus to withdraw quicker with no penalty.</p>
                    </div>}
                    <p>Sell your {(props.payout / beansMultiplier).toFixed()} BEANS for:<br/>{checkTime() ? (calculateFullPayoutReinvest()/multiplier).toFixed(5) : ((calculateFullPayoutReinvest()/multiplier)*0.1).toFixed(5)} BNB?</p>
                    {!checkTime() && <p className="hint-text">Hint:<br/>Keep compounding for 5 days until the penalty is removed for a higher payout.<br/>
                    Value without penalty:<br/>{(calculateFullPayoutReinvest()/multiplier).toFixed(5)} BNB</p>}
                    {props.direct_bonus > 0 && <p className="hint-text">+{(props.direct_bonus / multiplier).toFixed(5)} BNB from Party Members.</p>}
                    <button onClick={props.withdraw}>SELL for  {checkTime() ? (calculateFullPayoutReinvest()/multiplier).toFixed(5) : ((calculateFullPayoutReinvest()/multiplier)*0.1).toFixed(5)} BNB</button><br/><br/>
                    <button onClick={props.disableWarning}>Cancel</button>
                </div>

            </div>
        </div>
    )
}

export default Warning