import { useEffect, useState } from "react";
import "../Warning.css"

function LookForBean(props) {
    
    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    const beansMultiplier = 1000000000000;

    const [random, setRandom] = useState(0);
    const [timeElapsed, setTimeElapsed] = useState(props.timestamp - props.beanCheckpoint);

    const randomEvents =
    [
        "You stepped on a huge pile of dragon poo!",
        "An Undead Skeleton meets you on your path, it is wearing tattered armor while holding a large blunt sword.",
        "While exploring some underwater ruins in the eastern sea, a school of mermaids swim into the large entrance hall.\nThey do not notice you.",
        "Your travels find you deep in the magic forest of For’daal. You hear a growl like none other behind you, when you turn around you see a massive two headed dog about the size of a cow. You ready your blade.",
        "It’s a hot summer day and you find yourself on the eastern ocean in a small fishing vessel. There are 4 fishermen traveling with you. You lazily cast your fishing line and kick your feet up on the edge of the boat. It’s a nice relaxing time.",
        "You and your party are in the Mountains of Coldsteel. Looking for the elusive Ice Giants. You come upon a small encampment that looks abandoned. There are giant footprints around as well.",
        "Your guildmaster sent you on a task to gather materials in the forests outside the city. You have been gathering all day and have collected enough everpine nuts to last a good few months. You haven’t found any Magic Beans though. The sun is setting and it’s getting late.",
        "Walking knee deep in the swamplands of OO’Gar.\nWhoa! What the heck is that? You see a golden frog hopping in and out of the water. You take four large steps and jump with your out stretched hand. Got it! Nevermind if you completely soaked all your gear…",
        "You decide it might be nice to visit the Royal Palace in Dolron. The King has been offering wild sums for talented warriors to fight the now awake Ancient Dragon Lord Nefagan. Let’s see what he has to offer us…",
        "Your travels find you in the magic forest of For’Daal. You hear some twinkling noises up ahead, After peering out from the bushes you find a Fairy Camp. They notice you at once and two guards fly towards you. “Human! Human! Please, we need your help! Our princess was turned into a toad by some of the witches in these woods. Perhaps a kiss from you can turn her back.” You notice a fat green toad on the ground close to the camp…",
        "You find yourself in the crypt of Al’Tuun. A dangerous and wretched place full of undead, bugs, clay golems, and powerful necromancers. You have some new members in your party you are just not sure about. Out of nowhere you are ambushed by zombies and skeletons. You begin the battle.",
        "Your guild master sends you out into the woods to collect sticks for arrows and good branches for bows. You collect quite a few nice specimens during the daylight without incident. On your way home you see two horses galloping towards you.",
        "It’s a nice warm summer day and you find yourself with some free time and no responsibilities from the guild. You decide to go out into the forest to a wonderful spring you know about. You make your way out to the spring and doze off on the sand to the sound of the running water…",
        "You find yourself in another beginner’s class. Learning the fundamentals of how to hold a staff, or how to swing the sword. How you cast your first fireball or how to properly jump a distance. During the lecture portion you find yourself getting tired, maybe I’ll just shut my eyes…",
        "You’re learning how to swing a sword at the guild hall. You’re supposed to be practicing a standard cut from top to bottom but you spin around in a swirling attack that you’re not supposed to do…",
        "You are studying your books in the cafeteria of The Great Bazaar. You’ve had enough reading for the day and decide to pack up your things and head back to your room.",
        "Your guild master tasks you with washing all of the training gear. An absolutely wretched stench comes from all of the soil garments and armor. What an awful task… Guess you have to put in your dues…",
        "The Guild of the Dark Arts has a job posted to collect bonechips from the outer cemeteries. These cemeteries are teeming with undead during the nights, many adventurers battle in search of treasure left by the living. In the day though, they are quite safe and absolutely littered with bones.You decide you could use some extra coin and take on the task…",
        "You find yourself having trouble lifting the great battle axes in the guild training hall. Even the smaller one is just too heavy for you to wield with any power. A few barbarians enter the hall as you’re practice and begin to laugh loudly.",
        "You decide to create your first party. You pick a sturdy looking cleric, A talented bard, and a quiet spellcaster , not yet specialized. You set off into the forest on the outskirts of town at dusk. Night Hound pelts have been fetching a good price.",
        "You’re shopping for some breakfast but the baker asks for your assistance when he sees your guild patch. “Can you please help me? I am completely out of red berries. There’s a patch just outside of the east gate over the first hill, will you fetch some for me? I’ll make it worth your while.",
        "You are taking a stroll along the river just outside of the city and you see a Magic Bean floating quickly up ahead coming your direction!!",
        "While out gathering berries for you guild, you see a small squirrel with a magic bean in his mouth. You set off running after him.",
        "Seated at the poker table in the The Dreary Rose tavern you look at the cards just dealt to you and see two golden stars. Your heart races as you’ve just secured the best hand you can get. You look over calmly across the table at the other gamblers.",
    ]

    const randomEventSuccess =
    [
        "After carefully scraping the sole of your boot, you find what seems to be a magic bean stuck to some of the fetid remains! Lucky you!",
        "You dodge its attack and smash it into pieces with a precise blow to the rib cage. Bones fly everywhere. A Magic Bean was hidden inside the tattered tunic!",
        "A mermaid notices you and swims up to you curiously.\n“What are you doing here human?”\nYou reply: “I have come looking for treasure.”\nThe mermaid smiles at you: “There is nothing valuable here for you human, on second thought, you humans like these don’t you?”\nShe hands you a Magic Bean!\nYou swim away without incident.",
        "The dog pounces at you and you swing your sword and miss completely. The dog doubles back and misses you with its bite but knocks you down to the ground. You brandish your sword and the dog growls at you some more but seems uninterested and runs off. While picking yourself up off the ground, you notice a small glowing Magic Bean! What Luck!",
        "Your fishing line begins to bob, and you think you have something! It’s a small trout! After you pull the fish out and release the hook, it spits out a glowing Magic Bean!",
        "Upon inspection of the encampment you find a small coin purse. Inside are some copper coins, enough to buy a weeks worth of food. Oh! What’s this glowing near the bottom? A Magic Bean!",
        "It’s easier to see glow of Magic Beans at night, so you stay out just a bit longer and risk encountering  some nightspawn. Lucky you did, persistence pays off and you find a large Magic Bean near the exit of the forest trail. It is going to be a good night at The Empty Bucket Pub!",
        "The glowing golden frog spits out a Magic Bean! In your excitement, the frog slips from your hand and jumps off deep into the swamp. Well, at least you got a Magic Bean!",
        "You get to the castle gates but it’s closed to audiences for the day. You kick the bottom of the entrance gate out of frustration and a Magic Bean falls out!",
        "Fairies are known tricksters but you humor them anyway. You pick up the large toad and give it a kiss. Amazingly it instantly poofs into a small fairy girl! “Ahh thank you fair human! Those witches can really be a problem sometimes. Here, take this as my thanks.” She hands you a Magic Bean and flies off back towards her camp.",
        "There are just too many of the monsters, you look over at your wizard and shout to him. EVACUATE! He begins to cast the spell. EVERYONE GET CLOSE NOW!! Just in the nick of time you all black out and materialize at the wizard ruins in the Great Planes. You have quite a journey back to home but at least you all survived. Underneath where you are sitting, you spot a glowing golden magic bean. What luck!",
        "The knights stop just ahead of you and whistle for you to come over. Hello young recruit. We have found many of these in our travels and adventures, here’s a little something to help you out. He flicks a glowing golden magic bean in your direction. “Thank you sir!” They go on their way as you continue back into the city.",
        "You wake up suddenly and notice it’s almost dark. There’s no way you’re going to make it out of the forest before night hits, so you’re sure to encounter some night spawn. You decide it’s best to start running. As you near the exit you notice a mature ghoul up ahead, but he doesn’t even notice you. You sneak the rest of the way out. In the darkness you notice a glowing golden bean just near the tree line, you snatch up a Magic Bean and head home to the city.",
        "You startle awake and notice there is no one in the class but the teacher up front. “What time is it??” He says you have been sleeping a number of hours but everyone figured you might need some rest. He flicks you a glowing golden Magic Bean. Here take that to the bazaar and see if you can get something to help yourself out.",
        "By sheer luck you gracefully nick the scarecrow in front of you across the neck, causing a small amount of straw to spill from him. A killing blow indeed. In the straw you notice a glowing golden Magic Bean. You pick it up and put it in your pocket, luck seems to be on your side.",
        "As you pack up your books you notice underneath your table a small golden Magic Bean, someone must have dropped it but you had been there for a few hours and no one came by or said anything to you. You feel it’s a small gift from God and don’t have any issues putting it into your bag.",
        "While washing you check the pockets of each garment, you end up finding 3 gold pieces but best of all a glowing Magic Bean. When you deliver it to the guild master, he swings his hand and turns around to show you another pile just as large sitting behind him. “Do this one tomorrow” he says. Your heart beats at the excitement of possibly finding another Magic Bean, but you don’t let him on any and sulk away after learning your task.",
        "You collect two large backpacks of bone chips, this will be enough to pay for your weeks dues and then some. You even found a few iron shackles strewn about, and you’re sure you can fetch a few more coins for them as well. But luckiest of them all, you found a glowing Magic Bean lodged in a femur bone, how it got there who knows but you’re not complaining.",
        "“Human, put that Ax down, you’re not fit for a weapon like that.” One of the Barbarians flicks a glowing golden bean in your direction. “Find yourself something else in the bazaar, leave the axes to the men!” He laughs loudly and walks off.",
        "You move together closely and set up a spot underneath a small mound, nothing along the tree line is able to see you where you’re set up. You catch a lone hound unaware. You ready your bow and land a solid arrow straight in it’s chest. It howls and begins to bound towards you. The bard and the spell caster begin to focus together and let out an inferno right as the Hound jumps over the mound. It’s instantly throw backward and you thrust a sword into the underbelly finishing it off. After killing a few more hounds you decide to split what you will earn from the pelts. A Magic Bean is your reward.",
        "You head out and gather a few baskets worth of berries, it was a nice day anyway and you hadn’t any responsibilities from the guild. When you bring the berries back to the baker he flicks a golden glowing magic bean in your direction. “I heard you warriors like these things…”",
        "You time your dive perfectly into the river and swim on quickly to the Magic Bean. You secure it in your hand and hold tightly while swimming back to the bank. You place it into your coin purse.",
        "The squirrel is startled, drops everything in its mouth, and immediately runs towards the nearest tree for safety. You easily gather the Magic Bean and put it in your coin pouch.",
        "The roll comes out with golden suns and your heart races. You’ve won without a doubt! No other hand could defeat yours. A large bearded man with a small dog on the table in front of him makes a hefty bet. You match him and raise a Magic Bean. You flick yours onto the table. He looks at you for a minute, reaches into his tunic and also flicks one onto the table. You both flip cards and he sees his fate. You survey the bar as you rake your winnings back…",

    ]

    const randomEventFailure =
    [
        "You managed to remove every last bit with a stick you found (Yuck!)",
        "You dodge its attack and smash it into pieces with a precise blow to the rib cage. Bones fly everywhere. There is nothing of note in the pile.",
        "You drink an invisibility potion and slip by them all unnoticed. As soon as you clear from the ruins you return to view and swim away without incident.",
        "The dog pounces at you and you swing your sword and just barely cut it near it’s neck and shoulder area. It whimpers in pain and runs off.",
        "Your fishing line begins to bob, you think you have something! You begin to reel and pull out some seaweed. Oh well, let’s try again…",
        "Upon inspection of the encampment you find some old dried food that will be helpful for your journey. You take the food and say a small prayer of thanks to those who came before you.",
        "You don’t want to encounter any nightspawn, so you call it a day and head back towards the city. A nice drink at The Empty Bucket Pub awaits you.",
        "The glowing golden frog turns back into a green frog while you’re holding it. Your hand and arm starts to feel numb. Rats! It must have been a witches curse! You arm remains numb for a number hours after.",
        "You get to the castle gates but it’s closed to audiences for the day. Well, we can always try again tomorrow. You turn back to go home.",
        "Fairies are known tricksters but you humor them anyway. You pick up the large toad and give it a kiss. The fairies all laugh at you! “It’s just a toad you silly human! Hahaha!” You toss the toad far into the woods out of frustration and leave the fairy camp.",
        "You smash two skeletons quickly and begin to cast a mind control spell on another. The zombies are ravaging your wizard and he looks in trouble. Your rogue is able to kill a few with some brutally well timed backstabs. It looks like you’re going to survive, two of your party members died but your cleric is experienced enough to bring them back to life in this short of a time frame. You let everyone know that you must go, as this crypt might be too much for you to handle…",
        "Two knights blow by you, knock you over, and kick up mud onto your cloth tunic. Neither acknowledge your presence.",
        "You wake up suddenly and notice it’s almost dark. There’s no way you’re going to make it out of the forest before night hits, so you’re sure to encounter some night spawn. You decide it’s best to start running. You make it close to the exit before being attacked by 3 night hounds. You swiftly dispatch them and thank your stars it wasn’t a mature ghoul. You make it home to the city safely.",
        "You feel something extremely hot radiate on your forehead. The teacher hit you with a small burst of flame spell. You startle awake and most of the class is laughing at you. The teacher resumes his lecture without addressing you.",
        "You miss the target completely and the hall goes silent as your sword leaves your hand and bangs loudly on the floor. How embarrassing…",
        "As you pack your books up you realize you forget one on the seat next to you. You go back to your table and find it still laying there. Phew! That one would have cost you some beans.",
        "You’re able to clean it all but it takes an entire day. When you deliver it to the guild master, he swings his hand and turns around to show you another pile just as large sitting behind him. “Do this one tomorrow” he says.",
        "You collect two large backpacks of bone chips, this will be enough to pay for your weeks dues and then some. You even found a few iron shackles strewn about, and you’re sure you can fetch a few more coins for them as well.",
        "“Human you’re having trouble with that. Ha Ha Ha! You’re not fit for a weapon like that! Here let me show you how to hold it properly.” He comes over and gives you a few tips, and although it feels a little better it’s still far too heavy for you to use effectively.",
        "The first pack you see engages quickly on you and catches you all off guard. One rolls by you and grabs onto the cleric’s arm, the bard freaks out and runs away, the spellcaster is unable to hit the fast approaching hounds with any of his fire magic. You make the call and shout RUN! Everyone barely makes it back to the gates with their lives.",
        "You head out and gather a few baskets worth of berries, it was a nice day anyway and you hadn’t any responsibilities from the guild. When you bring the berries back to the baker he gives you two fresh loaves of bread. “Here take these, and thank you.”",
        "You trip while jumping in the water and splash into the water. When you look back up you see a large river trout snatch the bean and swim off. Rats!",
        "The squirrel quickly darts to the nearest tree and is 15 feet up before you get to the trunk. There’s no way you’ll catch him now.",
        "The roll comes out and you see nothing but Silver Moons. Your hand doesn’t seem so promising anymore. A large bearded man with a small dog on the table infront of him makes a hefty bet. Even though you may still have the best hand, it’s too big of a risky at this point. You toss your cards to the dealer and look away to survey the tavern…",
    ]


    function checkTime() {
        if((timeElapsed) > 86400 - props.equipped.multipliers[20]) {
            return true;
        }
        else {
            return false;
        }
    }

    function returnHours() {
        return Math.floor((86400 - (props.timestamp - props.beanCheckpoint) - props.equipped.multipliers[20])/3600)
    }

    function returnMinutes() {
        return Math.floor(((86400 - (props.timestamp - props.beanCheckpoint) - props.equipped.multipliers[20])/60)%60)
    }

    function randomEventRandomize() {
        var _random = 999999
        if(_random == 999999) {
            _random = Math.floor(Math.random() * randomEvents.length);    
        }
        console.log(_random);
        setRandom(_random);
        props.lookForBean();
    }   

    useEffect(() => {
    // const interval = setInterval(() => {
    //     },
    //     1000);
    //     return () => clearInterval(interval);
    }, [])

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <h2>HAPPENING</h2>
                    {!props.searchComplete && checkTime() && !props.lookingForBean &&
                    <div>
                        <p>You have a distinct feeling that something unusual might happen today...</p>
                        <button disabled={!checkTime()} onClick={randomEventRandomize}>Go adventuring!</button><br/><br/>
                        <button onClick={props.disableBean}>Probably nothing...</button>
                    </div>
                    }
                    {!checkTime() && !props.searchComplete && !props.lookingForBean &&
                    <div>
                        <h3></h3>
                        <p>Hmm, looks like there's nothing interesting happening.<br/>It feels like something might happen in...<br/> {returnHours()} Hours and {returnMinutes()} Minutes? </p>
                        {props.equipped.bonuses[20] && <p className="hint-text">-{(props.equipped.equippedRelics[20].multiplier / 3600).toFixed()} Hours: Bonus provided by {props.equipped.equippedRelics[20].relicName} {props.equipped.equippedRelics[20].consumable && <span>{"("}consumable{")"}</span>}</p>}
                        <p className="hint-text">Hint:<br/>Some items will make Random Events happen more often.</p>
                        <button disabled={!checkTime() || props.lookingForBean} onClick={() => {randomEventRandomize()}}>Nothing ever happens...</button><br/><br/>
                        <button onClick={props.disableBean}>Go back</button>
                    </div>}
                    {props.lookingForBean &&
                    <div>
                        <p>{randomEvents[random]}</p>
                        <p className="hint-text">(This should take roughly 20 seconds)</p>
                    </div>}
                    {!props.lookingForBean && props.searchComplete && !props.beanFound &&
                    <div>
                        <p>{randomEvents[random]}</p>
                        <p>{randomEventFailure[random]}</p>
                        <button onClick={props.disableBean}>Go back</button>
                    </div>}
                    {!props.lookingForBean && props.searchComplete && props.beanFound &&
                    <div>
                        <p>{randomEvents[random]}</p>
                        <p>{randomEventSuccess[random]}</p>
                        <h2>Magic Bean Found!</h2>
                        <button onClick={props.disableBean}>Go back</button>
                    </div>}
                    
                </div>
                <div className="gacha-footer">
                    <p>Powered by</p> 
                    <a target="_blank" href="https://kenshi.io">
                    <img src="kenshi-logo.svg"></img>
                    </a>
                </div> 
            </div>
        </div>
    )
}

export default LookForBean