import { useWeb3React } from "@web3-react/core";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Web3Modal from "web3modal";
import {ethers} from "ethers";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from "@walletconnect/web3-provider"
import "../Connect.css";
import truncateEthAddress from 'truncate-eth-address'
import Web3 from "web3";


const Connect = forwardRef((props, ref) => {

    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const providerOptions = {
        coinbasewallet: {
            package: CoinbaseWalletSDK,
            options: {
                appName: "rpg-miner",
                // rpc: {200101: "https://rpc-devnet-cardano-evm.c1.milkomeda.com"}
                rpc: {56: provider}
            }
        },
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                appName: "rpg-miner",
                //rpc: {137: "https://polygon-rpc.com/"}
                rpc: {56: provider}
                // rpc: {200101: "https://rpc-devnet-cardano-evm.c1.milkomeda.com"}
            }
        }
    }

    const [web3Provider, setWeb3Provider] = useState(null);

    async function connectWallet() {
        try {
            let web3Modal = new Web3Modal({
                cacheProvider: false,
                providerOptions,
            });
            const web3ModalInstance = await web3Modal.connect();
            const web3ModalProvider = await new ethers.providers.Web3Provider(web3ModalInstance);
            await props.connect()
            if (web3ModalProvider) {
                setWeb3Provider(web3ModalProvider);
                if(web3ModalInstance.networkVersion != "56") {
                    props.setWeb3Instance();
                }
            }
            props.getName();
        } catch(error) {
            console.error(error);
        }
        setMouseOver(false);
    }

    const[mouseOver, setMouseOver] = useState(false);
    

    return(
        <div className="fixed-header">
            {!props.active ?
            <button onClick={connectWallet} className="connect-button">Connect Wallet</button> :
            <div>
                <button onClick={() => props.disconnect()} onMouseOver={() => setMouseOver(true)} onMouseOut={() => setMouseOver(false)} className="connected">{!mouseOver ? truncateEthAddress(props.account) : "Disconnect?"}</button>
            </div>
            }
        </div>
    )
})

export default Connect