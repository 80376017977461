import { useEffect, useRef, useState } from "react";
import "../Boss.css";
import BossItem from "./BossItem.js";

function Boss(props) {

    const statsMultiplier = 100000000000000000;
    const bossDefeated = props.bossDefeated;

    const [showItemMini, setShowItemMini] = useState(false);
    const [bossItem, setBossItem] = useState(false);

    useEffect(() => {
        props.getBossInfo();
        console.log("activated boss fight");
    }, [])

    function showBossItem() {
        setBossItem(true);
    }


    return(
        <div className="box-boss">
            {!props.loading ? <div className="boss-content">
                <h1 className="box-title">{bossDefeated ? "BOSS DEFEATED!" : "BOSS FIGHT!"}</h1>
                <h3 className="box-title">{props.bossInfo.name}!</h3>
                <div className="glow-around">
                    <img className="boss-image" src={"./bosses/" + props.bossInfo.name + ".jpg"}></img>
                </div>
                <h3 className="box-title">Reward:</h3>
                <div className="item-show" onMouseOver={() => {setShowItemMini(true)}} onMouseOut={() => {setShowItemMini(false)}} onClick={showBossItem}>
                    <p className="item-title" >{props.bossRelic.relicName} x {props.bossInfo.amountDropped}</p>
                    {showItemMini && <div className="cursor">
                        <img src={"./items/img-only-border/" + props.bossInfo.nftDrop + ".jpg"}/>
                    </div>}
                </div>
                <p className="box-title" style={{fontSize:"0.9em"}}>x{(props.bossInfo.rewardMultiplier / 100)} Bonus Multiplier on win.</p>
                <h3 className="box-title">Stats:</h3>
                <div className="box-title" style={{fontSize:"0.9em"}}>
                    <p>Strength: {props.bossInfo.STRneeded / statsMultiplier}<br/>
                    {parseInt(props.bossInfo.STRneeded) < parseInt(props.bossInfo.DEXneeded) && parseInt(props.bossInfo.STRneeded) < parseInt(props.bossInfo.INTneeded) && <span>{"("}Weak point{")"}</span>}
                    </p>
                    <p>Dexterity: {props.bossInfo.DEXneeded / statsMultiplier}<br/>
                    {parseInt(props.bossInfo.DEXneeded) < parseInt(props.bossInfo.STRneeded) && parseInt(props.bossInfo.DEXneeded) < parseInt(props.bossInfo.INTneeded) && <span>{"("}Weak point{")"}</span>}
                    </p>
                    <p>Intelligence: {props.bossInfo.INTneeded / statsMultiplier}<br/>
                    {parseInt(props.bossInfo.INTneeded) < parseInt(props.bossInfo.DEXneeded) && parseInt(props.bossInfo.INTneeded) < parseInt(props.bossInfo.STRneeded) && <span>{"("}Weak point{")"}</span>}
                    </p>

                </div>
            </div> : <h1 className="box-title">LOADING BOSS...</h1>}
            {bossItem == true && <BossItem id={props.bossInfo.nftDrop} relic={props.bossRelic} disableBox={() => setBossItem(false)}/>}
        </div>
    )
}

export default Boss;