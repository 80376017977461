import './App.css';
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./Components/wallet/connectors";
import Miner from "./Components/Miner.js";
import Boss from './Components/Boss';
import Inventory from './Components/Inventory';
import Gacha from './Components/Gacha.js';
import Stats from './Components/Stats.js';
import Connect from "./Components/Connect.js";
import Error from "./Components/Error.js";
import Splash from "./Components/Splash.js";
import Header from './Components/Header';
import Footer from './Components/Footer';
import Start from './Components/Start';
import About from "./Components/About";
import Ladder from "./Components/Ladder";
import Trading from "./Components/Trading";
import Quests from "./Components/Quests";
import Guilds from "./Components/Guilds";
import Web3 from 'web3';
import { useRef, useState } from 'react';
import './Background.css';
import MinerContract from "./contracts/minerabi.json";
import NFTContract from "./contracts/nfts.json";
import BossContract from "./contracts/bosses.json";
import GachaContract from "./contracts/gacha.json";
import TradingContract from "./contracts/trading.json";

function App(props) {

  const { active, account, library, connector, activate, deactivate } = useWeb3React();
  //const provider = "https://matic-mumbai.chainstacklabs.com";
  // const provider = "https://rpc-devnet-cardano-evm.c1.milkomeda.com"
  // const provider = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  const provider = props.provider;

  const web3 = new Web3(provider);
  web3.eth.setProvider(Web3.givenProvider);

  const [headerActive, setHeader] = useState(false);
  const [minerActive, setMiner] = useState(false);
  const [switchActive, setSwitch] = useState(false);
  const [splashActive, setSplash] = useState(true);
  const [bossActive, setBoss] = useState(false);
  const [inventoryActive, setInventory] = useState(false);
  const [connectActive, setConnect] = useState(false);
  const [startActive, setStart] = useState(false);
  const [gachaActive, setGacha] = useState(false);
  const [statsActive, setStats] = useState(false);
  const [ladderActive, setLadder] = useState(false);
  const [aboutActive, setAbout] = useState(false);
  const [questsActive, setQuests] = useState(false);
  const [tradingActive, setTrading] = useState(false);
  const [guildsActive, setGuilds] = useState(false);

  const [bossInfo, setBossInfo] = useState([]);
  const [bossDefeated, setBossDefeated] = useState(false);
  const [bossRelic, setBossRelic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingName, setLoadingName] = useState(false);
  const [nickname, setNickname] = useState("");
  const [nickFail, setNickFail] = useState(false);
  const [userInfo, setUser] = useState([]);
  const [deposited, setDeposited] = useState();

  const [tradesTo, setTradesTo] = useState([]);
  const [magicBeans, setMagicBeans] = useState();

  //MINER STATS
  const [TVL, setTVL] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const [balance, setBalance] = useState(0);
  const multiplier = 1000000000000000000;

  // // //testnet
  // const minerContractAddress = "0xf70743A8e05261FB9E54211098D8ede0BDBA00eC";
  // const bossContractAddress = "0x76E4E1309133fB0545D5e8c48B25B42B11Bfc8f0";
  // const nftContractAddress = "0xB97D65122bc263DB4EAbCdBFA970785Bc776Ea76";
  // const gachaContractAddress = "0xeaa1b72106b7fAbB7721Ad30b4037533F3273B87";
  // const vrfContractAddress = "";
  // const tradingContractAddress = "0x6961EF9fBEce40caA712185C3e41997a8e7Df2b1";
  // const guildsContractAddress = "0x3a531A2AA7DF65753ecE58DbC336792a2dd0506f";

  // mainnet
  const minerContractAddress = "0x9f5FcfeaaF8aA60cB4239d53507429b8cC86b015";
  const bossContractAddress = "0x47EA59791cE272cE6052cEEBD6C9A6706Deb7a00";
  const nftContractAddress = "0xcF807B56F5c59B11A6e5448E6D40CBA2c84874EA";
  const gachaContractAddress = "0x6759dda0e5f4e4205DDF8909FDEF33EBdcB251Ca";
  const vrfContractAddress = "0x63cD9B39b12d3ed17C48Ef88e6CB489EB234551c";
  const tradingContractAddress = "0x0068b5d23A95d11F820D5552c9E51bD587a9C0dA";
    const ladderAddress="0x266C178221f06eEEE91BE6a07606983d1a6e08D1";
    const questsContractAddress = "0x2e3f294c545160e5b75c504EEAb05Bda48C45991";
  const guildsContractAddress = "0x2D509827281DFC05D22d8203D6E9e284f8505BBE";
  
  // OLD
  // const nftContractAddress = "0x0c934422FbAB47668C2b3284B95cF444297A23B0";
  // const vrfContractAddress = "0xD76EB235a290DF924ccaEBc596466Fa7f1Fa185D";
  // const ladderAddress = "0xb4FEff70D6cfCd23bee2D68D75861a6cc9E09B5D";





  const nftContract = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  );
  const minerContract = new web3.eth.Contract(    
    MinerContract,
    minerContractAddress
  )
  const bossContract = new web3.eth.Contract(
    BossContract,
    bossContractAddress
  )
  const gacha = new web3.eth.Contract(
    GachaContract,
    gachaContractAddress
  )
  const trading = new web3.eth.Contract(
    TradingContract,
    tradingContractAddress
  )

  

  const ChildRef = useRef()

  async function getMinerInfo() {
    const _tvl = await web3.eth.getBalance(minerContractAddress);
    setTVL(_tvl);  
    const _users = await minerContract.methods.total_users().call();
    setTotalUsers(_users);
  }

  async function getName() {
    setLoadingName(true);
    const addresses = await web3.eth.getAccounts();
    var userInfo = await minerContract.methods.users(addresses[0]).call();
    setDeposited(userInfo.deposit_amount);
    console.log(userInfo.deposit_amount)
    setNickname("");
    const name = await minerContract.methods.getNicknameToAddress(addresses[0]).call();
    name != "" && setNickname(name);
    const _trades = await trading.methods.getTradesToAddress(addresses[0]).call();
    const _stats = await gacha.methods.stats(addresses[0]).call();
    const _timestamp = await minerContract.methods.getBlockTimeStamp().call();
    const _realTrades = _trades.filter(trade => !trade.fulfilled && parseFloat(trade.timeLimit) > parseFloat(_timestamp) - parseFloat(trade.timeInitiated));
    console.log(_realTrades);
    console.log(_timestamp);
    setTradesTo(_realTrades);
    setMagicBeans(_stats.tokens);
    console.log(_stats.tokens);
    setLoadingName(false);
  }

  async function setName(name) {
    console.log(name);
    const check = await minerContract.methods.checkNickname(name).call();
    if(check) {
      try {
        setNickFail(false);
        await minerContract.methods.setUserNickname(name, account).send(
          {from: account,
            gas: 200000,
          }
        );
        setTimeout(getName, 2000);
    }catch (ex) {
        console.error(ex)
    }
    }
    else {
      setNickFail(true);
      console.log("Error setting nickname");
    }
  }

  async function getBoss() {
    if(loading) {
      setLoading(true);
      const addresses = await web3.eth.getAccounts();
      const _cBoss = await bossContract.methods.currentBoss().call();
      const _info = await bossContract.methods.bossEverything(_cBoss).call();
      const _relic = await nftContract.methods.relics(_info.nftDrop).call();
      const _bossDefeated = await minerContract.methods.defeatedThisRound(_cBoss, addresses[0]).call();
      setBossDefeated(_bossDefeated);
      setBossInfo(_info);
      setBossRelic(_relic);
      setLoading(false);
    }
  }

  function handleFightBoss() {
    bossActive ? setBoss(false) : setBoss(true);
  }

  function setGachaActive() {
    setMiner(false);
    setBoss(false);
    setInventory(false);
    setGacha(true);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setStartActive() {
    setSwitch(false);
    setSplash(false);
    setStart(true);
    setConnect(true);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setMinerActive() {
    setMiner(true);
    setBoss(false);
    setConnect(true);
    setInventory(false);
    setSwitch(false);
    setSplash(false);
    setHeader(true);
    setStart(false);
    setGacha(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }


  function setInventoryActive() {
    setMiner(false);
    setBoss(false);
    setInventory(true);
    setGacha(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setSwitchActive() {
    setMiner(false);
    setStart(false);
    setSwitch(true);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setStatsActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(true);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setAboutActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(false);
    setLadder(false);
    setAbout(true);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setLadderActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(false);
    setLadder(true);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(false);
  }

  function setQuestsActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(true);
    setTrading(false);
    setGuilds(false);
  }

  function setTradingActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(true);
    setGuilds(false);
  }

  function setGuildsActive() {
    setMiner(false);
    setStart(false);
    setSwitch(false);
    setInventory(false);
    setSplash(false);
    setGacha(false);
    setBoss(false);
    setStats(false);
    setLadder(false);
    setAbout(false);
    setQuests(false);
    setTrading(false);
    setGuilds(true);
  }

  async function setWeb3Instance() {
    console.log("Wrong network, switch to Milkomeda TestNet to proceed");
    setSwitchActive();
  }

  async function connect() {
      try {
          await activate(injected);

      }catch (ex) {
          console.log(ex)
      }

      console.log(balance);
  }
  async function disconnect() {
      try {
          deactivate()
      } catch (ex) {
          console.log(ex);
      }
  }
  return (
        <div className='full-screen'>
            {splashActive && <Splash totalUsers={totalUsers} TVL={TVL} getMinerInfo={() => getMinerInfo()} setMiner={() => setMinerActive()} setStart={() => setStartActive()}/>}
            {startActive && <Start tradesTo={tradesTo} magicBeans={magicBeans} deposited={deposited} setMinerActive={() => setMinerActive()} provider={provider} connect={connect} loadingName={loadingName} setMiner={() => setMinerActive()} active={active} nickname={nickname} getName={() => getName()} setName={setName} nickFail={nickFail}/>}
            {headerActive && <Header setGuilds={setGuildsActive} setQuests={setQuestsActive} questsActive={questsActive} setTrading={setTradingActive} tradingActive={tradingActive} ladderActive={ladderActive} setLadder={() => setLadderActive()} aboutActive={aboutActive} setAbout={() => setAboutActive()} statsActive={statsActive} setStats={() => setStatsActive()} gachaActive={gachaActive} setGacha={() => setGachaActive()} inventoryActive={inventoryActive} setInventory={() => setInventoryActive()} minerActive={minerActive} setMiner={() => setMinerActive()} />}
            {connectActive && <Connect provider={provider} getName={getName} setWeb3Instance={() => setWeb3Instance()} connect={() => connect()} disconnect={() => disconnect()} account={account} active={active} />}
            {minerActive && <Miner provider={provider} gachaContractAddress={gachaContractAddress} vrfContract={vrfContractAddress} nickname={nickname} bossContractAddress={bossContractAddress} minerContractAddress={minerContractAddress} nftContractAddress={nftContractAddress} handleFightBoss={() => handleFightBoss()} fightingBoss={bossActive} setSwitch={() => setSwitchActive()} setInventory={() => setInventoryActive()} ref={ChildRef} connect={() => connect()} active={active}/>}
            {bossActive && <Boss provider={provider} bossDefeated={bossDefeated} loading={loading} getBossInfo={() => getBoss()} bossInfo={bossInfo} bossRelic={bossRelic}/>}
            {inventoryActive && <Inventory provider={provider} nftContract={nftContractAddress} />}
            {gachaActive && <Gacha provider={provider} gachaContract ={gachaContractAddress} nftContract={nftContractAddress} />}
            {statsActive && <Stats provider={provider} minerContract={minerContractAddress} bossContract={bossContractAddress} gachaContract ={gachaContractAddress} nftContract={nftContractAddress} />}
            {aboutActive && <About />}
            {ladderActive && <Ladder ladderAddress={ladderAddress} provider={provider} gachaContract={gachaContractAddress} vrfContract={vrfContractAddress} bossContract={bossContractAddress} minerContract={minerContractAddress} nftContract={nftContractAddress} />}
            {questsActive && <Quests questsContract={questsContractAddress} gachaContract ={gachaContractAddress} nftContract={nftContractAddress} provider={provider} />}
            {tradingActive && <Trading minerAddress={minerContractAddress} gachaContract={gachaContractAddress} tradingAddress={tradingContractAddress} nftContract={nftContractAddress} provider={provider}/>}
            {guildsActive && <Guilds minerAddress={minerContractAddress} guildsContract={guildsContractAddress} nftContract={nftContractAddress}/>}
            {switchActive && <Error provider={provider} setStart={setStartActive} setMiner={() => setMinerActive()}/>}
            <Footer />
          </div>
  );
}

export default App;
