import { useEffect, useState } from "react";
import "../Gacha.css"
import BossItem from "./BossItem";
import Refund from "./Refund";
import NFTContract from "../contracts/nfts.json";
import GachaContract from "../contracts/gacha.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";

function Gacha(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const gachaContractAddress = props.gachaContract;

  const nftContract = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  )

  const gacha = new web3.eth.Contract(
    GachaContract,
    gachaContractAddress
  )

  const [merchantAction, setAction] = useState(1);

  const [merchantTalk, setMerchantTalk] = useState(false);

  const [gachaCommon, setCommon] = useState([]);
  const [gachaUncommon, setUncommon] = useState([]);
  const [gachaRare, setRare] = useState([]);
  const [gachaLegendary, setLegendary] = useState([]);
  const [cost, setCost] = useState();
  const [allGacha, setAll] = useState([]);
  const [relics, setRelics] = useState([]);
  const [lastGacha, setLast] = useState([]);
  const [lastGachaRarities, setRarities] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [balance, setBalance] = useState(0);
  const [useTokens, setUseTokens] = useState(false);

  const [times10Minted, set10Minted] = useState(0);

  const [amount, setAmount] = useState(1);

  const [loaded, setLoaded] = useState(false);

  const [showItem, setShowItem] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [showItemWindow, setShowItemWindow] = useState([false, false, false, false, false, false, false, false, false, false]);
  const [refundWindow, setRefundWindow] = useState(false);

  const [loadingGacha, setLoadingGacha] = useState(false);
  const [displayResults, setDisplayResults] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [failed, setFailed] = useState(false);
  const [seeAvailable, setSeeAvailable] = useState(false);

  //REFUND
  const [blockNumber, setBlockNumber] = useState();
  const [refundMessage, setRefundMessage] = useState("");
  const [notRefundable, setRefundable] = useState(true);
  const [refundInputDisabled, setDisabledRefund] = useState(false);
  const [idGacha, setIdGacha] = useState([]);
  const [lastRequestRarities, setLastRarities] = useState([]);

  const [imgNumber, setImg] = useState(0);

  const multiplier = 1000000000000000000;

  const [merchantText, setMerchantText] = useState("Look who's here! Well, well, well... welcome to Veezra's Bazaar!")

  const merchantLines = [
    "I am Veezra VonSchlange, the most honest merchant you're ever gonna meet, I guarantee it!",
    "You won't find a better shop around here, trust me... friend...",
    "What are you buying? Finest trinkets in town!",
    "Looking to go off adventuring? Don't be a schmuck, I have just the thing you need!",
    "You must be looking for some very exotic trinkets indeed... *snickers*",
  ];

  const merchantImages= [
    "MerchantTalk",
    "SnakeMerchant",
    "MerchantLuxury"
  ]

  const rarityClasses = [
    "null",
    "common",
    "uncommon",
    "rare",
    "legendary"
  ]

  //REFUND
  async function checkRefund(props) {
    const _refund = await gacha.methods.checkRequestStatus(props).call({
      from: account
    });
    setIdGacha([]);
    if(_refund == "You are elegible for a refund") {
      setRefundable(false);
      setDisabledRefund(true);
    }
    else {
      setRefundable(true);
    }
    if(_refund == "This request has already been fulfilled") {
      const _ids = await gacha.methods.getRequestResult(props).call();
      setIdGacha(_ids);
      console.log(_ids);
      var i = 0;
      const _rarity = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      for (i; i < _ids.length; i++) {
        if(gachaCommon.includes(_ids[i])) {
          _rarity[i] = 1;
        }
        if(gachaUncommon.includes(_ids[i])) {
          _rarity[i] = 2;
        }
        if(gachaRare.includes(_ids[i])) {
          _rarity[i] = 3;
        }
        if(gachaLegendary.includes(_ids[i])) {
          _rarity[i] = 4;
        }
      }
      setLastRarities(_rarity);
    }
    setRefundMessage(_refund);
    console.log(_refund);
  }

  async function requestRefund(props) {
    try {
      setDisabledRefund(true);
      await gacha.methods.requestRefund(props).send({
        from: account,
        gas: 200000,
      })
      setRefundable(true);
      setRefundMessage("Refunded successfully, check your balance");
      setDisabledRefund(false);
      const _balance = await web3.eth.getBalance(account) / multiplier;
      const _userInfo = await gacha.methods.stats(account).call();
      setBalance(_balance);
      setUserInfo(_userInfo);
    }catch (ex) {
      console.log(ex)
    }
  }

  async function getGachas() {
    setLoaded(false);
    var _balance = await web3.eth.getBalance(account) / multiplier;
    setBalance(_balance.toFixed(2));
    const _gachas = await gacha.methods.getGacha().call();
    const _userInfo = await gacha.methods.stats(account).call();
    const _times10Minted = await gacha.methods.times10minted().call();
    set10Minted(_times10Minted);
    setUserInfo(_userInfo);
    const _info = _gachas[0];
    console.log(_gachas);
    setCommon(_info[0]);
    setUncommon(_info[1])
    setRare(_info[2]);
    setLegendary(_info[3]);
    setCost(_info[5]);
    const _relics = await nftContract.methods.getRelics().call();
    setRelics(_relics);
    var aG = _info[0].concat(_info[1]);
    aG = aG.concat(_info[2]);
    aG = aG.concat(_info[3]);
    setAll(aG);
    setLoaded(true);
    console.log(_relics);
  }

  async function mint() {
    setLast([]);
    //loading
    // const test = await web3.eth.getBlockNumber();
    // setBlockNumber(test);
    await gacha.methods.mintGachaVRF(0, amount).send(
        {
            from: account,
            value: !useTokens ? cost * amount : 0,
            gas: 200000,
        }
    )
    //setloading gacha and disable buttons
    setLoadingGacha(true);
    // console.log(test);
    setTimeout(() => getRequestResults(), 15000);
    //gacha loaded reenable buttons
    const _info = await gacha.methods.stats(account).call();
    setUserInfo(_info);
    const _balance = await web3.eth.getBalance(account) / multiplier;
    setBalance(_balance);
  }

  function advanceImage(props) {
    if(props)  {
      if(imgNumber == allGacha.length - 1) {
        setImg(0);
      }
      else {
        setImg(imgNumber+1);
      }
      console.log(imgNumber);
    }
    if(!props) {
      if(imgNumber == 0) {
        setImg(allGacha.length - 1);
      }
      else {
        setImg(imgNumber-1);
      }
      console.log(imgNumber);
    }
  }

  async function getRequestResults() {
    console.log("trying to fetch results")
    const _info = await gacha.methods.stats(account).call();
    console.log(_info);
    const _refund = await gacha.methods.checkRequestStatus(_info.lastRequest).call({
      from: account
    });
    if(_refund == "This request has already been fulfilled") {
      const _ids = await gacha.methods.getRequestResult(_info.lastRequest).call();
      console.log(_ids);
      var i = 0;
      const _rarity = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
      for (i; i < _ids.length; i++) {
        if(gachaCommon.includes(_ids[i])) {
          _rarity[i] = 1;
        }
        if(gachaUncommon.includes(_ids[i])) {
          _rarity[i] = 2;
        }
        if(gachaRare.includes(_ids[i])) {
          _rarity[i] = 3;
        }
        if(gachaLegendary.includes(_ids[i])) {
          _rarity[i] = 4;
        }
      }
      console.log(_rarity);
      setLast(_ids);
      setRarities(_rarity);
      setLoadingGacha(false);
      setDisplayResults(true);
      setErrorMessage(false);
    }
    else {
      setErrorMessage(true);
      console.log("something went wrong");
    }
  }

  async function getEvents(test) {
    var block = await web3.eth.getBlockNumber();
    gacha.getPastEvents(
      'GachaMint',
      {
        fromBlock: test,
        toBlock: 'latest' 
      },
      (err, events) => {
        var i = 0;

        console.log("checking events from blocks: " + test + " to " + block)
        var _last = lastGacha;
        console.log(events);
        for(i; i < events.length; i++) {
          if(events[i].returnValues.user == account) {
            console.log(events[i].returnValues);
            _last = events[i].returnValues.item;
            setLast(events[i].returnValues.item);
            setRarities(events[i].returnValues.rarity)
            setLoadingGacha(false);
            setDisplayResults(true);
            setErrorMessage(false);
          }
        }
        setLoadingGacha(false);
        if(_last.length == 0) {
          setErrorMessage(true);
          console.log("something went wrong");
        }
      }
      )
  }

  function chooseMerchantText() {
    var r = Math.floor(Math.random() * merchantLines.length);
    setMerchantText(merchantLines[r]);
  }

  function handleChangeAmount(event) {
    if(event.target.value <= 10) {
      setAmount(event.target.value);
    } 
  }

  function setShowItemMini(props) {
    if(props == 11) {
      var itemsShow = [false, false, false, false, false, false, false, false, false, false];
      setShowItem(itemsShow);
    }
    else {
      var itemsShow = [false, false, false, false, false, false, false, false, false, false];
      itemsShow[props] = true;
      setShowItem(itemsShow);
    }

  }

  function _showItemWindow(props) {
      var itemsShow = [false, false, false, false, false, false, false, false, false, false];
      itemsShow[props] = true;
      setShowItemWindow(itemsShow);
      console.log("showwindow");
  }

  function disableItemWindow() {
    var itemsShow = [false, false, false, false, false, false, false, false, false, false];
    setShowItemWindow(itemsShow);
    console.log("disable window");
  }

  async function openRefund() {
    const _info = await gacha.methods.stats(account).call();
    setUserInfo(_info);
    setRefundWindow(true);
  }


  useEffect(() => {
    getGachas();
    const interval = setInterval(() => {
      if(errorMessage) {
        console.log("looking for events");
        // getEvents(blockNumber);
      }
    }, 1000);
    return () => clearInterval(interval);
}, []);

  return (
    <div className="gacha-box">
        <h3>Veezra's Bazaar</h3>
        <img className="merchant-img" src={"./Characters/Merchant/" + merchantImages[merchantAction] +".jpg"}></img>
        <button className={merchantAction != 0 ? "merchant-header" : "merchant-header-select"} onClick={() => setAction(0)}>TALK</button>
        <button className={merchantAction != 1 ? "merchant-header" : "merchant-header-select"} onClick={() => setAction(1)}>CHESTS</button>
        <button className={merchantAction != 2 ? "merchant-header" : "merchant-header-select"} onClick={() => setAction(2)}>LUXURY</button>
        {merchantAction == 0 && <div>
          {merchantTalk && times10Minted < 1000 ?
          <p className="merchant-text" onClick={() => setMerchantTalk(false)}>~ Listen kid, for the first 1000 buyers<br/>- Buy 10, get 5 free -<br/>is that simple enough for you?</p> :
          <p className="merchant-text" onClick={() => {setMerchantTalk(true)}}>~ Have you heard the news, stranger? There's been a great sudden influx of adventurers in these parts!
          That's right, and this <span style={{textDecoration:"underline"}}>"Lord Nefagan"</span> is nothing but bad news for the business.<br/>
          Tell you what, <span style={{textDecoration:"underline"}}>the first 1000 adventurers</span> that come looking to buy
          <span style={{textDecoration:"underline"}}> 10 TREASURE CHESTS using BNB</span>, will receive<br/><span style={{textDecoration:"underline"}}>5 Magic Beans each!</span>
          <br/>What?! You don't know what Magic Beans do? *snickers* Don't you worry about that! Bring them to me,
          <span style={{textDecoration:"underline"}}>I'll get you a treasure chest for each Magic Bean you bring me</span>.</p>
          }
          {times10Minted >= 1000 &&
          <p className="merchant-text">~ Sorry kid! Our buy 10 get 5 free promotion is over!<br/>But you'll always get a little something special if you buy 10...</p>}
          {/* <p>0 / 1000<br/>Special Promotions left</p> */}
        </div>}
        {merchantAction == 1 && <div>
        {!useTokens && !loadingGacha && !displayResults && <p onClick={chooseMerchantText} className="merchant-text">~ {merchantText}</p>}
        {useTokens && !loadingGacha && !displayResults && !errorMessage && <p className="merchant-text">~ *sniff* Oooh, what do you have there? Are those *sniff*... Magic Beans perchance? Oh my, those are quite precious indee...<br/>I mean, hey listen, I'll trade you a treasure chest for any of them Beans you bring me, deal?</p>}
        {loaded && gachaCommon.length  > 0 ?
          <div>
          {!displayResults && !loadingGacha && !errorMessage && <div>
            <span>Purchase </span>
            <input style={{backgroundColor:"black", color:"white"}} value={amount} type="number" onChange={handleChangeAmount} min="0" max={useTokens && userInfo.tokens <= 10 ? userInfo.tokens : 10} placeholder="Amount to buy"></input>
            <span>Treasure Chests</span>
            {userInfo.tokens > 0 && <label className="container">
              <input type="checkbox" onChange={() => {setUseTokens(!useTokens); setAmount(0)}} checked={useTokens}/>
              <span className="hint-text" style={{textDecoration:"underline"}}>Use Magic Beans</span>
            </label>}
            {!useTokens && <p className="hint-text">Balance: {balance} → {(balance - cost / multiplier * amount).toFixed(2) + " BNB"}</p>}
            {useTokens && <p className="hint-text">Magic Beans: {userInfo.tokens} → {userInfo.tokens - amount}</p>}
            {amount == 10 && <p className="merchant-text">~ Hey hold on there! You're going to run me out of business!<br/>Why do you need so many anyway? Oh my rats...</p>}
            <br/>
            <button style={{width:"200px"}} className="special-button" disabled={amount == 0 || loadingGacha} onClick={() => mint()}>TAKE A CHANCE - {!useTokens ? (/*cost / multiplier*/ cost / multiplier * amount).toFixed(2) + " BNB" : amount + " Beans"}</button>
            {amount < 10 && <div><span className="hint-text">Hint: Purchase 10 Chests at the same time for a guaranteed rare item{times10Minted < 1000 && !useTokens && <span><br/>{" and 5 Magic Beans"}</span>}!</span></div>}
            {amount == 10 && times10Minted < 1000 && !useTokens && <div><span className="hint-text">You will receive 5 Magic Beans for this!</span></div>}
            {amount == 10 && <div><span className="rare">GUARANTEED RARE!</span></div>}
          </div>}
          {errorMessage && 
          <div>
            <p>Please wait a little longer for the results</p>
            <p>Click below to attempt to get the results.</p>
            <button onClick={() => getRequestResults()}>Reload Results</button>
            <p className="hint-text">If you have waited for longer than 60 seconds, press "Something went wrong" below.</p>
            <button onClick={() => setErrorMessage(false)}>Go back</button>
          </div>}
          {loadingGacha &&
          <div>
            <p className="merchant-text">~ Alright, let's dust off these old chests and see what I can get you...</p>
            <p className="hint-text">{"("}This should take roughly 20 seconds{")"}</p>
          </div>}
          {displayResults && !errorMessage &&
          <p className="merchant-text">~ There you go! Yes, some very fine items... thanks for the beans, hehe...</p>}
            {lastGacha.length != 0 && displayResults &&
            <div>
              <h3>Items Acquired:</h3>
              {lastGacha.map((minted, index) => {
                      return (
                        <div key={index} className="item-show" onMouseOver={() => {setShowItemMini(index)}} onMouseOut={() => {setShowItemMini(11)}}>
                          <p onClick={() => {_showItemWindow(index)}} className={rarityClasses[lastGachaRarities[index]]}>{relics[minted].relicName}</p>
                            {showItem[index] && <div className="cursor-gacha">
                                <img src={"./items/" + relics[minted].relicName + ".jpg"}/>
                            </div>}
                            {showItemWindow[index] && <BossItem relic={relics[minted]} id={minted} disableBox={() => disableItemWindow()}/>}
                        </div>
                    )
              })}
              {!useTokens && lastGacha.length == 10 && times10Minted < 1000 && 
              <p>You received 5 Magic Beans!</p>}
              <button onClick={() => setDisplayResults(false)}>Ok</button>
            </div>
            }
            <br/><br/>
            <button onClick={() => {const a = !seeAvailable; setSeeAvailable(a)}}>{seeAvailable ? "Hide" : "See"} available items</button>
            {seeAvailable &&
              <div>
                <div className="gacha-img-container">
                  <button onClick={() => advanceImage(false)} >←</button>
                  <div className="gacha-img-box">
                    <img src={"./items/img-only-border/" + allGacha[imgNumber] + ".jpg"}></img>
                    <div>
                      <p>{relics[allGacha[imgNumber]].relicName}</p>
                    </div>
                  </div>
                  <button onClick={() => advanceImage(true)} >→</button>
                </div>
                {gachaCommon.includes((imgNumber+1).toLocaleString()) && <p>Common - 60% chance</p>}
                {gachaUncommon.includes((imgNumber+1).toLocaleString()) && <p>Uncommon - 25% chance</p>}
                {gachaRare.includes((imgNumber+1).toLocaleString()) && <p>Rare - 10% chance</p>}
                {gachaLegendary.includes((imgNumber+1).toLocaleString()) && <p>Legendary - 5% chance</p>}
                <p>{imgNumber + 1} / {allGacha.length}</p>
              </div>
            }<br/><br/>
            <button onClick={openRefund}>Something went wrong?</button>
        </div> :
        <p className="merchant-text">~ Just a minute, friend!</p>
        }
        <div className="gacha-footer">
          <p>Powered by</p> 
          <a target="_blank" href="https://kenshi.io">
          <img src="kenshi-logo.svg"></img>
          </a>
        </div>
        </div>}
        {merchantAction == 2 && <div>
          <p className="merchant-text">~ So, looking for something special huh?<br/>Well, you're going to have to come back later, I don't have anything at the moment.</p>
        </div>}
        {refundWindow && <Refund disableItemWindow={() => disableItemWindow} showItemWindow={showItemWindow} _showItemWindow={() => _showItemWindow()} requestRarities={lastRequestRarities} rarityClasses={rarityClasses} relics={relics} idGacha={idGacha} balance={balance} userInfo={userInfo} inputDisabled={refundInputDisabled} notRefundable={notRefundable} refundMessage={refundMessage} requestRefund={requestRefund} checkRefund={checkRefund} lastRequest={userInfo.lastRequest} closeRefund={() => setRefundWindow(false)}/>}
    </div>
  )

}

export default Gacha;