import { useEffect, useState } from "react";
import "../Gacha.css"
import "../Guilds.css"
import BossItem from "./BossItem";
import GuildItem from "./GuildItem";
import GuildInventory from "./GuildInventory";
import Quest from "./Quest";
import NFTContract from "../contracts/nfts.json";
import GuildsContract from "../contracts/guilds.json";
import MinerContract from "../contracts/minerabi.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";

function Guilds(props) {


    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const guildsContractAddress = props.guildsContract;
    const minerAddress = props.minerAddress;

  const nft = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  )

  const guilds = new web3.eth.Contract(
    GuildsContract,
    guildsContractAddress
  )

  const miner = new web3.eth.Contract(
    MinerContract,
    minerAddress
  )

  const guildHalls = [0,1,2,3,4,5,6,7,8,9,10,11,12,13]

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");

  const [guild, setGuild] = useState(0);
  const [guildInfo, setGuildInfo] = useState([]);
  const [relics, setRelics] = useState([]);
  const [balances, setBalances] = useState([]);
  const [equipped, setEquipped] = useState([]);
  const [bonusesEquipped, setBonusesEquipped] = useState([]);


  const [inputName, setInputName] = useState("");
  const [inputTag, setInputTag] = useState("");
  const [hallSelected, setHall] = useState(0);

  const [addName, setAddName] = useState("");
  const [nameNotFound, setNotFound] = useState(false);
  const [addAddress, setAddAddress] = useState("");

  const [approved, setApproved] = useState(false);

  const [depositWindow, setDepositWindow] = useState(false);
  const [withdrawWindow, setWithdrawWindow] = useState(false);
  const [itemToDeposit, setItemToDeposit] = useState(0);
  const [amountToDeposit, setAmountToDeposit] = useState(1);
  const [itemToWithdraw, setItemToWithdraw] = useState(0);
  const [amountToWithdraw, setAmountToWithdraw] = useState(1);

  const [depositSuccessful, setDepositSuccessful] = useState(false);
  const [withdrawSuccessful, setWithdrawSuccessful] = useState(false);



  async function getInfo() {
    // setLoading(true)
    const _guild = await guilds.methods.getPlayerToGuild(account).call();
    setGuild(_guild);
    const _guildInfo = await guilds.methods.getGuildInfo(_guild).call();
    setGuildInfo(_guildInfo);
    const _balances = await nft.methods.getRelicsBalances(account).call();
    const _equipped = await nft.methods.getRelicsEquipped(account).call();
    const _bonusesEquipped = await nft.methods.getBonusesEquipped(account).call();
    await setBalances(_balances);
    await setEquipped(_equipped)
    await setBonusesEquipped(_bonusesEquipped);
    setApproved(await nft.methods.isApprovedForAll(account, guildsContractAddress).call());
    setRelics(await nft.methods.getRelics().call());
    console.log(_guildInfo);
    setName(await miner.methods.getNicknameToAddress(account).call());
    setLoading(false);
  }

  async function createGuild() {
    try {
      await guilds.methods.createGuild(inputName, inputTag, hallSelected).send({
        from: account,
        gas: 300000
      })
      getInfo();
    } catch (error) {
      console.error(error);
    }
  }

  async function setApproval(bool) {
    try {
      await nft.methods.setApprovalForAll(guildsContractAddress, bool).send({
        from: account,
        gas: 50000
      })
      getInfo();
      } catch (error) {
      console.error(error)
    }
  }


  async function addToGuild() {
    try {
      await guilds.methods.addToGuild(addAddress).send({
        from: account,
        gas: 400000
      })
      getInfo()
    } catch (error) {
      console.log(error)
    }
  }

  async function kickFromGuild(player) {
    try {
      await guilds.methods.kickFromGuild(player).send({
        from:account,
        gas: 300000
      })
      getInfo();
    } catch (error) {
      console.error(error);
    }
  }

  async function leaveGuild() {
    try {
      await guilds.methods.leaveGuild().send({
        from:account,
        gas: 300000
      })
      getInfo();
    } catch (error) {
      console.error(error);
    }
  }

  async function promoteToGuildMaster(player) {
    try {
      await guilds.methods.promoteToGuildMaster(player).send({
        from:account,
        gas: 300000
      })
      getInfo();
    } catch (error) {
      console.error(error);
    }
  }

  async function demoteFromGuildMaster(player) {
    try {
      await guilds.methods.demoteFromGuildMaster(player).send({
        from:account,
        gas: 300000
      })
      getInfo();
    } catch (error) {
      console.error(error);
    }
  }

  async function depositInGuildBank() {
    try {
      await guilds.methods.depositItem(itemToDeposit, amountToDeposit).send({
        from: account,
        gas: 500000
      })
      getInfo();
      setItemToDeposit(0);
      setDepositSuccessful(true);
    } catch (error) {
      console.error(error);
      setDepositSuccessful(false);
    }
  }

  async function withdrawFromGuildBank() {
    try {
      await guilds.methods.withdrawItem(itemToWithdraw, amountToWithdraw).send({
        from: account,
        gas: 500000
      })
      getInfo();
      setItemToWithdraw(0);
    } catch (error) {
      console.error(error);
    }
  }

  function handleChangeName(event) {
    setInputName(event.target.value);
  }

  function handleChangeTag(event) {
    setInputTag(event.target.value);
  }

  function selectDeposit(item) {
    console.log(item);
    setItemToDeposit(item);
    setAmountToDeposit(1);
  }

  function setAmountDeposit(value) {
    setAmountToDeposit(value);
  }

  function selectWithdraw(item) {
    console.log(item);
    setItemToWithdraw(item);
    setAmountToWithdraw(1);
  }

  function setAmountWithdraw(value) {
    setAmountToWithdraw(value);
  }

  function changeAddName(event) {
    setAddName(event.target.value);
  }

  async function searchName() {
    const _address = await miner.methods.getAddressToNickname(addName).call();
    if(_address == "0x0000000000000000000000000000000000000000") {
      setNotFound(true);
    }
    else{
      setAddAddress(_address);
      setNotFound(false)
    }
  }

  async function changeAddAddress(event) {
    setAddAddress(event.target.value);
  }




  useEffect(() => {getInfo()}, [])

  return(
        <div className="gacha-box">
            {!loading && <div className="center-element">
              {guild == 0 &&
              <div>
              <h2>Create a new guild</h2>
                <input onChange={handleChangeName} placeholder="Your Guild Name" value={inputName} maxLength="12"></input>
                <br/><br/>
                <input onChange={handleChangeTag} placeholder="Your Guild Tag" value={inputTag} maxLength="5"></input>
                <p className="hint-text">Your Guild tag will be displayed like this:</p>
                <p className="hint-text"> {name} {"<" + inputTag + ">"} </p>
                <p>Select an image that will be your Guild Hall:</p>
                  <div className="choose-hall">
                      <img className="quest-img" src={"./guilds/" + hallSelected + ".jpg"}></img>
                      <br/>
                      <button disabled={hallSelected == 0} onClick={() => {let _hall = hallSelected; _hall > 0 && setHall(_hall - 1)}}>←</button>
                      <button disabled={hallSelected == 13} onClick={() => {let _hall = hallSelected; _hall < 13 && setHall(_hall + 1)}}>→</button>
                      <br/><br/>
                      <button className="create-guild-button" id={inputName.length == 0 || inputTag.length == 0 ? "create-disabled" : ""} disabled={inputName.length == 0 || inputTag.length == 0} onClick={createGuild}>
                        {inputName.length > 0 && inputTag.length > 0 ? "Create " + inputName + " Guild <" + inputTag + ">" : "Choose a name and tag to create your Guild"}
                      </button>

                  </div>
              </div>}
              {guild != 0 &&
              <div>
                <p>{guildInfo._guild.name} Guild Hall</p>
                <img className="quest-img" src={"./guilds/" + guildInfo._guild.hall + ".jpg"}></img>
                <button onClick={() => setDepositWindow(true)}>Deposit items into the Guild Bank</button>
                <button onClick={() => setWithdrawWindow(true)}>Withdraw items from the Guild Bank</button>

                {depositWindow &&
                <GuildInventory withdrawing="false" approved={approved} approve={() => setApproval(true)} deposit={depositInGuildBank} amount={amountToDeposit} setAmount={setAmountDeposit} selected={itemToDeposit} setSelected={selectDeposit} relics={relics} balances={balances} closeWindow={() => setDepositWindow(false)} />}
                {withdrawWindow &&
                <GuildInventory withdrawing="true" approved="true" setSelected={selectWithdraw} setAmount={setAmountWithdraw} amount={amountToWithdraw} selected={itemToWithdraw} withdraw={withdrawFromGuildBank} relics={relics} balances={guildInfo.bank} closeWindow={() => setWithdrawWindow(false)}/>}
                <div>
                  <h2>{guildInfo._guild.name} Guild Members</h2>
                  {guildInfo.members.map((member, index) => {
                    return(
                      <div>
                        <div className="members-list">
                          <div className="names">
                            <span><span style={member == name ? {textDecoration:"underline", fontSize:"1em"} : {fontSize:"1em"}}>{member}</span> {member == name && "(You)"} {guildInfo._guild.guildMasters.includes(guildInfo._guild.members[index]) && index != 0 && "(Guild Master)"} {index == 0 && "(Guild Leader)"}</span>
                          </div>
                          {index != 0 && guildInfo._guild.guildMasters.includes(account) && <div className="buttons">
                            {!guildInfo._guild.guildMasters.includes(guildInfo._guild.members[index]) && <button onClick={() => promoteToGuildMaster(guildInfo._guild.members[index])}>Promote</button>}
                            {guildInfo._guild.members[0] == account && guildInfo._guild.guildMasters.includes(guildInfo._guild.members[index]) && <button onClick={() => demoteFromGuildMaster(guildInfo._guild.members[index])}>Demote</button>}
                            <button onClick={() => kickFromGuild(guildInfo._guild.members[index])}>Kick</button>
                          </div>}
                        </div>
                        <hr style={{width:"50%"}}/>
                      </div>
                    )
                  })}
                  {<button onClick={leaveGuild}>Leave Guild</button>}
                  {guildInfo._guild.guildMasters.includes(account) && <div>
                    <p>Add player as Guild Member:</p>
                    <input style={{width:"100px"}} placeholder="Player's name" value={addName} onChange={changeAddName}></input>
                    <button disabled={addName.length == 0} onClick={searchName}>Search for this users address</button>
                    <br/>{nameNotFound && <span className="hint-text">Player not found (make sure the letters are properly capitalized)</span>}
                    <br/>
                    <input style={{width:"200px"}} placeholder="address of player to add" value={addAddress} onChange={changeAddAddress}></input>
                    <button disabled={addAddress.length != 42} onClick={addToGuild}>Add to Guild</button>
                    <p className="hint-text">Warning:<br/>All Guild members will be able to take items from the shared Guild Bank.</p>
                  </div>}
                </div>

              </div>}
            </div>}
        </div>
  )
}

  export default Guilds