import Web3 from 'web3';
import "../Miner.css";

function Error(props) {

    const chainId = 56 // BSC
    const chainName = "Binance Smart Chain";
    const provider = props.provider;
    const explorer = "https://bscscan.com/";
    // const provider = "https://rpc-devnet-cardano-evm.c1.milkomeda.com"
    const web3 = new Web3(provider);

    async function changeChain() {
        if (window.ethereum.networkVersion !== chainId) {
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }]
              });
              props.setStart();
            } catch (err) {
                // This error code indicates that the chain has not been added to MetaMask
              if (err.code === -1 || err.code === 4902) {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainName: chainName,
                      chainId: web3.utils.toHex(chainId),
                      nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                      rpcUrls: [provider],
                      blockExplorerUrls: [explorer] 
                    }
                  ]
                });
                await window.ethereum.request({
                  method: 'wallet_switchEthereumChain',
                  params: [{ chainId: web3.utils.toHex(chainId) }]
                });
                props.setStart();              
              }
              else {
                console.log(err)
              }
            }
          }
          else {
            props.setStart();
          }
      
    }


    return(
        <div className="box" style={{height:"auto"}}>
            <div className='center-element'>
                <h1>
                    Please switch to {chainName}.
                </h1>
                <h3>{chainName}:</h3>
                <p>Chain ID: {chainId}</p>
                <p>RPC: {provider}</p>
                <p>Block Explorer: {explorer}</p>
                <p>Symbol: BNB</p>
                <button className="animated-button" onClick={changeChain}>
                <span></span><span></span><span></span><span></span>Switch</button>
            </div>

        </div>
    )
}

export default Error;