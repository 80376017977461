import { useState } from "react";
import "../Inventory.css";

function Item(props) {

    const [selected, setSelected] = useState(false);

    function select() {
        const _sel = selected;
        _sel == true ? setSelected(false) : setSelected(true);
        console.log(selected)
    }

    return(
        <div onClick={() => {props.select()}}>
            {props.balance > 0 && props.bonusFilter.includes(parseInt(props.relic.bonusType)) &&
            <div className={props.equipped ? "item-equipped" : "item-container"} id={props.itemSelected == props.index && "item-selected"}>
                <img src={"./items/img-only-border/" + props.index + ".jpg"} className="item-image"></img>
                <p>{props.relic.relicName}</p>
                <p>{props.balance} owned</p>
                {/* {props.equipped && <p>Equipped</p>} */}
            </div>}
        </div>
    )
}

export default Item