import { useEffect, useState } from "react";
import "../Warning.css"
import "../Gacha.css"
import BossItem from "./BossItem";

function Refund(props) {


    const [id, setId] = useState(props.lastRequest);
    const ids = props.idGacha;

    const rarityClasses = [
        "null",
        "common",
        "uncommon",
        "rare",
        "legendary"
      ]
    

    useEffect(() => {}, [])

    function handleChange(event) {
        setId(event.target.value)
    } 

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <p>Input your request id to check if you are eligible for a refund</p>
                    <p className="hint-text">Note: The number below is from your last request;<br/>Refunds are only allowed once 10 minutes have passed from the original request.</p>
                    <input disabled={props.inputDisabled} value={id} type="number" min="0" placeHolder="request id" onChange={handleChange}></input>
                    <button onClick={() => props.checkRefund(id)}>Check Request</button><br/><br/>
                    {props.refundMesssage != "" && 
                    <p>{props.refundMessage}</p>}
                    {props.idGacha.length > 0 &&
                    <div>
                        <h3>Items Acquired:</h3>
                        {props.idGacha.map((item, index) => {
                            return (
                                <div key={index}>
                                <p onClick={() => {props._showItemWindow(index)}} className={props.rarityClasses[props.requestRarities[index]]}>{props.relics[item].relicName}</p>
                                {props.showItemWindow[index] && <BossItem relic={props.relics[item]} id={item} disableBox={() => props.disableItemWindow()}/>}
                                </div>
                            )
                        })}
                    </div>}
                    <br/>
                    <button disabled={props.notRefundable} onClick={() => props.requestRefund(id)}>Request refund</button>
                    <button onClick={props.closeRefund}>Cancel</button>

                    <p className="hint-text">Balance: {parseFloat(props.balance).toFixed(2)} BNB<br/>{props.userInfo.tokens} Magic Beans</p>
                </div>

            </div>
        </div>
    )
}

export default Refund