import { useEffect } from "react";
import "../Warning.css"

function Party(props) {
    
    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const expMultiplier = 100000000000000;
    const beansMultiplier = 100000000000000;

    function checkTime() {
        if(props.streak + (props.timestamp - props.checkpoint) > 432000 - props.equipped.multipliers[9]) {
            return true;
        }
        else {
            return false;
        }
    }

    function calculateFullPayoutReinvest() {
            var _payout = ((parseInt(props.payout) + parseInt(props.direct_bonus) + parseInt(props.match_bonus) + parseInt(props.pool_bonus)));
            return _payout
        }
    

    useEffect(() => {console.log(props.equipped.equippedRelics);}, [])

    return(
        <div className="black-screen">
            <div className="warn-box">
                <div className="text">
                    <h3>Your Party!</h3>
                    <p>You currently have {}</p>
                    <button onClick={props.disableWarning}>Cancel</button>
                </div>

            </div>
        </div>
    )
}

export default Party