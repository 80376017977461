import { useState } from "react";
import "../Inventory.css";

function GuildItem(props) {

    function select() {
    }

    return(
        <div onClick={() => {props.select()}}>
            {props.balance > 0 && props.bonusFilter.includes(parseInt(props.relic.bonusType)) && 
            <div className="item-container">
                <img src={"./items/img-only-border/" + props.index + ".jpg"} className="item-image"></img>
                <p>{props.relic.relicName}</p>
                {<p>{props.balance} available</p>}
            </div>}
        </div>
    )
}

export default GuildItem