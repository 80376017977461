import "../Ladder.css"
import "../Stats.css";
import { useEffect, useState } from "react";
import LadderContract from "../contracts/Ladder.json";
import MinerContract from "../contracts/minerabi.json";
import NFTContract from "../contracts/nfts.json";
import BossContract from "../contracts/bosses.json";
import GachaContract from "../contracts/gacha.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";
import {CopyToClipboard} from "react-copy-to-clipboard";


function Ladder(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContractAddress = props.nftContract;
    const gachaContractAddress = props.gachaContract;
    const minerContractAddress = props.minerContract;
    const bossContractAddress = props.bossContract;
    const ladderContract = props.ladderAddress;
    const devs = ["0xE8c65e725C69fAEC6cDd841dF667fC14665b66FB", "0xfF8c736524Ee7Fc0a1FE2a353449A5ca902D4380"];
    const multiplier = 1000000000000000000;
    const finneyMultiplier = 1000000000000000
    const statsMultiplier = 100000000000000000;
    const beansMultiplier = 1000000000000;

    const [users, setUsers] = useState([]);
    const [defaultLadder, setDefaultLadder] = useState([]);
    const [sortedBy, setSorted] = useState(3);
    const [loaded, setLoaded] = useState(false);

    const ladder = new web3.eth.Contract(
        LadderContract,
        ladderContract
    )

    const miner = new web3.eth.Contract(    
        MinerContract,
        minerContractAddress
    )
    const boss = new web3.eth.Contract(
        BossContract,
        bossContractAddress
    )

    const nft = new web3.eth.Contract(
        NFTContract,
        nftContractAddress
    )

    const gacha = new web3.eth.Contract(
        GachaContract,
        gachaContractAddress
    )

    const noFlyList = [
        "nigger", "n1gger"
    ]

    async function getLadder() {
        // var totalUsers = await miner.methods.total_users().call();
        var _ladder = await ladder.methods.getLevels(0, 0).call();
        console.log(_ladder);
        setDefaultLadder(_ladder);
        const sliceLadder = _ladder.slice().sort(function(a, b) {
            if(parseFloat(a.stats[3]) > parseFloat(b.stats[3])) return - 1;
            if(parseFloat(a.stats[3]) < parseFloat(b.stats[3])) return 1;
            else return 0;
        })
        console.log(sliceLadder);
        setUsers(sliceLadder)
        // const sorted = _ladder.sort();
        // console.log(sorted);
        setLoaded(true);
    }

    function sortBy(attribute) {
        const _ladder = users;
        const sliceLadder = _ladder.slice().sort(function(a, b) {
            if(parseFloat(a.stats[attribute]) > parseFloat(b.stats[attribute])) return - 1;
            if(parseFloat(a.stats[attribute]) < parseFloat(b.stats[attribute])) return 1;
            else return 0;
        })
        setUsers(sliceLadder)
        setSorted(attribute);
    }

    
    function sortByFinancials(param) {
        const _ladder = users;
        const sliceLadder = _ladder.slice().sort(function(a, b) {
            if(parseFloat(a[param]) > parseFloat(b[param])) return - 1;
            if(parseFloat(a[param]) < parseFloat(b[param])) return 1;
            else return 0;
        })
        setUsers(sliceLadder)
        setSorted(param+1);
    }

    function sortDefault() {
        setUsers(defaultLadder);
        setSorted(4);
    }

    useEffect(() => {getLadder()}, [])

    return (
        <div className="ladder-body">
            <div className="ladder-box">
                <h2>THE LADDER</h2>
                <p>Climb to the top for Special Rewards at the end of each season!</p>
                    {!loaded && <h3>Loading Ladder...</h3>}
                    {loaded && <div>
                    <span>Sort by:</span>
                    <button onClick={() => sortBy(3)}>LVL</button>
                    <button onClick={() => sortBy(0)}>STR</button>
                    <button onClick={() => sortBy(1)}>DEX</button>
                    <button onClick={() => sortBy(2)}>INT</button>
                    {devs.includes(account) && <div style={{display:"inline-block"}}>
                    <button onClick={() => sortByFinancials(3)}>Deposits</button>
                    <button onClick={() => sortByFinancials(4)}>Reinvested</button>
                    </div>
                    }
                    <button onClick={() => sortByFinancials(5)}>Referrals</button>

                    <p>Press any user's name to copy their address to your clipboard.</p>

                    {/* <button onClick={() => sortDefault()}>Entry Order</button> */}
                    <br/>
                    <table>
                    <tbody>
                        <tr>
                            <th onClick={() => sortBy(3)}>Pos.</th>
                            <th>Name</th>
                            <th style={sortedBy == 0 ? {textDecoration:"underline"} : {}} onClick={() => sortBy(0)}>STR</th>
                            <th style={sortedBy == 1 ? {textDecoration:"underline"} : {}} onClick={() => sortBy(1)}>DEX</th>
                            <th style={sortedBy == 2 ? {textDecoration:"underline"} : {}} onClick={() => sortBy(2)}>INT</th>
                            <th style={sortedBy == 3 ? {textDecoration:"underline"} : {}} onClick={() => sortBy(3)}>LVL</th>
                            {devs.includes(account) && <th style={sortedBy == 4 ? {textDecoration:"underline"} : {}} onClick={() => sortByFinancials(3)}>Deposited</th>}
                            {devs.includes(account) && <th style={sortedBy == 5 ? {textDecoration:"underline"} : {}} onClick={() => sortByFinancials(4)}>Reinvested</th>}
                            {devs.includes(account) && <th style={sortedBy == 7 ? {textDecoration:"underline"} : {}} onClick={() => sortByFinancials(6)}>Withdraws</th>}
                            <th style={sortedBy == 6 ? {textDecoration:"underline"} : {}} onClick={() => sortByFinancials(5)}>Referrals</th>
                        </tr>
                        {users.map((user, index) => {
                        return (
                            <tr key={index}>
                                <td>{index+1}</td>
                                {noFlyList.includes(user._name.toLowerCase()) && <CopyToClipboard text={user._addr}><td style={{color:"grey", cursor:"pointer"}}>REDACTED {user._guildTag.length > 0 && "<"+user._guildTag+">"}</td></CopyToClipboard>}
                                {!noFlyList.includes(user._name.toLowerCase()) && <CopyToClipboard text={user._addr}><td style={user._name.length == 0 ? {color:"grey", cursor:"pointer"} : {cursor:"pointer"}}>{user._name.length > 0 ? user._name : "anonymous"} {user._guildTag.length > 0 && "<"+user._guildTag+">"}</td></CopyToClipboard>}
                                <td>{Math.floor(user.stats[0] / statsMultiplier)}</td>
                                <td>{Math.floor(user.stats[1] / statsMultiplier)}</td>
                                <td>{Math.floor(user.stats[2] / statsMultiplier)}</td>
                                <td>{Math.floor(user.stats[3] / statsMultiplier)}</td>
                                {devs.includes(account) && <td>{((user._deposit)/multiplier).toFixed(2)} BNB</td>}
                                {devs.includes(account) && <td>{(user._reinvest/multiplier).toFixed(2)} BNB</td>}
                                {devs.includes(account) && <td>{(user._payouts/multiplier).toFixed(2)} BNB</td>}
                                <td>{user._referrals}</td>
                            </tr>
                        )
                })}
                    </tbody>
                    </table>
                    </div>}
            </div>
        </div>
    )
}

export default Ladder;