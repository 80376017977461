import Item from "./Item.js";
import { useEffect, useState } from "react";
import "../Inventory.css"
import NFTContract from "../contracts/nfts.json";
import Web3 from "web3";
import {useWeb3React, Web3ReactProvider} from '@web3-react/core';
import { injected } from "./wallet/connectors";

function Inventory(props) {

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    const provider = props.provider;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const metadataURL = "https://bafybeiechcudyx6zr7q5v2bl6wajwshqkfklnagdlcrjaukz6clvllnyiy.ipfs.nftstorage.link/";



    const nftContractAddress = props.nftContract;

  const nftContract = new web3.eth.Contract(
    NFTContract,
    nftContractAddress
  );

  const [balances, setBalances] = useState([]);
  const [relics, setRelics] = useState([]);
  const [equipped, setEquipped] = useState([]);
  const [bonusesEquipped, setBonusesEquipped] = useState([]);
  const [inventoryLoaded, setInventoryLoaded] = useState(false);

  const [itemDescription, setItemDescription] = useState("");
  const [itemImage, setImage] = useState("");

  const [itemSelected, setItemSelected] = useState(null);
  const [selected, setSelected] = useState(false);
  const [bonusFilter, setBonusFilter] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);

  const statsMultiplier = 100000000000000000;
                          


  const bonusType = [
    "",
    "Compound bonus when leveling Strength",
    "Compound bonus when leveling Dexterity",
    "Compound bonus when leveling Intelligence",
    "Bonus to Strength gained when levelling up",
    "Bonus to Dexterity gained when levelling up",
    "Bonus to Intelligence gained when levelling up",
    "Higher chance to beat boss",
    "Loss when defeated by a boss",
    "Seconds less to the withdrawal penalty",
    "Immediate gain to your Strength stat",
    "Immediate gain to your Dexterity stat",
    "Immediate gain to your Intelligence stat",
    "to STR when equipped",
    "to DEX when equipped",
    "to INT when equipped",
    "Allow the user to upgrade the 3 attributes simultaneously",
    "Compound bonus when leveling all 3 attributes simultaneously",
    "Seconds less to your recovery time before fighting a boss",
    "% higher chance to find a Magic Bean",
    "Shorten the recovery time to stumble into random events"
  ]

  const bonusTypes = [
    "All", "Compound", "Level up", "Stat Bonus", "Boss Fight", "Time", "Special", "Strength", "Dexterity", "Intelligence", "Quest"
  ]

  
  function changeBonusFilter(bonus) {
    var _filter = [];
    if(bonus == 0) {
      _filter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
    }
    if(bonus == 1) {
      _filter = [1, 2, 3, 17];
    }
    if(bonus == 2) {
      _filter = [4, 5, 6];
    }
    if(bonus == 3) {
      _filter = [10, 11, 12, 13, 14, 15];
    }
    if(bonus == 4) {
      _filter = [7, 8, 18];
    }
    if(bonus == 5) {
      _filter = [9, 18, 20];
    }
    if(bonus == 6) {
      _filter = [16, 19, 20];
    }
    if(bonus == 7) {
      _filter = [1, 4, 10, 13];
    }
    if(bonus == 8) {
      _filter = [2, 5, 11, 14];
    }
    if(bonus == 9) {
      _filter = [3, 6, 12, 15]
    }
    if(bonus == 10) {
      _filter = [0];
    }
    console.log(bonus);
    console.log(_filter)
    setBonusFilter(_filter);
  }

  function url(num) {
    return metadataURL + num + ".json"
  }

  function pullJson(num) {
    fetch(url(num))
    .then(response => response.json())
    .then(responseData => {
      console.log(responseData);
    })
  }

  function pullJsonImage(num) {
    fetch(url(num))
    .then(response => response.json())
    .then(responseData => {
      setImage(responseData.image);
    })
  }

  function pullJsonDescription(num) {
    fetch(url(num))
    .then(response => response.json())
    .then(responseData => {
      setItemDescription(responseData.description);
    })
  }

  async function getItems() {
    setInventoryLoaded(false);
    const _balances = await nftContract.methods.getRelicsBalances(account).call();
    const _relics = await nftContract.methods.getRelics().call();
    const _equipped = await nftContract.methods.getRelicsEquipped(account).call();
    const _bonusesEquipped = await nftContract.methods.getBonusesEquipped(account).call();
    await setRelics(_relics);
    await setBalances(_balances);
    await setEquipped(_equipped)
    await setBonusesEquipped(_bonusesEquipped);
    setInventoryLoaded(true);
  }

  async function handleSelect(item) {
    setItemSelected(item);
    setSelected(true);
    pullJsonDescription(item);
    pullJsonImage(item);
  }


  async function toggleEffect() {
    await nftContract.methods.toggleEffect(itemSelected).send(
      {
        from: account,
        gas: 200000,
      }
    );
    getItems();
  }

    useEffect(() => {getItems()}, []);

    return(
        <div className="inventory-box">
            <p className="inventory-title">INVENTORY</p>
              <div className="inventory-container">
                <div className="items-container">
                  <select className="select-filter" name="Filter" onChange={e=> {changeBonusFilter(e.target.value)}}>
                    {bonusTypes.map((filter, index) => {
                      return(
                        <option key={index} value={index}>{filter}</option>
                      )
                    })}
                  </select>
                  {inventoryLoaded ?
                    relics.map((itemContent, index) => {
                      return (
                        <Item key={index} bonusFilter={bonusFilter} relic={itemContent} balance={balances[index]} equipped={equipped[index]} itemSelected={itemSelected} index={index} select={() => handleSelect(index)}/>
                    )
                  }) : <p>Loading</p>}
                </div>
                <div className="display-item">
                  {selected ?
                  <div>
                  <p>{relics[itemSelected].relicName}</p>
                  <img src={"./items/img-only-border/" + itemSelected + ".jpg"} className="display-image"></img>
                  <p className="description-text">~ {itemDescription}</p>
                  <p>Effect: <br/> 
                  {relics[itemSelected].bonusType == 0 &&
                  <span>Quest Item</span>}
                  {relics[itemSelected].bonusType < 7 && relics[itemSelected].bonusType != 0 &&
                  <span>x{relics[itemSelected].multiplier / 100} {bonusType[relics[itemSelected].bonusType]}</span>
                  }
                  {relics[itemSelected].bonusType == 7 &&
                  <span>+{relics[itemSelected].multiplier}% {bonusType[relics[itemSelected].bonusType]}</span>
                  }     
                  {relics[itemSelected].bonusType == 8 &&
                  <span>Keep {relics[itemSelected].multiplier}% of your compound when defeated by a boss</span>
                  }
                  {relics[itemSelected].bonusType == 9 &&
                  <span>You can withdraw with no penalty {(relics[itemSelected].multiplier/3600).toFixed()} Hours earlier than usual</span>
                  }                            
                  {relics[itemSelected].bonusType > 9 && relics[itemSelected].bonusType < 16 &&
                  <span>+{relics[itemSelected].multiplier / statsMultiplier} {bonusType[relics[itemSelected].bonusType]}</span>
                  }
                  {relics[itemSelected].bonusType == 16 &&
                  <span>{bonusType[relics[itemSelected].bonusType]}</span>}
                  {relics[itemSelected].bonusType == 17 &&
                  <span>x{relics[itemSelected].multiplier / 100} {bonusType[relics[itemSelected].bonusType]}</span>}
                  {relics[itemSelected].bonusType == 18 &&
                  <span>You can fight a boss {(relics[itemSelected].multiplier/3600).toFixed()} Hours earlier than usual</span>
                  }
                  {relics[itemSelected].bonusType == 19 &&
                  <span>+{relics[itemSelected].multiplier}{bonusType[relics[itemSelected].bonusType]}</span>
                  }
                  {relics[itemSelected].bonusType == 20 &&
                  <span>{bonusType[relics[itemSelected].bonusType]} by {(relics[itemSelected].multiplier/3600).toFixed()} Hours</span>
                  }
                  </p>
                  <p>Amount owned: {balances[itemSelected]}</p>
                  <p>{relics[itemSelected].consumable ? "One time use only" : "Permanent Item"}</p>
                  {relics[itemSelected].bonusType < 10 || relics[itemSelected].bonusType > 12 ?
                  <button onClick={toggleEffect}>{equipped[itemSelected] ? "UNEQUIP" : "EQUIP"}</button> :
                  <button disabled={balances[itemSelected] == 0}onClick={toggleEffect}>USE</button>
                  }
                  {bonusesEquipped.bonuses[relics[itemSelected].bonusType] && bonusesEquipped.equippedRelics[relics[itemSelected].bonusType].relicName != relics[itemSelected].relicName &&
                  <p className="hint-text">Note: Equipping this item will unequip {bonusesEquipped.equippedRelics[relics[itemSelected].bonusType].relicName}</p>
                  }
                  </div> :
                  <p>Item selected will be displayed here</p>}                
                </div>
              </div>
              <p>Remember you can find new items by defeating bosses or visiting the Merchant!</p>
          </div>
    )
}

export default Inventory;