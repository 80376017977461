import { useState } from "react";
import "../Inventory.css";

function TradeItem(props) {

    const [selected, setSelected] = useState(false);

    // function select() {
    //     const _sel = selected;
    //     _sel == true ? setSelected(false) : setSelected(true);
    //     console.log(selected)
    // }

    function select() {
        if(!props.equipped) {
            props.select();
        }
    }

    return(
        <div onClick={() => {console.log(props.amount); select()}}>
            {props.balance > 0 && props.bonusFilter.includes(parseInt(props.relic.bonusType)) && props.balance > props.inOffer && props.amount < props.balance && 
            <div className={props.equipped ? "item-equipped" : "item-container"} id={props.equipped && "trade-item-equipped"}>
                <img src={"./items/img-only-border/" + props.index + ".jpg"} className="item-image"></img>
                <p>{props.relic.relicName}</p>
                {!props.equipped && <p>{props.balance} owned</p>}
                {props.equipped && <p style={{color: "red", textShadow:"0 0 5px black"}}>EQUIPPED</p>}
                {/* {props.equipped && <p>Equipped</p>} */}
            </div>}
        </div>
    )
}

export default TradeItem