import GuildItem from "./GuildItem";
import "../Inventory.css";
import "../Warning.css";
import { useState } from "react";

function GuildInventory(props) {

    const bonusTypes = [
        "All", "Compound", "Level up", "Stat Bonus", "Boss Fight", "Time", "Special", "Strength", "Dexterity", "Intelligence", "Quest"
      ]
    
    const [bonusFilter, setBonusFilter] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]);


    function changeBonusFilter(bonus) {
        var _filter = [];
        if(bonus == 0) {
          _filter = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        }
        if(bonus == 1) {
          _filter = [1, 2, 3, 17];
        }
        if(bonus == 2) {
          _filter = [4, 5, 6];
        }
        if(bonus == 3) {
          _filter = [10, 11, 12, 13, 14, 15];
        }
        if(bonus == 4) {
          _filter = [7, 8, 18];
        }
        if(bonus == 5) {
          _filter = [9, 18, 20];
        }
        if(bonus == 6) {
          _filter = [16, 19, 20];
        }
        if(bonus == 7) {
          _filter = [1, 4, 10, 13];
        }
        if(bonus == 8) {
          _filter = [2, 5, 11, 14];
        }
        if(bonus == 9) {
          _filter = [3, 6, 12, 15]
        }
        if(bonus == 10) {
          _filter = [0];
        }
        console.log(bonus);
        console.log(_filter)
        setBonusFilter(_filter);
      }

    function selectItem(item) {
        props.setSelected(item);
    }

    function handleChangeAmount(event) {
        props.setAmount(event.target.value);
    }

    return (
        <div className="black-screen">
            <div className="create-trade-box">
                {!props.approved && <div>
                    <p>You must approve the Guilds Contract<br/>for trading before you can deposit items in the Guild Bank</p>
                    <button onClick={props.approve}>Approve for trading</button>
                </div>}
                <div className="trade-items-container">
                    <select className="select-filter" name="Filter" onChange={e=> {changeBonusFilter(e.target.value)}}>
                        {bonusTypes.map((filter, index) => {
                            return(
                                <option key={index} value={index}>{filter}</option>
                            )
                        })
                    }
                    </select>
                    {props.relics.map((itemContent, index) => {
                        return (
                            <GuildItem select={() => selectItem(index)} key={index} bonusFilter={bonusFilter} relic={itemContent} balance={props.balances[index]} index={index}/>
                        )
                    })}
                </div>
                {props.selected > 0 && props.withdrawing=="false" &&
                <div>
                    <span>{props.relics[props.selected].relicName} x</span>
                    <input type="number" value={props.amount} onChange={handleChangeAmount} max={props.balances[props.selected]} min="1"></input>
                    <p onClick={props.deposit}><button>Deposit</button></p>
                </div>}

                {props.selected > 0 && props.withdrawing=="true" &&
                <div>
                    <span>{props.relics[props.selected].relicName} x</span>
                    <input type="number" value={props.amount} onChange={handleChangeAmount} max={props.balances[props.selected]} min="1"></input>
                    <p onClick={props.withdraw}><button>Withdraw</button></p>
                </div>}
                <button onClick={props.closeWindow}>Close</button>

            </div>
        </div>
        )
}

export default GuildInventory