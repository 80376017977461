import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ConnectWallet from "./Components/ConnectWallet";
import reportWebVitals from './reportWebVitals';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

function getLibrary(provider) {
  return new Web3(provider);
}
// const chainId = 97;
const chainId = 56;

const _provider = "https://bsc-dataseed.binance.org";
// const _provider = "https://data-seed-prebsc-1-s3.binance.org:8545/";
// 
// https://data-seed-prebsc-1-s3.binance.org:8545/

root.render(
  <Web3ReactProvider getLibrary={() => getLibrary(_provider)}>
  <BrowserRouter>
  <Routes>
  <Route path="/:referral" element={<App chainId={chainId} provider={_provider}/>}>
  </Route>
  </Routes>
  </BrowserRouter>
  </Web3ReactProvider>

    

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
