import { useEffect, useState } from "react";
import "../Warning.css"

function Quest(props) {

    const questNames = [
        "Epic Quest of Knowledge",
        "Epic Quest of Might",
        "Epic Quest of Skill"
      ]
    
      const questStepsText = [
        [ // EPIC QUEST OF KNOWLEDGE
          "You run your hands through the purple dust,\nit feels like a soft sand.\n"+
          "As you move it and play with it,\nit sparkles with an other worldly luminescence.\n"+
          "There’s nothing like it you’ve ever seen.\nEven the Grand Wizards cannot conjure such an effect,\n"+
          "or a light as similar as when the space dust glows.\n\nWhile traveling at night in the Grand Plains you noticed it glowing.\n"+
          "It was unmistakable as you’ve seen some before at the bazaar and other places.\nThere was quite a lot of it there.\n"+
          "No one knows quite how it’s formed or really what it is exactly\nbut some have claimed to see it from the sky.\n\n"+
          "The Temple claims it to be a divine substance,\nproof of the sacred and the Grand Wizards claim\nit's a miracle of magic and science.\n\n"+
          "Either way, there is something compelling about it,\nand you’ve decided to see if anyone knows anything about it in Dolron Bazaar. "+
          "Surely there would be someone with some information… \n\n"+
          "~ “Ahhh Yes, Space Dust.\nFor something so precious there sure seems to be a lot of it.\n "+
          "Granted we still sell it for a good price but any adventurer could find some if they tried.\n"+
          "What do you say I’ll take it off your hands for 30 gold pieces?\nI don’t sell it for much more myself…”\n\n"+
          "The old merchant pulled some golden coins out from under the table. "+
          "You had gathered quite a lot, and it was taking up some space so you decide to sell him your space dust, but keep a small bag yourself. "+
          "\n\n“Do you know anything else about this stuff?”\nThere was something curious about it to you still, something compelling…"+
          "\n\n“Go talk to the Wizard’s about it, they’re always coming in to buy it. One of them said they use it for one spell or another, I can’t remember…”",

          "The Grand Wizards’ tower stood before you.\n\nAn impressive, if not intimidating structure. "+
          "The base was not very big and you could probably walk around it in a few minutes time, "+
          "but it seemed to go straight into space.\n\n Even stranger was the inside:\n "+
          "Teleportation pads and areas far too large to exist inside the structure.\n "+
          "Not to mention windows into unknown lands... unknown to outsiders...\n "+
          "You must be a senior member of the Caster’s Guild to access most of the tower.\n\n"+
          "You enter into a spacious well lit room, loud with activity. A man wearing a fine red robe addresses you politely.\n\n"+
          "“Hello sir, how may we help you here?”\n\n"+
          "You explain your quest to learn more about the Space Dust, how Veezra at the bazaar said that the wizards buy it from time to time.\n\n"+
          "“Indeed, we do know about Space Dust, although all it has to offer is still a mystery to us. "+
          "We have a few volumes on it, but they always seem to be checked out…”\n\n"+
          "Just then another man who was walking by catches your attention.\n"+
          "He approaches you without hesitation, with his eyes fixed upon the mysterious purple dust:\n\n “Ah yes! There it is!\n"+
          "I was just reading about it too!\nThe wizards have been able to conjure meteors from it, "+
          "although only the highest ranking seem to have the knowledge... or the permission to do so.\n"+
          "The elves have been attempting to use it in their crafting they say.\nThere is so much about it in this book.”\n\n"+
          "You notice him holding a book the same color as the space dust itself.\n\n"+
          "“I'm still going through it you know...\nWait is that a Griffon Feather I see in your pack??\n"+
          "You know I’ve been meaning to make a new pen from one of those.\n"+
          "What say you we trade, I’ll return the book and let you rent it from the tower’s library and you give me that pen.” \n\n"+
          "You shake his hand \n\n“It’s a deal.”",

          "After staying up nearly all night reading “Space Dust Vol 2 – Enchanter’s Edition,” "+
          "you feel about ready to put down the book, not having found anything even slightly helpful...\n\n " +
          "Still, out of sheer force of will, you decide to turn over to the next page,\n"+
          "hoping that it's not yet another 'Magical Purple Soup Recipe'...\n\n As you start reading,\nyour sixth sense is telling you there's something special about this one. \n\n"+
          "The story in the book said an Arch Wizard of the Elven kingdom appeared in the Grand Chambers.\n"+
          "An incredible feat as you would need an exact memory of the location to teleport in, "+
          "and somehow make it through the spell gates placed by all the human wizards.\n\n"+
          "He said: \n\n~“There is much you do not know.\nYou must seek this knowledge,\nor else you will forever be asleep.”\n\n"+
          "His robe was described as being an incredible kind of blue,\nas if one was looking into the deep ocean,\n"+
          "glowing golden with a purple luminescence, similar to the Space Dust.\n\n"+
          "You decide this warranted a visit to the Elves so you could verify the story's claims.\n\n"+
          "Even if you managed to get an audience with the Grand Wizards,\n there was no guarantee they would know... \n\n"+
          "Having found a wizard willing to teleport you to the Fer’Dal Runes,\n you would be able to find your way to the Elven capital city of Fer’Dal from there. \n\n"+
          "If the story was true surely there would be elven wizards who knew about the robe\n"+
          "or perhaps some Elven craftsmen who would know about how to use the dust as a crafting ingredient.\n\n"+
          "“Well, are you ready to go. Have you ever done this before?”\n\nYou nod your head but you’re not exactly used to it.\n"+
          "Losing consciousness not under your own will is always a bit of a scary experience, will you ever wake up?\n\n"+
          "“OK, did you bring the Potion of Thought we will need?\nGood, now try to relax, it will be done before you know it…”",

          "You come back to consciousness in a large grassy field,\nleaning up against a large carved column like stone.\n"+
          "The wizard places her hand upon your shoulder.\n\n“We made it sir. Have a nice trip.”\n\n"+
          "Just as you’re blinking your eyes she begins to fade and disappears in front of you…\n\n"+
          "You turn around and see the grand city of Fer’Dal.\nIt’s absolutely massive and brilliantly white.\n"+
          "You thought you might have been out of place but there was a large amount of humans walking about,\n"+
          "enough that no one seemed to give your presence any thought.\n"+
          "You even see a few Dark Elves around!\n\nThe first place you decide to go is the Wizard’s guild.\nThey must know something…"+
          "“Yes the robe and the man exist.\nAnd although he is Elven he isn’t one of us.\n"+
          "He did the same little trick in our High Chamber as well.\nThe robe was as your book claims...\n"+
          "He was glowing like the dust.”\n\nThe Elf turned and looked away from you.\n"+
          "He was clearly an adult but his skin was still youthful,\n"+
          "he could have been 50 or 100 years old, you still hadn’t been around enough elves to tell\n"+
          "\n“We do have the ability to imbue our silk with the dust,\n"+
          "but none of our tailors have been able to make what he was wearing.\n"+
          "No one has a clue yet how to create any garment like that.\n"+
          "Our Temple claims he is fulfilling some kind of prophecy,\n"+
          "that he is bestowed with knowledge beyond even what we wizards know.\n"+
          "Surely someone who could access both the Grand Council and our High Chamber knows something we don’t…\n\n"+
          "Well, I’d be happy to create some of this silk for you if you had the materials…”",

          "The Elven Temple stood simple,\nan unadorned white building settled into a beautiful forest.\n\n"+
          "Fer’Dal had many trees, it was like a city inside of a forest\nas modern as Dolron yet still somehow in and of nature.\n\n"+
          "The Temple existed in many forms,\nand the symbols seemed to exist from an ancient time.\n"+
          "There never was a time people remember or in recorded history\nthat the Temple was not a peaceful benevolent institution.\n"+
          "Many times cities are raided but The Temples will be found empty and they have never participated in the violence of strife of the ages. "+
          "\n\nEven the Kingdom of Shadow has Temple institutions,\nalthough we’re not sure if the Ogres really understand the message or not...\n"+
          "I’m not sure most of the Temple members quite understand the true teachings,\nbut they all have devoted their lives to the cause.\n"+
          "Most of their time is spent in quiet meditation\nand the simple executions of daily life, enough to survive.\n\n"+
          "As you enter the hall there is an old Elven man\nin plain clothes on an elevated stage.\n"+
          "You’re sure this one is old as his hair is completely grey.\nHe stares at you the entire time.\nSeemingly without blinking.\n"+
          "You hear in your head:\n\n“Please Sit.”\n\nAnd you startle as he nods to you.\n\n"+
          "You sit on an empty pad just to your right,\nyour gear clanking onto the ground.\nHe continues to look into your eyes.\n\n"+
          "“I know why you have come.\nYou are seeking the knowledge that we possess.\nThere is more to this life than as you know it.\n"+
          "The map you have in your pack.\nIt is of the Pre-Sand Golong desert.\nYes it existed before the sands we have long known.\n"+
          "Now that you know this, your map will make sense to you.\nHead to this area for the divine blessing.\nUse your intuition as you have been using so far.\n"+
          "We are guiding you…”\n\nThe man shut his eyes.\nYou look around to see if anyone else heard anything, or noticed.\n"+
          "It doesn’t feel right to disturb the silence presently in the temple.\n\nYou stand up and walk out…",

          "You cover your face with your arm\nand pull your cloak tighter around your head.\n\n"+
          "This sand storm was a little nastier than the others you had experienced.\n"+
          "The cart you’re riding in clamours on towards the Golong flats.\n"+
          "You surmise the map is leading to some kind of large building in what seems to be the area of the flats.\n\n"+
          "There is a small village in the area.\nYou get out of the cart as you pull up to the village and toss the driver a gold coin.\n"+
          "You look around in search of a pub or tavern to quench your incredible thirst.\nSomething to eat would be nice too…\n\n"+
          "You see a sign up ahead indicating a rest area.\nAs you enter the small hut you are the only patron.\n"+
          "You notice the small young bearded man sitting behind a small sunken bar.\n\n“Ahh Welcome yes.\nPlease, have some water…\n"+
          "What brings you out here to the flats?\nWe never see you humans or even many elves anymore.”\n\nYou shake your head at him\n"+
          "“I don’t know how I ended up out here really,\nI was just trying to figure out what was going on with this dust.\n"+
          "Now I’m in search of a robe or something else, I don’t even know anymore…”\n\n"+
          "“Well have some of this bread and water first you seem tired...\n"+
          "You said a robe?\nWe do have a local tailor here maybe he knows what you’re looking for…”\n\n"+
          "The tailor’s shop was plain,\nwith a few large white robe-like garments in various sizes\nhanging from racks in the corner.\n\n"+
          "“Yes Yes, come in. I have been waiting for you...\nPlease, Let me get started right away.\n"+
          "Hand me the Elven Silk so I can get working.\nGo find the pattern, it is in the old ruins, please hurry there is not much time…”\n\n"+
          "Sir, Where are the Ruins? How do I find them?\n\n“Follow the map, now please go I must get to work…”",

          "You begin walking west in the desert with a full pack of provisions and plenty of water.\n"+
          "According to the map it can’t be that far away,\nmaybe this was idiotic to come out here and do this…\n"+
          "How did he know about you?\nWhy isn’t anyone else here looking?\n\n"+
          "You see what seems like the sun shining even brighter in a certain area.\nYou decide to walk that direction.\n\n"+
          "You heart jumps and adrenaline rushes through your body\nas you see a glowing piece of parchment floating in mid air.\n"+
          "There is no one around,\ncertainly no wizard casting any spells.\n"+
          "The paper is just floating there shining in light.\n\n"+
          "Suddenly, a voice whispers through your ears...\n\n~ Take it...\n\n"+
          "You hold your hand out and grab the paper.\nYou see the outline of a robe and what seems to be design patterns.\n"+
          "The language is completely foreign to you.\nThe light goes away and even though it was mid day all of a sudden it seems to now be twilight.\n\n"+
          "You better get back before you get caught out in the dark.\nWho knows what kind of night spawn exist out here.\n\n"+
          "You walk into the tailor’s shop,\nsurprised it’s still open.\n\n"+
          "“You found it!\nA Miracle Indeed!\nGo to the east side of the village to the Crazy Camel Inn.\n"+
          "Sleep there and come back here in the morning!”\n\nYou hand over the piece of parchment without thinking much about it,\n\n"+
          "it never crosses your mind to bring it anywhere else.\n\nAs you lay awake in your bed you think about what happened today and what it all meant.\n"+
          "Why would you just give him the parchment like that?\n\nYour thoughts begin to fade as you slowly drift off to sleep",

          "You awake in the morning far later than you usually sleep.\n\n"+
          "You waste no time getting ready and head straight to the tailor’s.\n\n"+
          "“Ahh there you are!\nLook, it is finished!\nIt is for you.\nYou are ready to wear it now…”\n\n"+
          "He hands you a small package\nwrapped carefully in leather with a leather drawstring.\n\n"+
          "“Now go!\nGo back to the Inn, \nopen the package there,\ndon’t do it here.”\n\nYou are flooded with emotions\ntaking the package from the old tailor.\n"+
          "His eyes have a firey glow to them\nas he looks you deep in your eyes.\n\n“Go now! You’re ready.”\n\nYou walk in a daze back to the Crazy Camel Inn.\n\n"+
          "You don’t even look inside the package on the way.\n\n"+
          "You carefully untie the drawstring and see a deep blue color,\nit looks strange to you,\nalmost like the material has depth to it.\n"+
          "\nA strange feeling washes over you as you touch it.\nYou put it back in the package and decide again to go see the tailor.\n\n"+
          "Why had he hurried you out so quickly?\nWhen you make it back to that side of town, you can’t seem to find his shop.\n"+
          "You are sure it was right there.\nNext to the yellow building.\nWhere the tailor’s shop was,\nis nothing but a flat open parcel of sand.\n\n"+
          "You decide to visit that first resting place when you entered town.\nWhen you enter there is an older woman.\n\n"+
          "“What young bearded man?\nNo one like that works here.”\n\nYour head is spinning as you step out of the pub.\n"+
          "You pull out the package in your bag and open it.\n"+
          "You grasp the robe with both hands and pull it out of the bag.\n"+
          "It subtly glows golden and purple\nas the winds whip through the empty village streets.\n"+
          "You can’t resist now to unfold it and put it on.\nAs you slide one arm in and drape it around your body\nyou begin to hear a humming noise.\n\n"+
          "It gets louder and louder.\n\n~ You are ready now…\nexperience life as it really is…\n"+
          "\nYou black out into an infinitely expansive void\nand feel an incredible presence of love and safety.\n\n"+
          "You no longer feel your body and your vision begins\nto split as you experience the past\nand the probable future at once…"
        ],
        // EPIC QUEST OF MIGHT
        ["The Blacksmith’s shop in Dolron is hot and dark.\n"+
        "The smell of ashe and metal pervade the air.\n"+
        "You were tasked by your guild master\n"+
        "to deliver some CROAL to the head blacksmith\n"+
        "and it’s the last task of the day.\n\n"+
        "“Ahhh there you are.\n"+
        "I’ve been waiting all day for this.\n"+
        "We need some CROAL to help make blades\nfor the fight against Lord Nefagan.\n"+
        "Hurry up now.”\n\n"+
        "You hand over your CROAL\n"+
        "and you see a few larger swords hanging on the wall.\n\n"+
        "“Those are for the real warriors.\nSome of my finest work.\nMaybe one day you’ll be able to wield one yourself.\nSay, I have another task for you if you’re willing…”",
        
        "The blacksmith’s hammer clanks as he is pounding out a sword.\n"+
        "The metal hisses as he dips the sword\ninto a bath of water and steam rises from the blade.\n\n"+
        "“I need some tufts of fur from the direwolves\njust outside the city gate.\n"+
        "They have been overrunning the local fauna\nand we need to trim the population anyway.\nThink you can take on a direwolf?”\n\n"+
        "You nod your head and set out at dusk\nto see if you can catch yourself a wolf or two."+
        "Upon entering the forest you hear their howls,\nit sounds like there are hundreds of them.\n"+
        "Your heart is beating fast,\nwhat have you gotten yourself into now?\n"+
        "Just then you see a large direwolf all on his own.\n\n"+
        "Perfect!\nHere’s your chance!\n\n"+
        "You draw your bow and release.\nAn arrow lodges into the chest cavity of the wolf\nand you hear it whimper.\n"+
        "It turns and sees you and begins to charge.\nYou dodge it’s first attack\nand it begins to move slower and slower\nfrom it’s injury.\n"+
        "You see an opening and plunge your sword into it’s chest.\nIt dies with a whimper.\n\n"+
        "Nightspawn will be coming sooner than later,\n"+
        "so you drag the corpse and skin the pelt\non the outskirts of the city gate.\n\n"+
        "This should be plenty for the Blacksmith."+
        "You return and hand over the tufts of fur.\n\n"+
        "Ahh, this is just what I was looking for!\nPerfect.\nMaybe you are indeed ready to wield a more powerful blade.\nCome back another time\nand we can talk some more...",

        "You wake up the next day\nand can’t stop thinking about\nthose blades you saw on the wall.\n"+
        "Maybe you could be strong enough to wield one.\n\nThen you wonder what the most powerful blade ever made was,\nthat’s what you want.\n"+
        "You want the strongest and the best,\nmaybe the old Blacksmith has a clue,\nyou decide to return to his shop sooner than later.\n\n"+
        "“Ha Ha Ha!\nMesmerized by the steel, eh boy?\nYes, it is quite compelling, isn’t it?\nI asked about you down at the guild hall,\nthey said you were quite the fighter.\n"+
        "As for the most powerful sword in the world?\nI’ve heard of one yes,\nQuellon’s blade.\nI’m not sure how it was made,\nbut I do know it needs some exotic material\n"+
        "that I don’t have\nand will never be able to get.\n\nI’m getting too old,\nI’m not sure I’d be able to even craft it myself anymore.\n\n"+
        "There is one thing you could do to help me.\nThe Fairies out in Fer’Dal forest\nhave some kind of concoction\nthat gives you immense strength.\n"+
        "It doesn’t last forever\nbut I can use a little at a time.\nCan you bring me some of this concoction?\n\n"+
        "You head out into the forest during the day\nand find the pathway to the fairy encampment.\nNo one usually bothers them,\n"+
        "as they are usually tricksters\nand can cause quite a lot of trouble for you if they want.\nNot many humans have seen their city no less,\n"+
        "usually interaction with the Fairies\nis very short.\n\n"+
        "“Yes we can brew you a potion of power of course!\n"+
        "But you will need to give us something for it...\n\nOOOO IS THAT SPACE DUST???\nYes!\nWe will take some of that!!”\n\n"+
        "Seems like a fair trade to you,\nso you give the fairies some space dust.\nIn return, they give you a vial\nwith a strange red liquid in it.",

        "You arrive back to the blacksmith’s shop\nand show him the vial of glowing red liquid.\n\n“Yes that’s it!\nThat’s just what I need!\n"+
        "Maybe I will be able to make a blade for you…\n\nYou know, the first thing I’ll need are some dragon scales.\n"+
        "Every legendary blade uses scales in their forging process.\nWe haven’t found any material stronger than that.\nWhere to find them, I haven’t a clue,\nmaybe ask down at the bazaar.\n"+
        "Please give me that potion,\nI’m thirsty now.”\n\nYou hand over the glowing red liquid\nand the blacksmith takes a large gulp.",

        "“Yes we sell dragonscales,\nwe have quite a few in stock.\n"+
        "There is far more supply than ever,\nsince Lord Nefagan went on that rampage\nand destroyed the northern section of Dolron.\n\n"+
        "Price is too high you say??\nWell you’re always welcome to go slay a dragon\nand get some yourself.\n\nHA HA!”\n\n"+
        "Slaying a dragon is out of the question,\nbut maybe you could find some scales yourself\nin the ruins of Northern Dolron…\n\n"+
        "You walk through the makeshift wooden barrier\nset up as a makeshift wall after the destruction.\n\n"+
        "The destruction is just incredible.\nSo many buildings toppled,\nstone everywhere,\nmelted stone from dragonfire is smooth\nand glistening from the mid day sun.\n\n"+
        "Quite the battle took place that day,\nthere has to be some scales around somewhere.\n\nAfter a few hours digging around,\nlifting stones,\nand moving the remains of old buildings,\n"+
        "you spot something small and bright red in the rubble.\n"+
        "It looks like a ruby gem at first\nbut as you get closer,\nyou see it is indeed a dragon scale!\nYou put it in your pouch\nand with renewed vigor begin lifting more stones.\n\n"+
        "After a few hours\nyou find a couple more scales,\nhopefully this is enough to do something with…\n\n"+
        "“Yes!\nThis will be plenty.\nLook at these beauties.\nSome fine scales you found.\n\nI will put them aside.\nWe’ll need something as hot as dragon fire as well.\n"+
        "I’ll also need to find out about a recipe.\nI’ll do some asking around for you...\n\nUntil then think of how we might be able to get something as hot as dragon fire….”",

        "Back in the guild hall you begin asking around:\n\n“So what’s as hot as dragon fire?”\n\nA few laugh at you.\n\n"+
        "“Absolutely nothing!\nAre you nuts?\nThe only things that come close\nare liquid magma and perhaps\nblood of the fire giants.”\n\n"+
        "Fire giants eh?\n\n"+
        "“Well who wants to take down a fire giant with me?”\n\n"+
        "A cloaked rogue speaks up\n\n“I will come,\nI’ve been meaning to get my hands\non some treasure from their lair…”\n\n"+
        "A tall woman with pale skin\nand long dark hair\nwearing a black robe chimes in\n\n“I need some of that blood myself for my potion\nand spell casting research.\nI will come.”\n\n"+
        "A beautiful blonde woman\nwearing silver armor\nwith golden ornaments on the front speaks up\n\n“I will lend you my healing magic.\nThis will be fun!”\n\n"+
        "Lastly a large barbarian stands up.\n\n“You will need a true warrior\nto beat these giants.\nI have slain a few myself.\nI shall come with you.”\n\n"+
        "This is quite the party you’ve assembled.\n\n“There’s only one thing missing.”\n\nThe dark enchantress speaks up\n\n“So who has a map to get to their lair?”",

        "You take a look at the map again,\nthis must be the right place,\nthe ceilings are huge,\nthe stone carvings and architecture\nare what you would expect from fire giants,\n"+
        "even though you’ve never seen one before.\n\nThis is the entrance to a fire giant encampment,\ndeep inside the active volcano of Legarr.\n\n"+
        "You then come upon two giant wooden doors.\nAfter opening them you see two large humanoid creatures,\nfire giants.\n"+
        "\nThe air is intensely hot,\nand it’s quite difficult to breathe.\nThere’s just not enough room to separate them,\nyou’ll have to fight them both at the same time.\n\n“What’s the plan?”\n\nYou say."+
        "The Dark Enchantress says\n\n“I can mind control one for a short time,\nmaybe a minute or so,\nwe’ll have to take down the other one\nfast before he calls for help\nor does any damage to us.\n"+
        "Then we can all work together\nto finish off the other,\nonce my spell wears off.”\n\n"+
        "“That sounds like the best idea to me.”\n\n"+
        "“I will start it,\nonce you see the first giant drop to his knees,\nthat’s when you should strike…\nReady?”\n\n"+
        "Everyone nods and you see the woman’s eyes go dark,\nshe begins moving her hands\nand mumbling something under her breath,\nher head nods downwards as if she fell asleep,\n"+
        "just then you see one of the giants\nfall to his knees.\n\n"+
        "The other giant looks at him confused.\n\n“Charge!”\n\nyou yell.\n"+
        "The giant who is awake sees you\nand begins to yell and run towards you.\n"+
        "His large steps echoing throughout the chamber.\nThe Barbarian prepares his axe and swings it,\n"+
        "just missing the giant’s legs.\nYou are able to land a few cuts to the giant\n"+
        "with your sword and the rogue rolls past to get behind him\nand stabs the giant in the calf.\n\n"+
        "The giant kicks behind him and knocks the rogue down.\n"+
        "He grabs the Barbarian and lifts him up,\nbut the Barbarian is landingblow after blow with his axe.\n\n"+
        "The red glowing blood starts flowing\nand hissing as it touches the ground.\n\nThe healer begins casting a spell\nand blue particles surround the rogue\nas he gets up off the ground.\n"+
        "You land a few more plunges of your blade into the same leg\nuntil finally the giant falls with a loud crash on the ground.\n\n"+
        "Everyone takes a few more strikes\nuntil it is clear the giant is dead.\nThe other giant remains motionless still on his knees.\n\n"+
        "Just then the Dark Enchantress wakes up with a gasp\nand the other fire giant comes back to his senses.\n\n"+
        "“What have you done?!!!”\n\n"+
        "He shouts.\nHe begins to run away to get help\nbut is still drowsy and trips over himself.\n\n"+
        "“Now’s our chance!”\n\nYou shout.\n"+
        "You, the barbarian, and the rogue\n"+
        "all pounce on top of the giant’s back\nand make quick work of him.\n\n"+
        "The scalding hot blood oozing onto the floor\nas the large corpse lays motionless.\n\nYou take out 3 large stone jugs\nand begin collecting the blood.\n"+~
        "You fill up as much as you can.\n"+
        "The Rogue begins poking around for some treasure.\n\n"+
        "“Well, I’ve got what I’ve come for,\nI’m ready to return to Dolron.”\n\nThe cleric looks worried\n\n“Yes this was a little more than I signed up for I guess,\nI’d like to get home too.”\n\n"+
        "Everyone agrees to leave\nand begins walking out of the chamber\nthe same way you came in.\n\n"+
        "The rogue speaks up and says:\n\n“I think I’ll stick around a little bit longer,\ndon’t worry about me,\nI remember the way out.”\n\nHe darts off deeper into the dungeon…"+
        "Back at the blacksmith\nhe is amazed at the heat coming from the jugs of blood.\n\n"+
        "“This stuff is incredible.\nYou really killed giants for this?\nMaybe you really are ready to wield a legendary blade.\n\nThe only thing we’re missing are the forging instructions.\n"+
        "Quellon must have recorded his process somewhere…",

        "If any place would have historical\nrecorded information\nit’s the library in the Grand Wizard’s tower of Dolron.\n\n"+
        "You decide to head there\nto see what you can dig up.\n\nYou get the attention of the attendant\nat the front desk\n\n"+
        "“Quellon?\nAhh yes, Quellon.\nHe was quite the warrior.\nHe wrote a few books yes.\nWhat you’re looking for is the Tome of the Dragons.\nI’m sure we have a copy here somewhere,\n"+
        "but that is an ancient book\nand we don’t keep the same kind of records for those.\nYou’ll have to find it yourself in the great archives below…"+
        "As you descend the stairs\nyou come into a massive library full of tomes,\nscrolls, and books from ceiling to wall.\n"+
        "And this is just the first floor of the archives.\nFinding the book will be like finding\na needle in a haystack!\n\n"+
        "After hours of looking,\nyou don’t have any luck.\nYou decide to come back in the morning and try again.\nAnother day,\nno luck again,\nyou’ve barely made it through half of the first floor of the archives…\n\n"+
        "Another day of looking for the book…\nNo luck.\nYou start to think you’re wasting your time,\nmaybe it doesn’t even exist,\nand how would you even find it.\n\n"+
        "No!\nYou won’t listen to the self doubt\nand you come again for a fourth day.\nYou will find that book!\nYou’ve already gone through enough trouble.\nYou killed a fire giant for god’s sake,\nhow hard is it just looking for a book…\n\n"+
        "You have no clue how the wizard’s do it all day.\nYou resolve yourself to find the Tome of the Dragons\nno matter how long it takes."+
        "On the morning of the 8th day,\nyou run your hand along a brown tome\nand see the words:\n\nTome of the Dragons by Quellon Artornon.\n\nYou found it!!\n"+
        "Your heart races with excitement.\nYou sit down in that very spot\nand begin to read.\nThere are tales of slaying dragons,\nof excitement and wonder,\n"+
        "of a lost time period with an ancient war\n between men and dragonkin.\nThen an extensive section on weaponry.\n\n"+
        "Instructions to forging an ancient blade\ncapable of piercing dragonscales and cutting through any kind of material.\nThis is it!"+
        "You rush to the blacksmith.\n\n“Is that really the Tome of the Dragons?\nI – I can’t believe it…\nWe can really make the ancient blade.\n"+
        "I have everything I need…\nCome back tonight.\nI will have your weapon.”\n\nThe blacksmith takes a swig of the red potion,\nopens the book and wanders into the back of his forge…",

        "You return in the evening to the old blacksmith’s shop.\n\nHe is holding a massive sword,\ntwo times larger than any you’ve seen before.\n\n“I’ve done it!”\n\n"+
        "He says.\n\n“You’ve made this blacksmith's dream come true.\nI never thought I would be able to forge\na legendary blade like this one.\nBut here it is, right here before us.”\n\n"+
        "The ancient runes and inscriptions on it\nglow a subtle green color.\nThe edge seems to collapse into infinity,\nyou can tell it’s incredibly sharp.\n\n"+
        "“I had to gather a few more things on my own,\nand we even needed a wizard involved,\nbut that’s not for you to worry about…\n\n"+
        "Here’s your blade, sir.\nThis, I’m sure can kill a dragon.\n"+
        "You don’t mind I hold onto this book\na bit longer do you?\nI’ll return it myself when I’m done…”\n\nHe hands over the large sword to you,\n"+
        "it’s a bit heavier than expected\nbut you will get used to it.\n\nYou exit the blacksmith’s forge with a sense of great accomplishment."
        ]
      ]

      const questFail = [
        [

        ]        
      ]

    const currentStep = parseFloat(props.currentStep)+0
    const quest = props.quest

    const [step, setStep] = useState(currentStep);

    function changeStep(upTrueDownFalse) {
        const _step = currentStep + upTrueDownFalse ? 1 : -1;
        console.log(_step);
        setStep(_step);
    } 
    

    useEffect(() => {}, [])

    return(
        <div className="black-screen">
            <div style={{width:"700px"}} className="warn-box">
                <div className="quest-text">
                    <h2>{questNames[props.questIndex]}</h2>
                    <p>Step {parseFloat(currentStep)+1} of {quest.items.length}</p>
                    {props.balances[quest.items[currentStep]] < quest.amounts[currentStep] &&
                        <video className="video" autoPlay muted loop src={"./quests/" + questNames[props.questIndex] + "/video.webm"} ></video>}



                    {currentStep != quest.items.length && <div>
                        {props.balances[quest.items[currentStep]] >= quest.amounts[currentStep] &&
                    <img className="quest-img" src={"./quests/"+ questNames[props.questIndex] + "/" + currentStep + "a.jpg"}></img>}
                    {props.balances[quest.items[currentStep]] >= quest.amounts[currentStep] ?
                        <pre>
                        {questStepsText[props.questIndex][currentStep]}
                        </pre>
                        :
                        <pre>
                            You will need the following item{quest.amounts[currentStep] > 1 ? "s" : ""} to progress with this quest: <br/> <br/>~{props.relics[quest.items[currentStep]].relicName} x {quest.amounts[currentStep]}
                        </pre>
                    }
                    {props.balances[quest.items[currentStep]] >= quest.amounts[currentStep] &&
                    <img className="quest-img" src={"./quests/"+ questNames[props.questIndex] + "/" + currentStep + "b.jpg"}></img>}<br/>
                    {props.balances[quest.items[currentStep]] >= quest.amounts[currentStep] ?
                    <button onClick={() => {props.fulfill()}} style={{margin:"15px"}} className="animated-button3">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Fulfill Step - Will cost {props.relics[quest.items[currentStep]].relicName} x {quest.amounts[currentStep]}
                    </button> : 
                    <p>Visit the Merchant or <a href="https://opensea.io/collection/beanquest" target="blank">OpenSea</a> to find the items you need!</p>}
                        {/* <button disabled={props.balances[quest.items[currentStep]] < quest.amounts[currentStep]}>Fulfill step - Will cost {props.relics[quest.items[currentStep]].relicName} x {quest.amounts[currentStep]}</button><br/> */}
                    {/* {props.balances[quest.items[currentStep]] >= quest.amounts[currentStep] && <p className="hint-text">This will cost you {quest.amounts[currentStep]} {props.relics[quest.items[currentStep]].relicName}</p>} */}

                    {/* {props.balances[quest.items[currentStep]] < quest.amounts[currentStep] && <p className="hint-text">You lack {props.relics[quest.items[currentStep]].relicName} x {quest.amounts[currentStep]} to fulfill this step</p>} */}
                </div>}

                {currentStep == quest.items.length &&
                <div>
                    <img className="quest-img" src={"./quests/"+ questNames[props.questIndex] + "/" + currentStep + "a.jpg"}></img>
                    <pre>
                        {questStepsText[props.questIndex][currentStep]}
                    </pre>
                    <img className="quest-img" src={"./quests/"+ questNames[props.questIndex] + "/" + currentStep + "b.jpg"}></img>
                    <h2>You obtained the {props.relics[quest.reward].relicName}</h2>
                    <img src={"./items/img-only-border/" + quest.reward + ".jpg"} className="display-image"></img>
                </div>}
                
                    <br/><button onClick={props.close}>Close</button>
                </div>
            </div>
        </div>
    )
}

export default Quest