import { useWeb3React } from "@web3-react/core";
import { forwardRef, useEffect, useImperativeHandle, useState, useSyncExternalStore } from "react";
import { injected } from "./wallet/connectors";
import Warning from "./Warning.js";
import Party from "./Party.js";
import Deposit from "./Deposit.js";
import Compound from "./Compound.js";
import BossWindow from "./BossWindow.js";
import LookForBean from "./LookForBean";
import MinerContract from "../contracts/minerabi.json";
import NFTContract from "../contracts/nfts.json";
import BossContract from "../contracts/bosses.json";
import OracleContract from "../contracts/oracle.json";
import GachaContract from "../contracts/gacha.json";

import Web3 from "web3";
import "../Miner.css";
import "../StatButtons.css";
import "../Button.css";
import "../Input.css";
import "../BossButton.css"
import { useParams } from "react-router-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";

const Miner = forwardRef((props, ref) => {


    // const chainId = "56";
    

    const { active, account, library, connector, activate, deactivate } = useWeb3React();
    // const provider = "https://rpc-devnet-cardano-evm.c1.milkomeda.com"
    const provider = props.provider;
    const url = "beanquest.io/";
    const symbol = "BNB";

    const [loading, setLoading] = useState(true);

    const [attributePicked, setAttribute] = useState(0);
    const [toDeposit, setToDeposit] = useState(0.1);
    const [checkpoint, setCheckpoint] = useState(0);
    const [timestamp, setTimestamp] = useState(0);
    const [streak, setStreak] = useState(0);
    const [balance, setBalance] = useState(0);
    const [user, setUser] = useState([]);
    const [userBonusStats, setBonusStats] = useState([]);
    const [equipped, setEquipped] = useState([]);
    const [relics, setRelics] = useState([]);
    const [totalStats, setTotalStats] = useState([])



    const [buttons, setButtons] = useState(false);
    const [referralVisible, setReferralVisible] = useState(false);
    // const [statsVisible, setStatsVisible] = useState(false);
    const [beansTimer, setBeansTimer] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(false);

    const [depositActive, setDepositActive] = useState(false);
    const [warning, setWarning] = useState(false);
    const [withdrawWarning, setWithdrawWarning] = useState(false);
    const [compound, setCompound] = useState(false);
    const [party, setParty] = useState(false);

    //MAGIC BEAN
    const [lookForBeanWindow, setBeanWindow] = useState(false);
    const [searchComplete, setSearchComplete] = useState(false);
    const [lookingForBean, setLookingForBean] = useState(false);
    const [beanFound, setBeanFound] = useState(false);
    const [oldBeanCheckpoint, setOldBeanCheckpoint] = useState();
    const [oldBeanStreak, setOldBeanStreak] = useState()
    const [beanError, setBeanError] = useState(false);

    //BOSS stuff
    const [currentBoss, setCurrentBoss] = useState();
    // const [bossBlock, setBossBlock] = useState();
    const [bossDefeated, setBossDefeated] = useState();
    const [bossRecoveryTime ,setBossRecoveryTime] = useState();
    const [bossFightFinished, setFightFinished] = useState(false);
    const [fightUnderway, setFightUnderway] = useState(false);
    const [bossError, setBossError] = useState(false);
    const [oldCheckpoint, setOldCheckpoint] = useState();
    const [lastRequest, setLastRequest] = useState();
    const [isFulfilled, setFulfilled] = useState(false);


    const [bossInfo, setBossInfo] = useState([]);
    const [bossStats, setBossStats] = useState([]);
    const [fightBossWindow, setBossWindow] = useState(false);

    const [bossEvent, setBossEvent] = useState([]);
    const [bossEventChance, setBossEventChance] = useState(999999);
    const [bossEventDefeated, setBossEventDefeated] = useState(null);

    const [copied, setCopied] =useState(false);
    const [expVisible, setExpVisible] = useState([false, false, false]);

    const boxType = ["str-box", "dex-box", "int-box", "all-box", "nul-box"];
    const inputType = ["str-input", "dex-input", "int-input", "all-input", "nul-input"];

    const buttonClass=["animated-button1", "animated-button5", "animated-button8", "animated-button3", ""];    

    const nftContractAddress = props.nftContractAddress;
    const minerContractAddress = props.minerContractAddress;
    const bossContractAddress = props.bossContractAddress;
    const gachaContractAddress = props.gachaContractAddress;
    const vrfContractAddress = props.vrfContract;

    const [stats, setStats] = useState([]);
    const [payout, setPayout] = useState();
    const [maxPayout, setMaxPayout] = useState();
    const [upline, setUpline] = useState("0xfF8c736524Ee7Fc0a1FE2a353449A5ca902D4380");
    const {referral} = useParams();
    // const [fightBoss, setFightBoss] = useState(false);
    const [depositedAmount, setDepositedAmount] = useState(0);
    // const [payouts, setPayouts] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState();
    const multiplier = 1000000000000000000;
    const statsMultiplier = 100000000000000000;
    const beansMultiplier = 100000000000000;
    const web3 = new Web3(provider);
    web3.eth.setProvider(Web3.givenProvider);

    const nftContract = new web3.eth.Contract(
        NFTContract,
        nftContractAddress
    );
    const minerContract = new web3.eth.Contract(    
        MinerContract,
        minerContractAddress
    )
    const bossContract = new web3.eth.Contract(
        BossContract,
        bossContractAddress
    )
    const gacha = new web3.eth.Contract(
        GachaContract,
        gachaContractAddress
    )
    
    const oracle = new web3.eth.Contract(
        OracleContract,
        vrfContractAddress,
    )


    async function getPayout() {
        const addresses = await web3.eth.getAccounts();
        var _payout = await minerContract.methods.payoutOf(addresses[0]).call();
        await setPayout(_payout[0]);
        setMaxPayout(_payout[1]);

    }

    async function refresh() {
        if(timestamp == 0) {
            setLoading(true);
        }
        const addresses = await web3.eth.getAccounts();
        var _balance = await web3.eth.getBalance(addresses[0]) / multiplier;
        setBalance(_balance.toFixed(2));;
        var _stats = await nftContract.methods.userStats(addresses[0]).call();
        var _totalStats = await nftContract.methods.stats(addresses[0]).call();
        setTotalStats(_totalStats);
        setStats(_stats);
        var _payout = await minerContract.methods.payoutOf(addresses[0]).call();
        setPayout(_payout[0]);
        setMaxPayout(_payout[1]);
        // var userInfo = await minerContract.methods.userInfo(addresses[0]).call();
        var userInfo = await minerContract.methods.users(addresses[0]).call();
        setUser(userInfo);
        console.log(userInfo);
        var _userBonusStats = await minerContract.methods.userBonusStats(addresses[0]).call()
        setBonusStats(_userBonusStats);
        if(userInfo[0] != "0x0000000000000000000000000000000000000000") {
            setUpline(userInfo[0]);
        }
        const _equipped = await nftContract.methods.getBonusesEquipped(addresses[0]).call();
        setEquipped(_equipped);
        console.log(_equipped);
        referral.length == 42 && setUpline(referral);
        console.log(referral + " is your referral");
        setDepositedAmount((userInfo.total_direct_deposits / multiplier).toFixed(2))
        const _relics = await nftContract.methods.getRelics().call();
        setRelics(_relics);
        const _cBoss = await bossContract.methods.currentBoss().call();
        setCurrentBoss(_cBoss);
        setLoading(false);
        setCheckpoint(parseFloat(userInfo.checkpoint));
        setStreak(parseFloat(_userBonusStats.streak));
        const _timestamp = await minerContract.methods.getBlockTimeStamp().call();
        setTimestamp(parseFloat(_timestamp));
        const _timeElapsed = parseFloat(_timestamp) - parseFloat(userInfo.checkpoint) >= 172800 ? 172800 : parseFloat(_timestamp) - parseFloat(userInfo.checkpoint);
        setTimeElapsed(_timeElapsed);
        if(_timeElapsed + parseFloat(_userBonusStats.streak) < 432000) {
            setWithdrawWarning(true);
        }
        console.log("streak: " + _userBonusStats.streak)
        console.log("time since last interaction: " + (_timeElapsed));
        console.log("streak + time since last interaction:" + (parseFloat(_userBonusStats.streak) + _timeElapsed));
        const _boss = await bossContract.methods.bossEverything(_cBoss).call();
        const _bossStats = await bossContract.methods.getBossStats(_cBoss).call();
        const _bossDefeated = await minerContract.methods.defeatedThisRound(_cBoss, addresses[0]).call();
        const _bossRecoveryTime = await minerContract.methods.bossRecoveryTime().call();
        console.log("recovery time " + _bossRecoveryTime);
        setBossRecoveryTime(parseFloat(_bossRecoveryTime))
        setBossDefeated(_bossDefeated)
        console.log("boss has been defeated? "+ _bossDefeated);
        setBossInfo(_boss);
        setBossStats(_bossStats);
    }

    useImperativeHandle(ref, () => ({
        callChildFunction() {refresh()}
    }))

    function calculate48Hours() {
        var _deposit = ((((parseInt(user.deposit_amount) * 0.03) * 172800)/ 86400) / beansMultiplier).toFixed();
        return _deposit
    }

    async function deposit() {
        if(!depositActive) {
            setDepositActive(true);
        }
        else {
                setButtons(true);
            try {
                await minerContract.methods.deposit(upline, attributePicked).send({
                    from:account,
                    value:toDeposit*multiplier,
                    gas: 500000,
                });
                setToDeposit(0);
    
            }catch (ex) {
                console.log(ex)
            }
            setButtons(false);
            refresh();
        }
    }

    async function reinvest() {
        if(props.fightingBoss) {
            if(!fightBossWindow) {
                setBossWindow(true);
            }
            else {
                setButtons(true);
                const _oldCheckpoint = checkpoint;
                try {
                    await bossContract.methods.fightBoss(attributePicked).send({
                        from:account,
                        gas: 500000,
                    });
                    setFightUnderway(true);
                    setTimeout(() => getBossResults(_oldCheckpoint), 25000);
                    setButtons(false);
                    refresh();
                }catch (ex) {
                    console.log(ex)
                }
            }
        }
        else if(!compound) {
            setCompound(true);
        }
        else {
            setButtons(true);
            try {
                await minerContract.methods.reinvest(attributePicked, false, account, 0).send({
                    from:account,
                    gas: 400000,
                });
            }catch (ex) {
                console.log(ex)
            }
            setButtons(false);
            refresh();
        }
    }

    async function withdraw() {
        if(warning == false) {
            console.log(streak + (timeElapsed));
            setWarning(true);
        }
        else {
            setButtons(true);
            try {
                await minerContract.methods.withdraw().send({
                    from:account,
                });
      
            }catch (ex) {
                console.log(ex)
            }
            setButtons(false);
            refresh();
        }
        // const blockNumber = web3.eth.blockNumber;
        // const timestamp = web3.eth.getBlock(blockNumber).timestamp;
        // if(streak < 216000) {
        //     setWarning(true);
        // }
    }

    async function lookForBean() {
        setButtons(true);
        try {
            var _userBonusStats = await minerContract.methods.userBonusStats(account).call();
            setOldBeanCheckpoint(_userBonusStats.beanCheckpoint);
            setOldBeanStreak(_userBonusStats.beanStreak);
            // const _block = await web3.eth.getBlockNumber();
            await bossContract.methods.lookForBeanVRF().send({
                from:account,
                gas: 400000,
            });
            setLookingForBean(true);
            setTimeout(() => getBeanResults(_userBonusStats.beanStreak), 15000);
        }catch (ex) {
            console.log(ex)
        }
        setButtons(false);
        refresh();
    }


    function handleChange(event) {
        setToDeposit(event.target.value);
    }

    function handleChangeUpline(event) {
        setUpline(event.target.value);
    }

    function pressStat(attribute) {
        setAttribute(attribute);
    }

    async function getEvents(test) {
        minerContract.getPastEvents(
          'BossFight',
          {
            fromBlock: test,
            toBlock: 'latest'
          },
          (err, events) => {
            var i = 0;
            for(i; i < events.length; i++) {
              if(events[i].returnValues.user == account) {
                console.log(events[i]);
                setBossEvent(events[i].returnValues);
                setBossEventDefeated(events[i].returnValues.defeated);
                setBossEventChance(events[i].returnValues.chance);
              }
            }
            console.log(events);
            console.log("event search concluded")
            setFightUnderway(false);
            setFightFinished(true);
          }
          )
      }

      async function getBossResults(old) {
        var userInfo = await minerContract.methods.users(account).call();
        setUser(userInfo);
        if(userInfo.checkpoint == old) {
            setBossError(true);
            console.log("something went wrong");
        }
        else {
            const _bossDefeated = await minerContract.methods.defeatedThisRound(currentBoss, account).call();
            setBossDefeated(_bossDefeated);
            console.log("boss results delivered");
            setFightUnderway(false);
            setFightFinished(true);
            setBossError(false);
        }
        
      }

      async function getBeanResults(oldStreak) {
        var _userBonusStats = await minerContract.methods.userBonusStats(account).call();
        setBonusStats(_userBonusStats);
        const _beanCheckpoint = _userBonusStats.beanCheckpoint;
        if(oldBeanCheckpoint == _beanCheckpoint) {
            console.log("something went wrong");
            // setBeanFound(false);
            setSearchComplete(true);
            setBeanError(true);
        }
        else {
            if(parseFloat(_userBonusStats.beanStreak) > parseFloat(oldStreak)) {
                setBeanFound(true);
            }
            if(_userBonusStats.beanStreak == 0) {
                setBeanFound(false);
            }
            setLookingForBean(false);
            setSearchComplete(true);
        }
      }

      async function getBeanEvents(test) {
        var found = false;
        minerContract.getPastEvents(
          'MagicBeanFound',
          {
            fromBlock: test,
            toBlock: 'latest'
          },
          (err, events) => {
            var i = 0;
            for(i; i < events.length; i++) {
              if(events[i].returnValues.user == account) {
                console.log(events[i].returnValues);
                found = true;
              }
            }
            setBeanFound(found);
            console.log("event search concluded")
            console.log(found);
            setLookingForBean(false);
            setSearchComplete(true);
          }
          )
      }

    // function copyToClipboard() {
    //     navigator.clipboard.writeText(url + account);
    //     setCopied(true);
    // }

    useEffect(() => {
        refresh();
        const interval = setInterval(() => {
            getPayout();
        },
        15000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div style={!props.fightingBoss ? {margin:"auto"} : {marginLeft:"auto"}}>
        {!loading ? 
            <div className={props.fightingBoss ? "box-when-fighting-boss" : "box"} id={boxType[attributePicked]}>
            {active ? <div className="boss-content">
            <h1 className="box-title">BEAN QUEST</h1>
            <h2 className="center-element">{attributePicked < 4 && "Deposit"}</h2>
            {attributePicked < 4 && <p className="center-element">Balance: {balance} {symbol}</p>}
            {/* <p className="center-element">POWER: {(user.deposit_amount / beansMultiplier).toFixed()}</p> */}
            <div className="center-element">
                {attributePicked < 4 && <div>
                    <input className="deposit-input" id={inputType[attributePicked]} disabled={attributePicked > 3} type="number" onChange={handleChange} value={toDeposit} min="0" step=".1"/>
                    <br/><p className="hint-text">{toDeposit > balance ? "Not enough BNB" : ""}</p>	
                    <button disabled={buttons} style={{fontSize:"1.2em"}} onClick={() => deposit()} className={buttonClass[attributePicked]}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    DEPOSIT
                </button>
                {user.total_direct_deposits < 1 * multiplier && <p className="hint-text">Hint: Deposit at least 1 BNB<br/>for a chance to win a Magic Bean every 24 hours</p>}
                </div>}
                <hr style={{width:"50%"}}/>
                {referralVisible ? <div><p className="hint-text">Use the link below to Recruit Party Members<br/>And earn 10% on all their deposits</p>
                <CopyToClipboard text={url + account} onCopy={() => setCopied(true)}><div>
                    <button>{copied ? "Copied to clipboard successfully!" : "Copy my Referral Link to clipboard"}</button><button onClick={() => setReferralVisible(false)}>Ok!</button></div>
                </CopyToClipboard>
                </div> :
                <button onClick={() => setReferralVisible(true)}>Party(Referral System)</button>}
                {/* {toDeposit > 5 && <p style={{fontSize:"0.8em"}}>⚠️Warning⚠️<br/>Stats can only be increased by 50 points per deposit.</p>} */}
                {/* {referralVisible ?
                <div><input onChange={handleChangeUpline} value={upline} style={{marginTop: "10px"}}></input><button onClick={() => setReferralVisible(false)}>Ok!</button></div> :
                <button disabled={buttons} onClick={() => setReferralVisible(true)} style={{margin: "10px"}}>Change Referral</button>}<br/> */}
            </div>
            {/* <p className="center-element">Total Deposited: {totalDeposit} {symbol}</p> */}
            {/* <p className="center-element"> Deposited by you: {depositedAmount} {symbol}</p> */}
            {/* <h3 className="center-element">Choose a skill to focus on:</h3> */}
            <h3 className="center-element">Pick an attribute to Level up:</h3>
            <div className="container">
                <div className={attributePicked == 0 || attributePicked == 3 ? "btn-pressed" : "btn"} onClick={() => pressStat(0)}><a>⚔️STR</a></div>  
                <div className={attributePicked == 1 || attributePicked == 3 ? "btn-pressed" : "btn"} onClick={() => pressStat(1)}><a>⚡DEX</a></div>  
                <div className={attributePicked == 2 || attributePicked == 3 ? "btn-pressed" : "btn"} onClick={() => pressStat(2)}><a>📖INT</a></div>
            </div>
            {equipped.bonuses[16] && <button className={attributePicked == 3 ? "special-button-pressed" : "special-button"} onClick={attributePicked != 3 ? () => setAttribute(3) : () => setAttribute(0    )}>ALL ATTRIBUTES</button>}
            {/* <div className="center-element">
                <button onClick={() => pressStat(0)} style={attributePicked === 0 ? { backgroundColor: "tomato", fontWeight: "bold", borderRadius: "4px"} : {}} className="str-button">STR</button>
                <button onClick={() => pressStat(1)} style={attributePicked === 1 ? { backgroundColor: "greenyellow", fontWeight: "bold", borderRadius: "4px"} : {}} className="dex-button">DEX</button>
                <button onClick={() => pressStat(2)} style={attributePicked === 2 ? { backgroundColor: "lightskyblue", fontWeight: "bold", borderRadius: "4px"} : {}} className="int-button">INT</button>
            </div> */}

            <div className="center-element">
            
            {depositedAmount > 0 && <div>
                <p className="clickable" onClick={!beansTimer ? () => setBeansTimer(true) : () => setBeansTimer(false)}>Beans found while adventuring:<br />{(payout / beansMultiplier).toFixed()} / {calculate48Hours()} BEANS{172800 - timeElapsed <= 0 && "⚠️"}</p>
                {beansTimer && 172800 - timeElapsed > 0 && <p className="hint-text">Time left until beans are maxed out: {Math.floor((172800 - timeElapsed) / 3600)}h{Math.floor(((172800-timeElapsed)/60)%60)}m</p>}
                {beansTimer && 172800 - timeElapsed <= 0 && <p className="hint-text">Maxed Out! {!withdrawWarning && "Collect Loot"} or Level Up to keep collecting beans!</p>}
                <button style={bossDefeated && props.fightingBoss ? {textDecoration:"line-through"} : {}} disabled={buttons || attributePicked > 3} onClick={() => reinvest()} className={attributePicked < 4 ? buttonClass[attributePicked] : "animated-button2"}>
                    {<div><span></span>
                    <span></span>
                    <span></span>
                    <span></span></div>}
                    {props.fightingBoss ? "" : "Level up"}
                    {props.fightingBoss && "FIGHT BOSS!"}
                </button>
                <br/>
                {attributePicked < 4 && <label className="container">
                    <input type="checkbox" onChange={props.handleFightBoss} checked={props.fightingBoss}/>
                    <span className="checkmark">Fight Boss?{bossDefeated && " (Defeated)"}</span>
                </label>}
                <p>Or</p>

                <button disabled={buttons} onClick={() => withdraw()} className="animated-button11">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {withdrawWarning && "⚠️"}Collect Loot{withdrawWarning && "⚠️"}
                </button>
                <br/>
                {user.total_direct_deposits >= 1 * multiplier &&
                <button disabled={searchComplete} onClick={() => setBeanWindow(true)} className={searchComplete ? "special-button-disabled" : "special-button"} style={{fontSize:"0.8em", height:"fit-content", marginBottom:"10px", marginTop:"5px"}}>RANDOM EVENT</button>}
            </div>}
                <div className="stats-section">
                {attributePicked != 3 &&
                <div>
                    <h3>Level: {Math.floor(stats[3] / statsMultiplier)}</h3>
                    {/* {toDeposit > 0 && attributePicked < 4 && <span> → <span className={toDeposit > 0 ? "attribute-lvl" : ""}>{(stats[3] / statsMultiplier + parseFloat(toDeposit * 10)).toFixed(0)}</span></span>}</h3> */}
                    <p className={attributePicked == 0 && "attribute-str"} onClick={() => {!expVisible[0] ? setExpVisible([true, false, false]) : setExpVisible([false, false, false])}}>STR: {Math.floor(stats[0] / statsMultiplier)}{toDeposit > 0 && attributePicked == 0 && <span> → {(stats[0] / statsMultiplier) + parseFloat(toDeposit * 10) > 100 + equipped.multipliers[13] / statsMultiplier ? 100 + Math.floor(parseFloat(equipped.multipliers[13]) + parseFloat(totalStats.lbSTR)) / statsMultiplier + "⚠️ Strength cannot go higher" : Math.floor((stats[0] / statsMultiplier) + parseFloat(toDeposit * 10))}</span>}
                    {expVisible[0] &&<div>
                    <span id="exp">total STR exp: {(stats[0] / 100000000000000).toFixed()}</span></div>}</p>
                    <p className={attributePicked == 1 && "attribute-dex"} onClick={() => {!expVisible[1] ? setExpVisible([false, true, false]) : setExpVisible([false, false, false])}}>DEX: {Math.floor(stats[1] / statsMultiplier)}{toDeposit > 0 && attributePicked == 1 && <span> → {(stats[1] / statsMultiplier) + parseFloat(toDeposit * 10) > 100 + equipped.multipliers[14] / statsMultiplier ? 100 + Math.floor(parseFloat(equipped.multipliers[14]) + parseFloat(totalStats.lbDEX)) / statsMultiplier + "⚠️ Dexterity cannot go higher" : Math.floor((stats[1] / statsMultiplier) + parseFloat(toDeposit * 10))}</span>}
                    {expVisible[1] &&<div>
                    <span id="exp">total DEX exp: {(stats[1] / 100000000000000).toFixed()}</span></div>}</p>
                    <p className={attributePicked == 2 && "attribute-int"} onClick={() => {!expVisible[2] ? setExpVisible([false, false, true]) : setExpVisible([false, false, false])}}>INT: {Math.floor(stats[2] / statsMultiplier)}{toDeposit > 0 && attributePicked == 2 && <span> → {(stats[2] / statsMultiplier) + parseFloat(toDeposit * 10) > 100 + equipped.multipliers[15] / statsMultiplier ? 100 + Math.floor(parseFloat(equipped.multipliers[15]) + parseFloat(totalStats.lbINT)) / statsMultiplier + "⚠️ Intelligence cannot go higher" : Math.floor((stats[2] / statsMultiplier) + parseFloat(toDeposit * 10))}</span>}
                    {expVisible[2] &&<div>
                    <span id="exp">total INT exp: {(stats[2] / 100000000000000).toFixed()}</span></div>}</p>
                </div>
                }
                {attributePicked == 3 &&
                <div>
                    <h3>Level: {(stats[3] / statsMultiplier).toFixed()}</h3>
                    {/* {toDeposit > 0 && attributePicked < 4 && <span> → <span className={toDeposit > 0 ? "attribute-lvl" : ""}>{(stats[3] / statsMultiplier + parseFloat(toDeposit * 10)).toFixed(0)}</span></span>}</h3> */}
                    <p className="attribute-str"
                    onClick={() => {!expVisible[0] ? setExpVisible([true, false, false]) : setExpVisible([false, false, false])}}>
                    STR: {Math.floor(stats[0] / statsMultiplier)}
                    {toDeposit > 0 && <span> → {(stats[0] / statsMultiplier) + parseFloat(toDeposit * 10) / 3 > 100 + equipped.multipliers[13] / statsMultiplier ? 100 + (parseFloat(equipped.multipliers[13]) + parseFloat(totalStats.lbSTR)) / statsMultiplier + "⚠️ Strength cannot go higher" : Math.floor((stats[0] / statsMultiplier) + parseFloat(toDeposit * 10) / 3)}</span>}
                    {expVisible[0] &&<div>
                    <span id="exp">total STR exp: {(stats[0] / 100000000000000).toFixed()}</span></div>}</p>

                    <p className="attribute-dex"
                    onClick={() => {!expVisible[1] ? setExpVisible([false, true, false]) : setExpVisible([false, false, false])}}
                    >DEX: {Math.floor(stats[1] / statsMultiplier)}
                    {toDeposit > 0 && <span> → {(stats[1] / statsMultiplier) + parseFloat(toDeposit * 10) / 3 > 100 + equipped.multipliers[14] / statsMultiplier ? 100 + (parseFloat(equipped.multipliers[14]) + parseFloat(totalStats.lbDEX)) / statsMultiplier + "⚠️ Dexterity cannot go higher" : Math.floor((stats[1] / statsMultiplier) + parseFloat(toDeposit * 10) / 3)}</span>}
                    {expVisible[1] &&<div>
                    <span id="exp">total DEX exp: {(stats[1] / 100000000000000).toFixed()}</span></div>}</p>

                    <p className="attribute-int"
                    onClick={() => {!expVisible[2] ? setExpVisible([false, false, true]) : setExpVisible([false, false, false])}}>
                    INT: {Math.floor(stats[2] / statsMultiplier)}
                    {toDeposit > 0 && <span> → {(stats[2] / statsMultiplier) + parseFloat(toDeposit * 10) / 3 > 100 + equipped.multipliers[15] / statsMultiplier ? 100 + (parseFloat(equipped.multipliers[15]) + parseFloat(totalStats.lbINT)) / statsMultiplier + "⚠️ Intelligence cannot go higher" : Math.floor((stats[2] / statsMultiplier) + parseFloat(toDeposit * 10) / 3)}</span>}
                    {expVisible[2] &&<div>
                    <span id="exp">total INT exp: {(stats[2] / 100000000000000).toFixed()}</span></div>}</p>
                </div>
                }
                    
                </div>
                {/* {statsVisible ?
                <div>
                    <h3>Soul Level: {(stats[3] / statsMultiplier).toFixed()}</h3>
                    <p>STR: {(stats[0] / statsMultiplier).toFixed()}</p>
                    <p>DEX: {(stats[1] / statsMultiplier).toFixed()}</p>
                    <p>INT: {(stats[2] / statsMultiplier).toFixed()}</p>
                </div>
                 :
                <button disabled={buttons} onClick={() => setStatsVisible(true)}>Check Stats</button>} */}

            </div>
            </div> : <h1 className="boss-content">Please Connect your wallet to proceed</h1>}
            {warning && <Warning timeElapsed={timeElapsed} direct_bonus={user.direct_bonus} match_bonus={user.match_bonus} pool_bonus={user.pool_bonus} equipped={equipped} payout={payout} maxPayout={maxPayout} withdraw={() => withdraw()} timestamp={timestamp} checkpoint={checkpoint} streak={streak} disableWarning={() => setWarning(false)}/>}    
            {party && <Party />}
            {depositActive && <Deposit deposit={() => deposit()} disableDeposit={() => setDepositActive(false)} toDeposit={toDeposit} depositedAmount={depositedAmount} payout={payout} direct_bonus={user.direct_bonus} match_bonus={user.match_bonus} pool_bonus={user.pool_bonus} equipped={equipped} attribute={attributePicked}/>}
            {compound && <Compound stats={stats} compound={() => reinvest()} disableCompound={() => setCompound(false)} payout={payout} direct_bonus={user.direct_bonus} match_bonus={user.match_bonus} pool_bonus={user.pool_bonus} equipped={equipped} attribute={attributePicked} />}
            {fightBossWindow && <BossWindow bossError={bossError} currentBoss={currentBoss} getBossResults={() => getBossResults()} bossFightFinished={bossFightFinished} fightUnderway={fightUnderway} recoveryTime={bossRecoveryTime} relics={relics} bossDefeated={bossDefeated} checkpoint={checkpoint} timestamp={timestamp} nickname={props.nickname} stats={stats} bossStats={bossStats} bossInfo={bossInfo} fightBoss={() => reinvest()} disableWindow={() => setBossWindow(false)} payout={payout} direct_bonus={user.direct_bonus} match_bonus={user.match_bonus} pool_bonus={user.pool_bonus} equipped={equipped} attribute={attributePicked}  />}
            {lookForBeanWindow && <LookForBean searchComplete={searchComplete} lookingForBean={lookingForBean} beanFound={beanFound} equipped={equipped} timestamp={timestamp} disableBean={() => setBeanWindow(false)} lookForBean={() => lookForBean()} beanCheckpoint={userBonusStats.beanCheckpoint} />}      
        </div> : <p>Loading...</p>}
        </div>
    );
})

export default Miner;